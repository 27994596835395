import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import 'moment/locale/pt-br'
import api from '~/services/api';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Loading from '~/components/loading';
import { DividerStyle } from '../Custom';
import {RecurrenceFlag} from './MaintenancesList';
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from '@material-ui/core';
import FBButton from '~/components/Button/FBButton';
import {Alarm, Check, ExpandMore} from '@material-ui/icons';
import {TimelineItem, TimelineContent, TimelineDot, TimelineSeparator, TimelineConnector, Timeline} from '@mui/lab';
import moment from 'moment';

export default function UnitMaintenance() {
    const { token, task_id } = useParams()
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState({ events: [], task: {}})

    const refresh = useCallback(async () => {
        try {
            setLoading(true)
            const response = await api.get(`/v1/units/${token}/maintenances/${task_id}`)
            if (response.status !== 200) {
                toast.error(response.data.message)
            } else {
                setResult(response.data)
            }
        } catch (err) {
            toast.error(err.response?.data?.message || 'Não foi possível localizar a solicitação de personalização');
        } finally {
            setLoading(false)
        }

    }, [token, task_id]);


    useEffect(() => { refresh() }, [refresh])

    async function addHistory() {
        try {
            const response = await api.post(`/v1/units/${token}/maintenances/${task_id}`)
            if (response.status !== 200) {
                toast.error(response.data.message || "Não foi possível realizar a tarefa")
            } else {
                refresh()
                toast.success("Manutenção realizada com sucesso")
            }
        } catch (err) {
            toast.error(err.response?.data?.message || 'Não foi possível localizar a solicitação de personalização');
        } finally {
            setLoading(false)
        }
    }

    return (
        <div>
            <Container className='container-fluid-folder' fluid={true}>
                <Container className='container-folder-page'>
                    <br />
                    <div style={{ display: 'flex' }}>
                        <Link to={`/${token}/manutencoes`}>
                            <ArrowBack fontSize='large' />
                        </Link>
                        <h4 style={{ margin: "auto" }}> Realizar manutenção </h4>
                        {loading && <div style={{ marginTop: "5px" }}><Loading /></div>}
                    </div>

                    <DividerStyle />
                    <Box sx={{p: 2}} display="flex" flexDirection="column" className='gap-3'>
                        <Typography variant="body1" align='center'>
                            <b> {result.task.name} </b>
                        </Typography>
                        <Typography variant="body1" align="center">
                            {result.task.description}
                        </Typography>
                        <Box width='100%' display='flex' justifyContent='center'>
                            {Boolean(result.task.recurrence) && <RecurrenceFlag recurrence={result.task.recurrence} last={result.task.last} />}
                        </Box>
                        { result.task?.name && <FBButton data-shape="rounded" startIcon={<Check />} primary onClick={addHistory} >
                            <Typography variant="body">
                                Marcar como realizado
                            </Typography>
                        </FBButton>}
                        {Boolean(result.events.length) && <Accordion>
                            <AccordionSummary className='maintenance-expand' expandIcon={<ExpandMore />}>
                                <Typography variant="body2" className="semibold">
                                    <Alarm /> Histórico
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='maintenance-content'>
                                <Timeline className="maintenance-tl" align="left">
                                    {result.events.map((ev, i) => (
                                        <TimelineItem className="tl-item" key={ev.id}>
                                            <TimelineSeparator>
                                                <TimelineDot variant='outlined'>
                                                    <Alarm />
                                                </TimelineDot>
                                                {(i !== result.events.length - 1 ) && <TimelineConnector />}
                                            </TimelineSeparator>

                                            <TimelineContent>
                                                <Typography variant="body2" className="semibold">
                                                    {moment(ev.created_at).format("DD/MM/YYYY")} - Manutenção realizada
                                                </Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    ))}
                                </Timeline>
                            </AccordionDetails>
                        </Accordion>}


                    </Box>

                    <div
                        display="inline-flex"
                        flexDirection="column"
                        style={{ width: '100%',}}>

                    </div>
                </Container>
            </Container>
        </div >
    );
}
