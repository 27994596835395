import React, { Fragment, forwardRef } from "react";
import "./Input.sass";
import Label from "../Label";
import { Typography } from "@material-ui/core";

const Component = forwardRef(({ line, ...props }, ref) => {
    return <>
        <input
            disabled={props.disabled ? props.disabled : false}
            className={`${line ? "line" : null} input`}
            {...props}
            ref={ref}
        />
    </>
})

const Input = forwardRef(({ errors, labelClass, label, ...props }, ref) => {
    return (
        <Fragment>

            {label && <Label className={labelClass} text={label} />}
            <Component {...props} ref={ref} />

            {errors?.[props.name]?.type === "required" && (
                <Typography color="error">Campo obrigatório.</Typography>
            )}

            {errors?.[props.name]?.type === "cpf" && (
                <Typography color="error">Documento inválido</Typography>
            )}
            {errors?.[props.name]?.type === "email" && (
                <Typography color="error">E-mail inválido</Typography>
            )}
            {errors?.[props.name]?.type === "phone" && (
                <Typography color="error">Telefone inválido</Typography>
            )}

        </Fragment>
    );
})

export default Input
