import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { track } from "~/services/track";

import "./styles.scss";

function FaqItem({ question }) {
  const { token, categoryGuid } = useParams();
  return (
    <>
      <li  className="item-question" onClick={() => track("ABRIU_PERGUNTA", { question: question.question})}>
          <Link to={{
            pathname: `/${token}/question/${categoryGuid}/${question.guid}`,
            question: question
          }}>
            <strong >{question.question}</strong>
          </Link>
      </li>
    </>
  );
}

export default FaqItem;
