import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import api from '~/services/api';

import FaqItem from "./FaqItem";

function FaqList() {
    const { categoryGuid } = useParams();
    const [questions, setQuestions] = useState([]);

    useEffect(() => {

        const getQuestions = async () => {
            const response = await api.get(`/questions/${categoryGuid}`);
            setQuestions(response.data)
        };

        getQuestions();
    }, [categoryGuid]);

    return (
        <div > 
            <ul className="faq-list">
                {questions.map(question => (
                    <FaqItem key={question.question} question={question}/>
                ))}
            </ul>
        </div>
    );
}

export default FaqList;