import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';
import { CameraAlt, ExpandMore, KeyboardArrowRight, Videocam } from "@material-ui/icons";
import RenderFilesImgVideo from "~/utils/RenderFilesImgVideo";
import BackIndex from "~/components/Navbar/BackIndex";
import api from "~/services/api";
import { toast } from "react-toastify";
import { CenterSpinnerButton } from "~/components/Spinner";

const ContainerBack = styled(Container)`
    background-color: #FFFFFF;
    min-height: 100vh;
    padding: 0px;
`

const AccordionStyled = styled(Accordion)`
    background-Color: rgba(0, 107, 234, 0.1);
    box-Shadow: 0px 4px 4px 0px #00000040;
    border-Radius: 6px;
    position: relative;
`

const DividerDiv = styled.div`
    margin: 0px 40px 0px 30px;
    @media (max-width: 999px) {
        margin: 0px 25px 0px 10px;
  }                         
`

const DivAcordion = styled.div`
    margin-Top: 20px;
    position: relative;
    border-Radius: 6px;
    overflow: hidden;
    box-Shadow: 0px 4px 4px 0px #00000040;
`

const LabelLink = styled.label`
    margin-left: 15px;
    font-size: 18px;
    font-weight: bold;
    word-break: break-word;
    color: #474747;
    cursor: pointer;
`

const LabelText = styled(Typography).attrs({
    variant: "body1",
    color: "primary",
})`
    font-weight: bold !important;
    word-break: break-word;
`

const DivSumary = styled.div`
    margin: 0px 30px 0px 30px;
    position: relative;
    @media (max-width: 999px) {
        margin: 0px 10px 0px 10px;
  }
`

const DivBackGroundPercentage = styled.div`
    position: absolute;
    height: 48.5px;
    z-index: 999;
    background-color: rgba(var(--theme-color-rgb,  0, 107, 234), 0.1);
    left: 0;
    top: 0;
`

const DivBackGroundPercentageNoLink = styled.div`
    position: absolute;
    height: 48.5px;
    z-index: 999;
    background-color: rgba(var(--theme-color-rgb,  0, 107, 234), 0.1);
    width: 60%;
    left: 0;
    top: 0;
`

export default function MonitorList() {
    const { token, guid } = useParams();
    const [workMonitorBuilding, setworkMonitorBuilding] = useState({ monitor: [] });
    const [expanded, setExpanded] = useState({});
    const [expandedTotal, setExpandedTotal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        async function getMonitors() {
            setLoading(true);
            try {
                const data = await api.get(`/v1/monitor/${token}?`);
                setworkMonitorBuilding(data.data)
                setLoading(false)
            } catch (e) {
                toast.error("Não foi possível localizar os detalhes do acompanhamento da obra");
                setLoading(false);
            }
        }

        getMonitors();

    }, [guid, token])

    const handleChange = (panel) => () => {
        setExpanded((expanded) => ({ ...expanded, [panel]: !expanded[panel] }));
    };

    function handleChangeTotal() {
        workMonitorBuilding.monitor.steps.map((monitor, a) => (
            monitor.attachments.length > 0 && setExpanded((expanded) => ({ ...expanded, [a]: !expandedTotal }))
        ))
        setExpandedTotal(!expandedTotal)
    }

    return (
        <ContainerBack fluid={true}>

            {loading &&
                <div
                    style={{
                        marginTop: "35px",
                        textAlign: "center"
                    }}
                >
                    <LabelText>  <CenterSpinnerButton color={"#000"} /> </LabelText>
                </div>
            }

            <DivSumary>
                <BackIndex link={`/${token}/`} workMonitorBuilding={workMonitorBuilding} />

                <DividerDiv style={{ marginTop: "20px" }}>
                    <Divider style={{ backgroundColor: "#474747" }} />
                </DividerDiv>

                <Link
                    to={`/${token}/workMonitor/MonitorListDates`}
                >
                    <Box sx={{ p:1, pl: 2, m: 1 }} display="flex" justifyContent="space-between">
                        <LabelLink> <CameraAlt color="primary" /> Fotos da obra </LabelLink>

                        <KeyboardArrowRight color="primary" />
                    </Box>
                </Link>

                <DividerDiv >
                    <Divider style={{ backgroundColor: "#474747" }} />
                </DividerDiv>

                {workMonitorBuilding.hasVideo &&
                    <>
                        <Link
                            to={`/${token}/workMonitor/MonitorListDates?filter=video`}
                        >
                            <Box sx={{ p:1, pl: 2, m: 1 }} display="flex" justifyContent="space-between">
                                <LabelLink> <Videocam color="primary" /> Vídeos da obra </LabelLink>

                                <KeyboardArrowRight color="primary" />
                            </Box>
                        </Link>

                        <DividerDiv >
                            <Divider style={{ backgroundColor: "#474747" }} />
                        </DividerDiv>
                    </>
                }

                <DivSumary
                    style={{
                        marginTop: "30px"
                    }}
                >
                    <DivAcordion>
                        <DivBackGroundPercentageNoLink
                            style={{ width: workMonitorBuilding.monitor.percent + "%", height: "100%" }}
                            onClick={() => (handleChangeTotal())}
                        />

                        <AccordionStyled
                            style={{ padding: "15px", backgroundColor: "rgba(var(--theme-color-rgb,  0, 107, 234), 0.1)" }}
                            onClick={() => (handleChangeTotal())}
                        >
                            <div style={{ display: "flex" }}>
                                <LabelText>Estágio da obra</LabelText>
                                <LabelText style={{ marginLeft: "auto" }} > {workMonitorBuilding.monitor.percent} % </LabelText>
                            </div>
                        </AccordionStyled>
                    </DivAcordion>
                </DivSumary>

                <div
                    style={{
                        marginTop: "35px",
                        textAlign: "center"
                    }}
                >
                    <LabelText> Etapas </LabelText>
                </div>

                <div style={{ marginTop: "-5px" }}>
                    <DivSumary>

                        {workMonitorBuilding.monitor.steps?.map((step, i) => (
                            <DivAcordion key={step.guid} >
                                <AccordionStyled
                                    TransitionProps={{ unmountOnExit: true }}
                                    onChange={step.attachments.length > 0 && handleChange(i)}
                                    expanded={expanded[i] || false}
                                    square
                                >
                                    <AccordionSummary expandIcon={Boolean(step.attachments.length) && <ExpandMore color="primary" />}
                                        style={{
                                            backgroundColor: "rgba(var(--theme-color-rgb,  0, 107, 234), 0.1)",
                                            boxShadow: "0px 4px 4px 0px #00000040",
                                            minHeight: "unset",
                                            height: "48px",
                                            display: "flex",
                                            padding: "0px 16px",
                                            outline: "0",
                                            position: "relative",
                                            alignItems: "center",
                                            userSelect: "none",
                                            borderRadius: "0",
                                            verticalAlign: "middle",
                                            color: "inherit",
                                            border: "0",
                                            cursor: step.attachments.length > 0 ? "pointer" : "auto",
                                            margin: "0",
                                            justifyContent: "center",
                                            textDecoration: "none",
                                        }}
                                    >
                                        <DivBackGroundPercentage
                                            style={{
                                                width: step.percent + "%",
                                                cursor: step.attachments.length > 0 ? "pointer" : "auto",
                                            }}
                                        />

                                        <LabelText> {step.name} </LabelText>&nbsp;
                                        <LabelText style={{ marginLeft: "auto" }}> {step.percent} % </LabelText>
                                    </AccordionSummary>

                                    <AccordionDetails
                                        style={{ backgroundColor: "rgba(var(--theme-color-rgb,  0, 107, 234), 0.4)" }}
                                    >
                                        <>
                                            {step.attachments.length > 0 ? (
                                                <Grid container>
                                                    <RenderFilesImgVideo attachments={step.attachments} />
                                                </Grid>
                                            ) : (
                                                <label>Nenhuma foto adicionada</label>
                                            )}
                                        </>
                                    </AccordionDetails>
                                </AccordionStyled>

                            </DivAcordion>
                        ))}

                    </DivSumary>
                </div>
            </DivSumary>
        </ContainerBack >
    );
}
