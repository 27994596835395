import React from "react";

import "./Button.sass";

import Button from "./Button";

export default React.forwardRef(({ className, children, ...props }, ref) => {
  let classes = `btn button-primary ${className}`;
  return (
    <Button {...props} className={classes} ref={ref}>
      {children}
    </Button>
  );
});
