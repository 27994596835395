import React  from 'react';
import styled from 'styled-components'
import { Tooltip } from "@material-ui/core";
import {Delete} from '@material-ui/icons';

const IconDeleteStyle = styled(Delete)`
  width: 19px;
  border-radius: 6px;
  opacity: 65%;
  &:hover{
    background-color: rgba(0, 0, 0, 0.1);
  };
`

export const ButtonLink = styled.button`
 color: #081630;
 padding: 0;
 text-decoration: none;
  background-color: transparent;
  border: none;
 font-size: 12px;
 &:hover{
    text-decoration: none; 
    color: #007bff;
 };
 color: #007bff;
`

export const IconButton = styled(ButtonLink)`
  &:not(:first-child) {
    margin-left: 5px;
  }
  > svg {
      padding: 1px;
      width: 25px;
      border-radius: 6px;
      opacity: 65%;
      &:hover{
        background-color: rgba(0, 0, 0, 0.1);
      };
  }
`

const style = { marginRight: "0px", marginLeft: "5px" }

export const TrashCanButton = ({ title, ...props  }) => (
    <ButtonLink style={style} {...props}>
        <Tooltip title={title}>
            <IconDeleteStyle color="error" />
        </Tooltip>
    </ButtonLink>
)


