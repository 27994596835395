import React from "react";
import "./Sac.sass";
import Input from "../Input/Input";
import FBButton from "../Button/FBButton";
import { handleChangeFormatDocument, handleChangeFormatPhone, validateDocument, validateEmail, validatePhone } from "~/utils/formatters";

export default ({ push, previous, register, handleSubmit, errors }) => {

    function onSubmit() {
        push("options-screen")
    }

    return (
        <div style={{ padding: "10px" }}>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <Input
                        maxLength={50}
                        autoFocus
                        placeholder="Insira seu nome"
                        cursor={"client_name"}
                        name="client_name"
                        {...register("client_name", { required: true })}
                        errors={errors}
                    />
                    <br />

                    <Input
                        maxLength={50}
                        placeholder="Insira seu e-mail"
                        cursor={"client_email"}
                        name="client_email"
                        {...register("client_email", { required: true, validate: { email: validateEmail } })}
                        errors={errors}
                    />
                    <br />

                    <Input
                        maxLength={50}
                        placeholder="Insira seu CPF/CNPJ"
                        cursor={"client_document"}
                        name="client_document"
                        {...register("client_document", { required: true, validate: { cpf: validateDocument } })}
                        onChange={handleChangeFormatDocument}
                        errors={errors}
                    />
                    <br />

                    <Input
                        maxLength={50}
                        placeholder="Insira seu telefone"
                        cursor={"client_phone"}
                        name="client_phone"
                        {...register("client_phone", { required: true, validate: { phone: validatePhone }  })}
                        onChange={handleChangeFormatPhone}
                        errors={errors}
                    />
                    <br />

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <FBButton
                            style={{ width: "100px", marginRight: "10px" }}
                            variant="outlined"
                            onClick={() => previous()}
                        >
                            Voltar
                        </FBButton>

                        <FBButton
                            style={{ width: "100px" }}
                            color='primary'
                            type="submit"
                        >
                            Próximo
                        </FBButton>
                    </div>

                </form>
            </div>
        </div>
    )
}



