import React, { Fragment } from "react";
import "./Input.sass";
import { UploadImage } from "~/components/Icons";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default ({
    className,
    id,
    children,
    image,
    cover_photo,
    handleImageChange,
    onDrop,
    onDragOver,
    labelAdd,
    result,
    loading,
    addornment,
    accept = "application/.pdf, application/pdf, video/quicktime, video/mov, video/.quicktime, video/.mov, image/.gif,image/gif,image/.jpg,image/.jpeg,image/.jpe,image/.svg,image/.tiff,image/.png,image/jpg,image/jpeg,image/svg,image/tiff,image/png,video/.mp4,video/.avi,video/mp4,video/avi"

}) => {

    const classes = `uploadimage ${className}`;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Fragment>
            <label
                onDragOver={onDragOver}
                onDrop={onDrop}
                fileInput
                htmlFor={id}
                className={classes}
            >
                {loading || (!image && !cover_photo ) && !addornment && (
                    <Fragment>
                        <UploadImage />
                        {
                            isMobile ?
                                (
                                    result ? (
                                        <span style={{ fontWeight: "300", fontSize: "10px" }}>
                      Clique para trocar {labelAdd || "fotos ou vídeos"}
                                        </span>
                                    ) : (
                                        <span>
                      Clique para adicionar
                                            <br />
                                            {labelAdd || "fotos ou vídeos"}
                                        </span>
                                    )

                                ) : (
                                    result ? (
                                        <span style={{ fontWeight: "300", fontSize: "10px" }}>
                      Clique ou arraste para trocar {labelAdd || "fotos ou vídeos"}
                                        </span>
                                    ) : (
                                        <span>
                      Clique ou arraste para adicionar
                                            <br />
                                            {labelAdd || "fotos ou vídeos"}
                                        </span>
                                    )
                                )
                        }

                    </Fragment>
                )}
                {addornment}
                {children}
            </label>
            <input
                type="file"
                id={id}
                name={id}
                multiple
                style={{ display: "none" }}
                accept={accept}
                onChange={handleImageChange}
            />
        </Fragment>
    );
};
