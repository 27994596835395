import React from 'react'
import CanvasDraw from 'react-canvas-draw'
import ButtonPrimary from '../Button/ButtonPrimary'
import ButtonSecondary from '../Button/ButtonSecondary'
import Label from '../Label'

export const Signature = React.forwardRef(({ text, showFooter, onSubmit, widthCanva }, ref) => {
    return (
      <div>
        <Label text={text}/>
        <CanvasDraw
          style={{
            width: widthCanva || 350,
            maxWidth:"790px",
            height: 350,
            position: 'relative',
          }}
          className='canvasDraw'
          brushRadius={1}
          lazyRadius={1}
            ref={ref}
        />
        <br/>
        {showFooter && (<div>
          <ButtonSecondary
            className='mr-1'
            onClick={() => ref.current.undo()}
          >
              Desfazer
          </ButtonSecondary>
              <ButtonSecondary
                className='mr-1'
                onClick={() => ref.current.clear()}
              >
                  Limpar
              </ButtonSecondary>
            <ButtonPrimary onClick={ref}>
              Confirmar
          </ButtonPrimary>
        </div>)}
      </div>
    )
})
