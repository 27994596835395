import React from 'react';

import { Container } from "react-bootstrap";
import "./styles.scss";
import FaqList from "../../components/FaqList/FaqList.js";
import { Link, useParams } from 'react-router-dom';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { track } from "~/services/track";

function Faq() {
  const { token } = useParams();
  return (
    <Container className="background-faq">
      <Link to={`/${token}/categories`} onClick={() => track("VOLTAR_PARA_CATEGORIAS")}>
        <div className="back-link">
          <ArrowBackIcon />
          <p>Voltar para Categorias</p>
        </div>
      </Link>
      <FaqList />
    </Container>
  );
}

export default Faq;
