import React from "react";

export default React.forwardRef(({ className, setDisabled, children, ...props }, ref) => {
  let classes = `button ${className}`;
  return (
    <button className={classes} {...props} ref={ref}>
      {children}
    </button>
  );
});
