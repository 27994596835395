import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import 'moment/locale/pt-br'
import api from '~/services/api';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Loading from '~/components/loading';
import { DividerStyle } from '../Custom';
import MaintenancesList from './MaintenancesList';

export default function UnitMaintenances() {

    const { token } = useParams()
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])

    useEffect(() => {
        async function loadData() {
            try {
                setLoading(true)
                const response = await api.get(`/v1/units/${token}/maintenances`)
    
                if (response.status !== 200) {
                    toast.error(response.data.message)
                } else {
                    setList(response.data.plan.tasks)
                }
            } catch (err) {
                toast.error(err.response?.data?.message || 'Não foi possível localizar a solicitação de personalização');
            }
    
            setLoading(false)
        }

        loadData();
    }, [token]);

    return (
        <div>
            <Container className='container-fluid-folder' fluid={true}>
                <Container className='container-folder-page'>
                    <br />
                    <div style={{ display: 'flex' }}>
                        <Link to={`/${token}`}>
                            <ArrowBack fontSize='large' />
                        </Link>
                        <h4 style={{ margin: "auto" }}> Manutenções preventivas </h4>
                        {loading && <div style={{ marginTop: "5px" }}><Loading /></div>}
                    </div>

                    <DividerStyle />

                    <div
                        display="inline-flex"
                        flexDirection="column"
                        style={{ width: '100%',}}>

                        <MaintenancesList list={list} />
                    </div>
                </Container>
            </Container>
        </div >
    );
}
