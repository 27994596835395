import React, { Fragment } from "react";

import "./Input.sass";

function Component({ placeholder, className, ...props }) {
  const classes = `input textera ${className}`;
  let textarea = document.querySelector("#autoresizing");
  textarea && textarea.addEventListener("input", autoResize, false);

  function autoResize() {
    this.style.height = "auto";
    this.style.height = this.scrollHeight + "px";
  }

  return (
    <textarea
      {...props}
      rows="1"
      id="autoresizing"
      placeholder={placeholder}
      className={classes}
    />
  );
}

export default function Textarea({ ...props }) {
  return (
    <Fragment>
      <Component {...props} />
    </Fragment>
  );
}
