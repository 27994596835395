import { useEffect, useRef, useState } from "react"

export const tap = fn => result => {
    fn(result)
    return result 
}

export const usePromise = (promise, map = d => d.data, defaultData) => {
    const [state, setState] = useState({ loaded: false, loading: true, data: defaultData, error: null })
    const care = useRef(true)

    useEffect(() => {
        care.current = true
        const fn = async () => {
            try {
                const res = await promise
                if (care.current) setState(st => ({ loaded: true, loading: false, data: map(res, st.data), error: null }))
            } catch (err) {
                if (care.current) setState({ loaded: true, loading: false, data: null, error: err })
            }
        }
        fn()
        
        return () => {
            care.current = false
        }
    }, [promise])

    return state
}
