import React, { useEffect, useState, Fragment, useRef, useMemo } from 'react';
import './Manual.sass';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import CanvasDraw from 'react-canvas-draw';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Assignment, Build, Description, HelpOutline, LocalShipping } from '@material-ui/icons';
import styled from 'styled-components';
import { uuid4 } from '@sentry/utils';
import { ReactComponent as Dicas } from '../../assets/folder_icons/dicas.svg';
import { ReactComponent as Nia } from '../../assets/nia.svg';
import { ReactComponent as IconConstruction } from '../../assets/folder_icons/icon_construction_4.svg';
import { TermoDeCompromisso } from './compromisso.js';
import { CenterSpinnerButton } from '~/components/Spinner';
import { TechnicalAssistance } from '~/components/Icons';
import Modal from '~/components/Modal';
import Label from '~/components/Label';
import Checkbox from '~/components/Checkbox';
import ButtonPrimary from '~/components/Button/ButtonPrimary';
import ButtonSecondary from '~/components/Button/ButtonSecondary';
import api from '~/services/api';
import { track, setTrackingCompany } from '~/services/track';
import { hexToRgb, renderIconSvg } from '~/services/utils';
import * as UnitActions from '~/store/modules/unit/actions';
import moment from 'moment';
import DraftsIcon from '@material-ui/icons/Drafts';
import { ReactComponent as IconCustom } from "../../assets/folder_icons/icon_custom.svg"
import { Card, ListCard } from '~/components/Card/card';
import { Box, Typography } from '@material-ui/core';
import {usePrimaryColor, useThemeModifier} from '~/contexts/custom-theme';
import StyledInnerHtml from '~/utils/StyledInnerHtml';

const HelpContainer = styled.div`
  display: flex;
  z-index: 99;
  position: fixed;
  > div {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: right;
  }
  @media only screen and (max-width: 800px) {
    position: absolute;
  }
`;

const newsBaseUrl = process.env.REACT_APP_URL || 'https://app.fastbuilt.com.br';

export default function Manual() {
    const dispatch = useDispatch();

    const { token } = useParams();
    const detailRef = useRef();
    const date = useMemo(() => moment().format("DD/MM/YYYY"), [])

    const [loading, setLoading] = useState(true);
    const [nickName, setNickName] = useState();
    const [folders, setFolders] = useState('');
    const [recentFiles, setRecentFiles] = useState([]);
    const [assistance, setAssistance] = useState('');
    const [manual, setManual] = useState('');
    const [openModalSignature, setOpenModalSignature] = useState(true);
    const [termsRead, setTermsRead] = useState(false);
    const [termsCompany, setTermsCompany] = useState(false);
    const [termsManual, setTermsManual] = useState(false);
    const [hasCustomization, setHasCustomization] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState();
    const [requestManualSignature, setrequestManualSignature] = useState();
    const [hasMonitor, setHasMonitor] = useState();
    const [showUnitGuarantees, setShowUnitGuarantees] = useState();
    const [showInspectionAttachments, setShowInspectionAttachments] = useState();
    const [hasNews, setHasNews] = useState(false);
    const [companyMessageUnit, setCompanyMessageUnit] = useState("");
    const [openModalSignatureCompanyMessage, setOpenModalSignatureCompanyMessage] = useState(true);
    const [requiredMessage , setRequiredMessage] = useState();
    const [signatureMessage, setSignatureMessage] = useState();
    const [buildingSupplier, setBuildingSupplier] = useState();

    const unit_detail = useSelector(store => store.unit.detail);
    const news_url = `${newsBaseUrl}/inteligencia-de-vendas/novidades/${token}/${nickName}`;
    const { isOriginal } = useThemeModifier()

    let signatureCanvasRef = useRef(null);
    let signatureMessageCompany = useRef(null);
    const history = useHistory()

    async function getUnitDetails() {
        try {
            const response = await api.get(`v1/units/${token}`);
            setLoading(false);

            const [unit] = response.data

            setHasNews(unit.has_news)
            setHasCustomization(response.data[0].customization)
            setrequestManualSignature(unit.building.request_manual_signature);
            setHasMonitor(unit.building.has_monitor);
            setShowUnitGuarantees(unit.building.show_unit_guarantees);
            dispatch(UnitActions.saveUnit(unit));
            setShowInspectionAttachments(unit.has_inspection_attachments);
            setFolders(unit.folders);
            setRecentFiles(unit.recent_files || [])
            setManual(unit.building.manual_limits);
            setAssistance(unit.building.assitance_limits);
            setBackgroundImage(unit.building.background_photo);
            setCompanyMessageUnit(unit.building.company_message);
            setRequiredMessage(unit.building.required_message_signature)
            setBuildingSupplier(unit.building?.building_supplier?.enabled)
            setSignatureMessage(unit.company_message_signature)           
            if (unit.company_image_url) {
                try {
                    document.querySelector('link[rel="icon"]').setAttribute("href", unit.company_image_url);
                    document.querySelector('link[rel="apple-touch-icon"]').setAttribute("href", unit.company_image_url);
                } catch (err) {
                    console.error(err)
                }
            }

            const {company} = unit.building
            if (company) {
                setTrackingCompany({
                    companyName: company.name,
                    state: company.state,
                    city: company.city,
                    companyId: company.id,
                });

                if (response) {
                    setNickName(company.nick_name);
                }
            } else {
                getCompanyNickName();
            }

        } catch (error) {
            console.error(error)
            Sentry.captureException(error)
            if (error.response?.status !== 401) {
                toast.error("Não foi possível localizar o código informado.");
                history.push("/");
            }
        }
    }
    const originalTitle = useMemo(() => {
        return {
            icon: document.querySelector('link[rel="icon"]').getAttribute("href"),
            appleTouchIcon: document.querySelector('link[rel="apple-touch-icon"]').getAttribute("href")
        }
    }, [])
    useEffect(() => {
        getUnitDetails();
        return () => {
            document.querySelector('link[rel="icon"]').setAttribute("href", originalTitle.icon);
            document.querySelector('link[rel="apple-touch-icon"]').setAttribute("href", originalTitle.appleTouchIcon);
        }
    }, [token, originalTitle]);
    async function getCompanyNickName() {
        if (unit_detail) {
            const response = await api.get(`v1/companies/${unit_detail.company_id}`);
            setTrackingCompany({
                companyName: response.data.name,
                state: response.data.state,
                city: response.data.city,
                companyId: unit_detail.company_id,
            });

            if (response) {
                setNickName(response.data.nick_name);
            }
        }
    }

    function closeModal() {
        setOpenModalSignature(!openModalSignature);
        getUnitDetails();
    }
    function closeModalSignature() {
        setOpenModalSignatureCompanyMessage(!openModalSignatureCompanyMessage)
        getUnitDetails();
    }

    function registerManualSignature(node) {
        if (!termsManual) {
            return toast.error(
                'É necessário confirmar que recebeu o Manual do Proprietário!',
            );
        }

        if (signatureCanvasRef.lines.length === 0) {
            return toast.error('A assinatura não pode estar em branco!');
        }

        signatureCanvasRef.canvas.drawing.toBlob(async data => {
            setLoading(true);
            const fileType = data.type;
            // Blob-to-file
            data.lastModifiedDate = new Date();
            data.name = `manual_signature_${uuid4()}.png`;
            try {
                const result = await api.get(`/v1/units/${token}/attachments/upload?extension=${fileType}`,);
                const options = { headers: { 'Content-Type': fileType } };
                await axios.create().put(result.data.uploadUrl, data, options);

                const body = { manual_deliver_signature: result.data.resultUrl, term_signed: node.textContent };
                await api.put(`v1/units/${token}/v2`, body);
                closeModal();
                setLoading(false);
                toast.success('Documento assinado com sucesso.');
            } catch (err) {
                console.error(err);
                toast.error(err?.response?.data?.message || 'Não foi possível fazer o upload da imagem!');
                Sentry.captureException(err);
                setLoading(false);
            }
        });
    }

    function registerSignature() {
        if (!termsRead) {
            return toast.error('É necessário aceitar os termos de privacidade!');
        }

        if (signatureCanvasRef.lines.length === 0) {
            return toast.error('A assinatura não pode estar em branco!');
        }

        signatureCanvasRef.canvas.drawing.toBlob(async data => {
            setLoading(true);
            const fileType = data.type;
            let uploadFile;
            // Blob-to-file
            data.lastModifiedDate = new Date();
            data.name = `signature_${uuid4()}.png`;
            try {
                try {
                    const result = await api.get(`/v1/units/${token}/attachments/upload?unit_guid=${unit_detail.guid}&extension=${fileType}`,);
                    const options = {
                        headers: {
                            'Content-Type': fileType,
                        },
                    };
                    await axios.create().put(result.data.uploadUrl, data, options);
                    uploadFile = result.data.resultUrl;
                    if (signatureCanvasRef) {
                        signatureCanvasRef.clear()
                    }
                } catch (error) {
                    console.error(error);
                    toast.error('Não foi possível fazer o upload da imagem!');
                    Sentry.captureException(error);
                    setLoading(false);
                    return;
                }

                const body = {
                    signature_url: uploadFile,
                };
                await api.put(`v1/units/${token}/v2`, body);
                setLoading(false);
                toast.success('Documento assinado com sucesso.');
                closeModal();
            } catch (error) {
                Sentry.captureException(error);
                console.error(error);
                setLoading(false);
                toast.error('Não foi possível enviar sua solicitação.');
            }
        });
    }
    function registerSignatureMessageCompany() {
        if (!termsCompany) {
            return toast.error('É necessário aceitar os termos de privacidade!');
        }
        if (signatureMessageCompany.lines.length === 0) {
            return toast.error('A assinatura não pode estar em branco!');
        }
        signatureMessageCompany.canvas.drawing.toBlob(async data => {
            setLoading(true);
            const fileType = data.type;
            let uploadFile;
            data.lastModifiedDate = new Date();
            data.name = `signature_${uuid4()}.png`;
            try {
                try {
                    const result = await api.get(`/v1/units/attachments/upload?unit_guid=${unit_detail.guid}&extension=${fileType}`,);
                    const options = {
                        headers: {
                            'Content-Type': fileType,
                        },
                    };
                    await axios.create().put(result.data.uploadUrl, data, options);
                    uploadFile = result.data.resultUrl;
                    if (signatureMessageCompany) {
                        signatureMessageCompany.clear()
                    }
                } catch (error) {
                    console.log(error)
                    toast.error('Não foi possível fazer o upload da imagem!')
                    Sentry.captureException(error);
                    setLoading(false);
                    return;
                }
                const body = {
                    company_message_signature: uploadFile,
                    company_message_signature_date: new Date(),
                    company_message:companyMessageUnit
                };
                await api.put(`v1/units/${token}/company-message`, body);
                setLoading(false);
                toast.success('Documento assinado com sucesso!')
                closeModalSignature();
            } catch (error) {
                Sentry.captureException(error);
                console.error(error)
                setLoading(false);
                toast.error('Não foi possivel enviar sua solicitação')
            }
        })
    }

    const primaryColor = usePrimaryColor()
    const style = { fill: primaryColor }

    return (
        <div>

            <Container
                style={!isOriginal && primaryColor ?
                    { '--custom-background': `rgba(${hexToRgb(primaryColor).join(",")}, 0.05)`,
                        '--primary-color': primaryColor
                    }:
                    {}}
                className='container-fluid-home'
                fluid={true}
            >
                <div className='building-img' style={{ backgroundImage: `url(${backgroundImage})` }} />
                {unit_detail && <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                    <img
                        src={unit_detail.building.logo ? unit_detail.building.logo : unit_detail.company_image_url}
                        style={{ width: 150, marginTop: 30 }}
                        alt="Logo"
                    />
                    <Typography variant="h6" style={{ marginTop: 15, padding: "0 10px" }} align="center">
                        {unit_detail.building.name} | {unit_detail.name}
                    </Typography>

                </Box>}

                <Container className='container-manual-page'>

                    {loading ? (
                        <CenterSpinnerButton color={'#fff'} />
                    ) : (
                        Boolean(folders) && (
                            <Fragment>
                                {assistance === true && unit_detail && (
                                    <Card
                                        badgeContent={parseInt(unit_detail.client_unread_count || "0") || 0}
                                        event={track('ASSISTENCIA_TECNICA')}
                                        to={`/${token}/assistencia/`}
                                        icon={<TechnicalAssistance fill={style.fill} />}
                                    >
                                        Assistência Técnica
                                    </Card>
                                )}
                                {Boolean(recentFiles.length) && (
                                    <ListCard items={recentFiles} title="Arquivos recentes" />
                                )}
                                {hasMonitor && (
                                    <Card to={`/${token}/workMonitor`} event="ACOMPANHAMENTO_OBRA" icon={(<IconConstruction style={style} />)}>
                                        Andamento da Obra
                                    </Card>
                                )}

                                {unit_detail && unit_detail.availability && (
                                    <Card
                                        icon={renderIconSvg({ name: "ic_calendar", style })}
                                        to={`/${token}/inspection/schedule/${unit_detail.availability}${unit_detail.availability_type ? `/${unit_detail.availability_type}` : ""}`}
                                        event="CLICOU_EM_AGENDAR_VISTORIA"
                                    >
                                        Agendar Vistoria
                                    </Card>
                                )}
                                {unit_detail && unit_detail.finishes && (
                                    <Card
                                        to={`/${token}/finishes/${unit_detail.finishes}`}
                                        icon={renderIconSvg({ name: "ic_project", style })}
                                        event='CLICOU_EM_CUSTOMIZACOES'>
                                        Minhas Customizações
                                    </Card>
                                )}
                                {hasCustomization && <Card
                                    to={`/${token}/customizacoes`}
                                    event='CLICOU_EM_PERSONALIZACAO'
                                    icon={<IconCustom style={{ ...style, strokeWidth: "3px" }} />}
                                >
                                    Minhas Personalizações
                                </Card>
                                }
                                {manual === true
                                    && folders.map((folder, i) => {
                                        const { guid, name } = folder;
                                        return (
                                            <Card
                                                key={i}
                                                icon={renderIconSvg({ name: folder.icon || "folder", style })}
                                                to={`/${token}/folder/${guid}`}
                                                event='CLICOU_PASTA_PRIMEIRA_PAGINA'
                                                metadata={{ guid, token, folderName: name }}
                                            >
                                                {name}
                                            </Card>
                                        );
                                    })}
                                {(unit_detail?.building.unit_maintenance_plan?.enabled) &&
                                    <Card
                                        to={`/${token}/manutencoes`}
                                        event='MANUTENCAO_PREVENTIVA'
                                        icon={<Build color="primary" />}
                                    >
                                        Manutenções Preventivas
                                    </Card>
                                }
                                {showUnitGuarantees &&
                                    <Card
                                        to={`/${token}/showUnitGuarantees`}
                                        event='GARANTIAS_DO_EMPREENDIMENTO'
                                        icon={<Description color="primary" />}
                                    >
                                        Garantias do Empreendimento
                                    </Card>
                                }

                                {buildingSupplier &&
                                    <Card
                                        to={`/${token}/suppliers`}
                                        event='FORNECEDORES'
                                        icon={<LocalShipping color="primary" />}
                                    >
                                        Fornecedores
                                    </Card>
                                }
                                {showInspectionAttachments &&
                                    <Card
                                        to={`/${token}/inspection/attachments`}
                                        event='RELATORIOS_DE_VISTORIA'
                                        icon={<Assignment color="primary" />}
                                    >
                                        Relatórios de vistoria
                                    </Card>
                                }

                                <Card to={`/${token}/signedTerms`} event="TERMOS_ASSINADOS" icon={(<DraftsIcon color="primary"/>)}>
                                    Termos
                                </Card>

                                <Card
                                    to={`/${token}/categories`}
                                    event='NIA'
                                    icon={(<Nia style={style} />)}
                                >
                                    NIA - Assistente Virtual
                                </Card>
                                {nickName && hasNews && (
                                    <a href={news_url} target="_blank" rel='noopener noreferrer'> 
                                        <Card event="NOVIDADES" icon={ <Dicas style={style}/> }> Novidades </Card>
                                    </a>
                                )}
                                {unit_detail && !unit_detail.manual_deliver_signature && requestManualSignature && unit_detail.signature_url && (
                                    <Modal
                                        centered
                                        size='xl'
                                        dialogClassName='modal-90w'
                                        aria-labelledby='contained-modal-title-vcenter'
                                        show
                                        handleClose={() => { }}
                                    >
                                        <Container className='container-forms'>
                                            <h2 style={{ textAlign: "center" }}>Termo de recebimento</h2>
                                            <div ref={detailRef} style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
                                                <Label text={`Edifício: ${unit_detail.building.name}.`} />
                                                <Label text={`Unidade: ${unit_detail.name}.`} />
                                                <p style={{ whiteSpace: "pre-line" }}>
                                                    {unit_detail.term?.trim() ?
                                                        unit_detail.term?.trim() : (
                                                            <>
                                                                O adquirente do imóvel, conforme Contrato de Promessa de Compra e Venda, declara expressamente nesta data, que recebeu para uso, o referido imóvel em perfeitas condições de habitabilidade, tendo efetuado vistoria, e constatado que todas as suas instalações estão em perfeito estado de funcionamento, bem como não apresenta nenhum vício ou defeito aparente.
                                                                Declara também que está ciente, que a partir desta data, passa a contar com as garantias que lhe são asseguradas pelo Código de Defesa do Consumidor, fixando-se em 90 (noventa) dias a responsabilidade do construtor quanto aos vícios aparentes ou de fácil constatação ou ocultos, e, em cinco anos quanto à solidez da estrutura.
                                                                Declara ainda, que está de pleno acordo que até a data da efetiva instalação do condomínio da referida edificação, todas as despesas decorrentes com a administração, vigilância, água, limpeza, energia, manutenção de elevadores, das partes e coisas condominiais, e as com pessoal, inclusive os encargos sociais - serão rateados proporcionalmente entre os apartamentos do edifício, observando-se as disposições específicas sobre o assunto existente na Convenção de Condomínio e no instrumento particular de promessa de compra e venda da aludida unidade habitacional.
                                                                Concorda que, para prevenir dúvidas, todas as comunicações com a Construtora e Incorporadora deverão ser sempre feitas através da plataforma fornecida, através de pedido de assistência técnica, pela que se obriga a tanto.
                                                                Concorda com qualquer possível modificação que tenha feito no projeto, nas especificações ou no acabamento da unidade e do prédio, tendo-se como necessárias ao atendimento de aspectos técnicos; ou em decorrência de qualidade e segurança, ou ainda, por falta no mercado de materiais originalmente anunciado.
                                                                Declara que recebeu via única do MANUAL DE USO E MANUTENÇÃO DO IMÓVEL.
                                                                A partir do presente momento, o adquirente assume a responsabilidade pela transmissão do MANUAL DE USO E MANUTENÇÃO DO IMÓVEL em caso de alienação, dação em pagamento, cessão de direitos e/ou quaisquer outras formas de transmissão da posse e propriedade da referida unidade condominial.
                                                                Dá, em consequência, ampla, geral, rasa e irrevogável quitação a incorporadora e construtora da edificação.
                                                            </>)}
                                                </p>

                                                <br />
                                                <b>
                                                    {date}.
                                                </b>
                                            </div>

                                            <div style={{ padding: '10px 0' }}>
                                                <Checkbox
                                                    value={termsRead}
                                                    onChange={() => setTermsManual(!termsManual)}
                                                    label={
                                                        'Confirmo o recebimento do Manual do Proprietário!'
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <Label text='Realize a assinatura abaixo para concordar com os termo de recebimento:' />
                                                <CanvasDraw
                                                    style={{
                                                        width: '100%',
                                                        position: 'relative',
                                                    }}
                                                    className='canvasDraw'
                                                    brushRadius={1}
                                                    lazyRadius={1}
                                                    ref={canvasDraw =>
                                                        (signatureCanvasRef = canvasDraw)
                                                    }
                                                />
                                                <div>
                                                    <ButtonSecondary
                                                        className='mr-1'
                                                        onClick={() => signatureCanvasRef.undo()}
                                                    >
                                                        Desfazer
                                                    </ButtonSecondary>
                                                    <ButtonSecondary
                                                        className='mr-1'
                                                        onClick={() => signatureCanvasRef.clear()}
                                                    >
                                                        Limpar
                                                    </ButtonSecondary>
                                                    <ButtonPrimary onClick={() => registerManualSignature(detailRef.current)}>
                                                        Confirmar
                                                    </ButtonPrimary>
                                                </div>
                                            </div>
                                        </Container>
                                    </Modal>
                                )}
                                {unit_detail && !unit_detail.signature_url && (
                                    <Modal
                                        centered
                                        size='xl'
                                        dialogClassName='modal-90w'
                                        aria-labelledby='contained-modal-title-vcenter'
                                        show={openModalSignature}
                                        handleClose={() => { }}
                                    >
                                        <Container className='container-forms'>
                                            <div>
                                                <h1>POLÍTICA DE PRIVACIDADE</h1>
                                            </div>
                                            <div>
                                                <TermoDeCompromisso />
                                                <div style={{ padding: '10px 0' }}>
                                                    <Checkbox
                                                        value={termsRead}
                                                        onChange={() => setTermsRead(!termsRead)}
                                                        label={'Eu li e aceito os termos de privacidade!'}
                                                    />
                                                </div>
                                                <div>
                                                    <Label text='Realize a assinatura abaixo para concordar com os termos de compromisso:' />
                                                    <CanvasDraw
                                                        style={{
                                                            width: '100%',
                                                            position: 'relative',
                                                        }}
                                                        className='canvasDraw'
                                                        brushRadius={1}
                                                        lazyRadius={1}
                                                        ref={canvasDraw =>
                                                            (signatureCanvasRef = canvasDraw)
                                                        }
                                                    />
                                                    <div>
                                                        <ButtonSecondary
                                                            className='mr-1'
                                                            onClick={() => signatureCanvasRef.undo()}
                                                        >
                                                            Desfazer
                                                        </ButtonSecondary>
                                                        <ButtonSecondary
                                                            className='mr-1'
                                                            onClick={() => signatureCanvasRef.clear()}
                                                        >
                                                            Limpar
                                                        </ButtonSecondary>
                                                        <ButtonPrimary onClick={registerSignature}>
                                                            Confirmar
                                                        </ButtonPrimary>
                                                    </div>
                                                </div>
                                            </div>
                                        </Container>
                                    </Modal>
                                )}
                                {(requiredMessage && companyMessageUnit && !signatureMessage) && (unit_detail.signature_url && (requestManualSignature ? unit_detail.manual_deliver_signature: true)) && (
                                    <Modal
                                        centered
                                        size='xl'
                                        dialogClassName='modal-90w'
                                        aria-labelledby='contained-modal-title-vcenter'
                                        show
                                        handleClose={() => { }}
                                    >
                                        <Container className='container-forms'>
                                            <div>
                                                <h1 style={{marginLeft:"30%"}}>Aviso da construtora</h1>
                                                <hr style={{ border: '1px solid black', position:"absolute", top:"60px",width: "93%" }} />
                                            </div>
                                            <div>
                                                <StyledInnerHtml htmlContent={companyMessageUnit} />

                                                <div style={{ padding: '10px 0' }}>
                                                    <Checkbox
                                                        value={termsCompany}
                                                        onChange={() => setTermsCompany(!termsCompany)}
                                                        label={'Eu li e estou de acordo!'}
                                                    />
                                                </div>
                                                <div>
                                                    <Label text='Realize a assinatura abaixo para concordar com os termos de compromisso:' />
                                                    <CanvasDraw
                                                        style={{
                                                            width: '100%',
                                                            position: 'relative',
                                                        }}
                                                        className='canvasDraw'
                                                        brushRadius={1}
                                                        lazyRadius={1}
                                                        ref={canvasDraw =>
                                                            (signatureMessageCompany = canvasDraw)
                                                        }
                                                    />
                                                    <div>
                                                        <ButtonSecondary
                                                            className='mr-1'
                                                            onClick={() => signatureMessageCompany.undo()}
                                                        >
                                                            Desfazer
                                                        </ButtonSecondary>
                                                        <ButtonSecondary
                                                            className='mr-1'
                                                            onClick={() => signatureMessageCompany.clear()}
                                                        >
                                                            Limpar
                                                        </ButtonSecondary>
                                                        <ButtonPrimary onClick={registerSignatureMessageCompany}>
                                                            Confirmar
                                                        </ButtonPrimary>
                                                    </div>
                                                </div>
                                            </div>
                                        </Container>
                                    </Modal>
                                )}

                            </Fragment>
                        )
                    )}
                </Container>
                {!loading && <HelpContainer
                    className='help-container'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <a
                        href='https://fastbuilt.notion.site/Utilizando-a-plataforma-FastBuilt-4d07a224ed55444282d4fcfc9f32afd2'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <Row>
                            <HelpOutline color="primary" />
                            <div>
                                <small>Ajuda</small>
                            </div>
                        </Row>
                    </a>
                </HelpContainer>}
            </Container>
        </div>
    );
}
