import React, { useState } from "react";
import "./Sac.sass";
import axios from "axios";
import { toast } from "react-toastify";
import { Container, Spinner } from "react-bootstrap";
import Label from "~/components/Label";
import Textarea from "~/components/Input/Textarea";
import InputImage from "~/components/Input/InputImage";
import { Pdf, Video } from "~/components/Icons";
import api from "~/services/api";
import { useSelector } from "react-redux";
import Loading from "../loading";
import { randomValue } from "~/services/utils";
import FBButton from "../Button/FBButton";
import history from "~/services/history";
import { ModalHeader } from "../Layout";
import { Box, Typography } from "@material-ui/core";
import { CameraAltOutlined } from "@material-ui/icons";
import {useParams} from "react-router-dom";

export default ({ 
    nickName, 
    guidUnit, 
    previous, 
    reset,
    guid_conversation,
    construction_system,
    name,
    phone,
    email,
    documentNumber,
    identify,
    acceptedOpeningTerm }) => {

    const [loading, setLoading] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [files, setFiles] = useState([]);
    const [text, setText] = useState("");
    const unit_detail = useSelector((store) => store.unit.detail);

    const { token } = useParams()
    const dropped = async (event) => {
        event.preventDefault()
        const newFiles = []

        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
            for (const item of event.dataTransfer.items) {
                newFiles.push(item.getAsFile())
            }
        } else if (event.dataTransfer.files.length > 0) {
            for (const item of event.dataTransfer.files) {
                newFiles.push(item)
            }
        }

        selectFiles(newFiles)
    }
    const dragover = (event) => {
        event.preventDefault()
    }

    function handleImageChange(event) {
        selectFiles(event.target.files)
    }

    function selectFiles(selected) {

        const newFiles = Array.from(selected)
        setFiles(fs => [...fs, ...newFiles.map(f => ({ name: f.name, type: f.type, file: f }))])

        const state = {}
        newFiles.forEach(f => {
            state[f.name] = true
        });

        setLoadingState(l => ({ ...l, ...state }))

        if (token){
            newFiles.forEach((f) => {
                api.get(`/v1/units/${token}/services_request/attachments/upload?unit_guid=${guidUnit || unit_detail.guid}&sac_guid=${guid_conversation}&extension=${f.type}&t=${randomValue()}`).then(result => {
                    const options = { headers: { "Content-Type": f.type, }, };
                    axios.put(result.data.uploadUrl, f, options).then(() => {
                        setFiles(fs =>
                            fs.map(file => file.file === f ? { ...file, resultUrl: result.data.resultUrl } : file))
                        setLoadingState(ls => ({ ...ls, [f.name]: false }))
                    })
                })
            })

        }else{
            newFiles.forEach((f) => {
                api.get(`/v1/services_request/attachments/upload?unit_guid=${guidUnit || unit_detail.guid}&sac_guid=${guid_conversation}&extension=${f.type}&t=${randomValue()}`).then(result => {
                    const options = { headers: { "Content-Type": f.type, }, };
                    axios.put(result.data.uploadUrl, f, options).then(() => {
                        setFiles(fs =>
                            fs.map(file => file.file === f ? { ...file, resultUrl: result.data.resultUrl } : file))
                        setLoadingState(ls => ({ ...ls, [f.name]: false }))
                    })
                })
            })
        }
    }

    function closeModal(linkPush) {
        setFiles([]);
        setText("");

        if (guid_conversation) {
            toast.success("Mensagem enviada com sucesso.")
            nickName ? previous() : reset()
        } else {
            toast.success("Chamado aberto com sucesso.")
            nickName ? history.push(`/${linkPush}`) : reset()
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);

        try {
            const body = {
                guid: unit_detail?.client_guid,
                unit_guid: unit_detail?.guid || guidUnit,
                construction_system: construction_system.type,
                construction_system_guid: construction_system.guid,
                attachment_url: files[0]?.resultUrl,
                attachments: files.map(f => ({ name: f.name, attachment_url: f.resultUrl })),
                text,
                name,
                phone,
                email,
                document_number: documentNumber,
                identify,
                acceptedOpeningTerm
            };

            if (!text) {
                toast.error("É obrigatório informar uma mensagem")
                return
            }

            let result
            if(token){
                if (guid_conversation) {
                    await api.post(`v1/units/${token}/services_request/${guid_conversation}/messages`, body)
                } else {
                    result = await api.post(`v1/units/${token}/services_request`, body);
                }
            }else{
                if (guid_conversation) {
                    await api.post(`v1/services_request/${guid_conversation}/messages`, body)
                } else {
                    result = await api.post(`v1/services_request`, body);
                }
            }
            closeModal(result?.data?.link_sac_guid);
            
        } catch (e) {
            console.error(e)
            toast.error(e.response?.data?.message || "Não foi possível enviar sua solicitação.");
        } finally {
            setLoading(false)
        }

    }

    return (
        <Container >
            <ModalHeader title="Descreva sua solicitação" previous={previous} />
            <div onDrop={dropped} onDragOver={dragover}>
                <form onSubmit={handleSubmit}>
                    <Label text="Mensagem" />
                    <Textarea
                        onChange={(e) => setText(e.target.value)}
                        maxLength="1000"
                        placeholder="Descreva aqui o problema ou situação."
                    />
                    <InputImage
                        id="image"
                        cover_photo={files.length}
                        idLabel={"dropContainer"}
                        className="sac-upload-input"
                        handleImageChange={handleImageChange}
                        addornment={(
                            <Box className="fontprimary" display="flex" alignItems="center" sx={{ pt: 2 }} style={{ gap: 10 }}>
                                <CameraAltOutlined style={{ margin: 0 }} />
                                <Typography>
                                    Anexar documentos
                                </Typography>
                            </Box>
                        )}
                    >
                        <div style={{ display: 'inline-flex', flexWrap: "wrap", overflow: "auto", width: "100%" }}>
                            {files.map(file => (
                                <div key={file.resultUrl} className="attachment-preview" title={file.name}>
                                    {loadingState[file.name] ? (<Loading />
                                    ) : (
                                        <>
                                            {file.type.includes("image") && (
                                                <img src={file.resultUrl} alt={file.name} />
                                            )}
                                            {file.type.includes("pdf") && <Pdf />}
                                            {file.type.includes("video") && <Video />}
                                        </>
                                    )}
                                    {file.name}
                                </div>
                            ))}
                        </div>
                    </InputImage>

                    <div className="v2-modal-footer">
                        <FBButton
                            style={{ width: "100px", marginRight: "10px" }}
                            variant="outlined"
                            onClick={() => previous()}
                        > Voltar </FBButton>
                        <FBButton
                            style={{ width: "100px" }}
                            disabled={loading || Object.values(loadingState).some(v => v)}
                            color='primary'
                            onClick={handleSubmit}
                        >
                            {loading ? <Spinner size="sm" animation="border" /> : "Enviar"}
                        </FBButton>
                    </div>
                </form>
            </div>
        </Container >
    );
};
