import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import 'moment/locale/pt-br'
import api from '~/services/api';
import { Box, Typography, AccordionSummary, Accordion, AccordionDetails, ImageList, Tooltip, Input } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Loading from '~/components/loading';
import { ClickableAttachment } from '~/components/Sac/Attachment';
import { DividerStyle } from '.';
import { ExpandMore } from '@material-ui/icons';
import FBButton from '~/components/Button/FBButton';
import Checkbox from "../../components/Checkbox";
import { formatMoneyDivide } from '~/utils/money';
import { ImageContainer } from '../Inspection/Sign';
import InputImage from '~/components/Input/InputImage';
import axios from 'axios';
import { Signature } from '~/components/Signature';
import { validateDocument } from '~/utils/formatters';

const DivButton = styled.div`
    margin: 0px 0px 10px 0px;
    max-width: 150px;
    @media (max-width: 999px) {
         max-width: 100%;
    }
`

const DivAcordion = styled.div`
    margin-Top: 10px;
    margin-bottom: 10px;
    position: relative;
    border-Radius: 6px;
    overflow: hidden;
    box-Shadow: 0px 4px 4px 0px #00000040;
`

const AccordionStyled = styled(Accordion)`
    box-Shadow: 0px 4px 4px 0px #00000040;
    border-Radius: 6px;
    position: relative;
`

const StyledGrid = styled(ImageList)`
    img, video {
        max-width: 100%;
        max-height: 50px !important;
    }
    > div {
        border-radius: 6px;
        min-width: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer-card {
        border-radius: 6px;
        display: flex;
        width: 80%;
        text-align: center;
        justify-content: center;
    }
    .footer-card a {
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`

const defaultResponseCustomer = {
    aproveProjectAttached: false,
    aproveBudget: false,
    aproveprojectFinished: false,
    selfie_key: undefined,
    signature_key: undefined,
    document: undefined
}

export default function AproveProject() {

    const { token, id } = useParams()

    const [expanded, setExpanded] = useState({});
    const [loading, setLoading] = useState(false)
    const [detailsProject, setDetailsProject] = useState(undefined)
    const [step, setStep] = useState(1)
    const [completed, setCompleted] = useState(false)
    const [responseCustomer, setResponseCustomer] = useState(defaultResponseCustomer)
    const [chatId, setChatId] = useState(undefined)

    const drawingRef = useRef(null);

    const handleChange = (panel) => () => {
        setExpanded((expanded) => ({ ...expanded, [panel]: !expanded[panel] }));
    };

    useEffect(() => {
        async function loadData() {
            try {
                setLoading(true)
                const response = await api.get(`v1/${token}/customization/estimate/${id} `);
                const result = await api.get(`v1/${token}/customization `);
                if (response.status !== 200) {
                    toast.error(response.data.message)
                } else {
                    setDetailsProject(response.data.estimate)
                    setChatId(result.data.chat.id)
                }

            } catch (err) {
                toast.error(err.response?.data?.message || 'Não foi possível localizar a solicitação de personalização');
            }

            setLoading(false)
        }

        loadData();
    }, [id, token]);

    function onChangeSelect(ev) {
        const { name, checked } = ev.target;

        setResponseCustomer((details) => {
            return {
                ...details,
                [name]: checked,
            };
        });
    }

    async function handleImageChange(event) {
        const [selfie] = Array.from(event.target.files);
        if (!selfie) { return }
        setResponseCustomer(r => ({ ...r, selfie_key: { name: selfie.name, loading: true } }));
        const url = `/v1/${token}/customization/chat/${chatId}/attachment_private?extension=${selfie.type}`
        const result = await api.get(url);
        setResponseCustomer(r => ({ ...r, selfie_key: { ...r.selfie_key, ...result.data } }));
        const options = { headers: { 'Content-Type': selfie.type } };
        await axios.put(result.data.uploadUrl, selfie, options);
        setResponseCustomer(r => ({ ...r, selfie_key: { ...r.selfie_key, loading: false } }));
    }

    async function onSubmit() {

        if (drawingRef.current.lines.length === 0) {
            return toast.warn('A assinatura não pode estar em branco!');
        }
        if (!responseCustomer.document) {
            return toast.warn('O documento é obrigatório');
        }
        if (!validateDocument(responseCustomer.document)) {
            return toast.warn('Insira um cpf válido');
        }
        if (!responseCustomer.aproveProjectAttached) {
            return toast.warn('Aprove os projetos anexados');
        }
        if (!responseCustomer.aproveBudget) {
            return toast.warn('Aprove o orçamento demostrado');
        }
        if (!responseCustomer.aproveprojectFinished) {
            return toast.warn('Aprove a personalização');
        }
        if (!responseCustomer.selfie_key.fileKey) {
            return toast.warn('Insira uma foto segurando seu documento');
        }

        setLoading(true)

        drawingRef.current.canvas.drawing.toBlob(async data => {
            const fileType = data.type;
            data.lastModifiedDate = new Date();
            data.name = `signature_${Number(new Date())}.png`;
            try {
                const url = `/v1/${token}/customization/chat/${chatId}/attachment_private?extension=${fileType}`
                const resultSignature = await api.get(url);

                const options = { headers: { 'Content-Type': fileType } };
                await axios.put(resultSignature.data.uploadUrl, data, options);

                const body = {
                    selfie_key: responseCustomer.selfie_key.fileKey,
                    signature_key: resultSignature.data.fileKey,
                    document: responseCustomer.document
                }

                const resultAprove = await api.post(`/v1/${token}/customization/estimate/${detailsProject.id}?`, body)

                if (resultAprove.status === 200) {
                    toast.success("Personalização aprovada com sucesso")
                    setCompleted(true)
                }

            } catch (err) {
                console.error(err)
                toast.error(err?.response?.data?.message || 'Não foi possível aprovar o orçamento!');
                setLoading(false);
            }
        });

        setLoading(false)
    }

    return (
        <div>
            <Container className='container-fluid-folder' fluid={true}>
                <Container className='container-folder-page'>
                    <br />

                    <div style={{ display: 'flex' }}>
                        <Link to={(step === 1 || completed) && `/${token}/customizacoes`}
                            onClick={() => step === 2 && setStep(1)}
                        >
                            <ArrowBack fontSize='large' />
                        </Link>
                        <h2 style={{ marginLeft: 30 }}> Aprovar projeto</h2>
                        {loading && <div style={{ marginTop: "5px" }}><Loading /></div>}
                    </div>
                    {!loading &&
                        <Box
                            display="inline-flex"
                            flexDirection="column"
                            width={"100%"}
                        >
                            <div iv style={{ marginTop: "-30px" }}>
                                <DividerStyle />
                            </div>

                            {!completed &&
                                <Typography>
                                    Você está aprovando os projetos personalizados que foram enviados pelo seu arquiteto para a construtora.
                                </Typography>
                            }

                            {completed ?
                                <div>
                                    <Typography variant='h6'> Projetos personalizados aprovados </Typography>
                                </div>
                                :
                                <>
                                    {step === 1 ?
                                        <>
                                            <Box marginTop="10px">
                                                <Typography variant='h6'> Projetos anexados pela construtora </Typography>
                                                {Boolean(detailsProject?.documents?.length) && !loading ?

                                                    detailsProject.documents.map((project, i) => (
                                                        <DivAcordion>
                                                            <AccordionStyled
                                                                TransitionProps={{ unmountOnExit: true }}
                                                                onChange={project.version.attachments.length > 0 && handleChange(i)}
                                                                expanded={expanded[i] || false}
                                                                square
                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={
                                                                        <ExpandMore style={{ color: project.version.attachments.length < 1 && "rgba(0, 107, 234, 4%)" }} />
                                                                    }
                                                                    style={{
                                                                        cursor: project.version.attachments.length > 0 ? "pointer" : "auto",
                                                                        backgroundColor: "rgba(0, 107, 234, 0.1)",
                                                                        boxShadow: "0px 4px 4px 0px #00000040",
                                                                        minHeight: "unset",
                                                                        height: "48px",
                                                                        display: "flex",
                                                                        padding: "0px 16px",
                                                                    }}
                                                                >

                                                                    <div style={{ display: "flex", width: "100%", alignItems: "center", }}>
                                                                        <Typography>Projeto:&nbsp; {project.version.request.type} </Typography>
                                                                        <Typography
                                                                            variant='caption'
                                                                            style={{ marginLeft: "auto" }}
                                                                        >
                                                                            Versão&nbsp;{project.version.version}
                                                                        </Typography>
                                                                    </div>
                                                                </AccordionSummary>

                                                                <AccordionDetails style={{ backgroundColor: "#FAFAFA" }} >
                                                                    <>
                                                                        {Boolean(project.version.attachments.length) ? (
                                                                            <StyledGrid
                                                                                rowHeight={70}
                                                                                cols={1}
                                                                            >
                                                                                {project.version.attachments.filter(i => i.attachment_url).map(item => (
                                                                                    <ClickableAttachment
                                                                                        attachment={{ attachment_url: item.attachment_url, name: item.name }}
                                                                                    />
                                                                                ))}
                                                                            </StyledGrid>
                                                                        ) : (
                                                                            <Typography>Nenhum projeto adicionado</Typography>
                                                                        )}
                                                                    </>
                                                                </AccordionDetails>
                                                            </AccordionStyled>
                                                        </DivAcordion>
                                                    )) : <Typography> Nenhum projeto enviado</Typography>
                                                }
                                            </Box>

                                            {Boolean(detailsProject?.attachments?.length) && !loading &&
                                                <DivAcordion>
                                                    <AccordionStyled TransitionProps={{ unmountOnExit: true }} square>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMore />}
                                                            style={{
                                                                cursor: "pointer",
                                                                backgroundColor: "rgba(0, 107, 234, 0.1)",
                                                                boxShadow: "0px 4px 4px 0px #00000040",
                                                                minHeight: "unset",
                                                                height: "48px",
                                                                display: "flex",
                                                                padding: "0px 16px",
                                                            }}
                                                        >
                                                            <Typography>Anexos</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ backgroundColor: "#FAFAFA" }} >
                                                            <StyledGrid rowHeight={70} cols={1}>
                                                                {detailsProject.attachments.map(item => (
                                                                    <ClickableAttachment
                                                                        attachment={{ attachment_url: item.attachment_url, name: item.name }}
                                                                    />
                                                                ))}

                                                            </StyledGrid>
                                                        </AccordionDetails>
                                                    </AccordionStyled>
                                                </DivAcordion>
                                            }

                                            <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: "10px" }}>
                                                <Checkbox
                                                    name="aproveProjectAttached"
                                                    onChange={(ev) => onChangeSelect(ev)}
                                                    label="Estou de acordo com os projetos anexados"
                                                    value={responseCustomer.aproveProjectAttached}
                                                    checked={responseCustomer.aproveProjectAttached}
                                                />
                                            </div>

                                            <DividerStyle />

                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <Typography variant='h6'> Orçamento - {detailsProject?.title}</Typography>
                                                </div>

                                                {!loading &&
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <Typography>
                                                            Valor total
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                color: "#006D00",
                                                                marginLeft: "10px",
                                                                fontWeight: "450",
                                                                lineHeight: "1.5",
                                                                fontSize: "19px",
                                                            }}
                                                        >
                                                            {Boolean(detailsProject?.price) ? formatMoneyDivide(detailsProject.price) : formatMoneyDivide(0)}
                                                        </Typography>
                                                    </div>
                                                }
                                            </div>

                                            <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: "10px" }}>
                                                <Checkbox
                                                    onChange={(ev) => onChangeSelect(ev)}
                                                    name="aproveBudget"
                                                    value={responseCustomer.aproveBudget}
                                                    checked={responseCustomer.aproveBudget}
                                                    label="Estou de acordo com o orçamento demonstrado"
                                                />
                                            </div>

                                            <DividerStyle />

                                            <DivButton>
                                                <FBButton
                                                    disabled={!responseCustomer.aproveBudget || !responseCustomer.aproveProjectAttached}
                                                    color='primary'
                                                    onClick={() => setStep(2)}
                                                >
                                                    Próximo
                                                </FBButton>
                                            </DivButton>

                                        </>
                                        :
                                        <>

                                            <Typography > Insira o seu cpf </Typography>
                                            <Input
                                                style={{ maxWidth: "350px" }}
                                                onChange={ev => setResponseCustomer({ ...responseCustomer, document: ev.target.value })}
                                                value={responseCustomer.document}
                                                maxLength={50}
                                                autoFocus placeholder='xxx.xxx.xxx-xx'
                                            />


                                            <Typography style={{ marginTop: "20px" }}> Tire uma selfie com o seu documento </Typography>
                                            <Tooltip title="Clique para selecionar escolher uma foto">
                                                <ImageContainer style={{ maxWidth: "350px" }}>
                                                    <InputImage
                                                        id='selfie'
                                                        handleImageChange={handleImageChange}
                                                        onDrop={handleImageChange}
                                                        accept="image/.jpg,image/.jpeg,image/.jpe,image/.svg,image/.tiff,image/.png,image/jpg,image/jpeg,image/svg,image/tiff,image/png"
                                                        onDragOver={event => event.preventDefault()}
                                                        image={responseCustomer.selfie_key?.resultUrl}
                                                        cover_photo={responseCustomer?.selfie_key?.loading}
                                                    >
                                                        {responseCustomer.selfie_key?.loading === false && <img src={responseCustomer.selfie_key?.resultUrl} alt='selfie' />}
                                                        {responseCustomer.selfie_key?.loading === true && <Loading />}
                                                    </InputImage>
                                                </ImageContainer>
                                            </Tooltip>

                                            <Signature ref={drawingRef} text='Faça sua assinatura' />
                                            <Box display="flex">
                                                <FBButton style={{ width: 100 }} primary className='mr-1' variant="outlined"
                                                    onClick={() => drawingRef.current.undo()}
                                                >
                                                    Desfazer
                                                </FBButton>
                                                <FBButton style={{ width: 100, marginLeft: 10, marginBottom: 20 }} primary className='mr-1' variant="outlined"
                                                    onClick={() => drawingRef.current.clear()}
                                                >
                                                    Limpar
                                                </FBButton>
                                            </Box>

                                            <DividerStyle />

                                            <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: "10px" }}>
                                                <Checkbox
                                                    onChange={(ev) => onChangeSelect(ev)}
                                                    name="aproveprojectFinished"
                                                    value={responseCustomer.aproveprojectFinished}
                                                    checked={responseCustomer.aproveprojectFinished}
                                                    label="Confirmo que estou de acordo com os projetos de personalizações aprovados e que os mesmos podem ser executados pela construtora."
                                                />
                                            </div>

                                            <DivButton style={{ marginTop: "20px" }}>
                                                <FBButton
                                                    disabled={!responseCustomer?.aproveprojectFinished || drawingRef?.current?.lines?.length === 0 || !responseCustomer?.selfie_key}
                                                    color='primary'
                                                    onClick={onSubmit}
                                                >
                                                    Aprovar
                                                </FBButton>
                                            </DivButton>
                                        </>
                                    }
                                </>
                            }

                        </Box>
                    }

                </Container>
            </Container>
        </div >
    );
}

