import React, { useCallback, useRef, useState } from "react";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import moment from "moment";
import Input from "~/components/Input/Input";
import { ScrollBarStyle } from "../Custom";
import styled from "styled-components";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const ListStyle = styled(List)`
    display: block;
    overflow: auto;
${ScrollBarStyle}
`
export default function ListGuarantees({ guarantees, expiredGuarantees }) {

    const [search, setSearch] = useState()
    const [inputSearch, setInputSearch] = useState()
    const searchRef = useRef(null)

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const update = useCallback((ev) => {
        const value = ev.target.value
        clearTimeout(searchRef.current)
        setSearch(value)
        searchRef.current = setTimeout(() => {
            setInputSearch(value)
        }, 300)
    }, [])

    const visible = inputSearch ? guarantees.filter(g => g.name.toLowerCase().includes(inputSearch.toLowerCase())) : guarantees

    return (
        <>

            {guarantees.length ?

                <>

                    <form onSubmit={ev => { ev.preventDefault(); }}>
                        <div>
                            <Input
                                maxLength={50}
                                onChange={update}
                                value={search}
                                autoFocus
                                placeholder="Pesquise sua garantia pelo nome"
                            />
                        </div>
                        <button value="Pesquisar" type="submit" style={{ display: "none" }}> </button>
                    </form>

                    <ListStyle
                        style={{
                            height: !expiredGuarantees && `calc(100vh - ${isMobile ? "175px" : "165px"})`,
                            maxHeight: expiredGuarantees && 310,
                            marginTop: "5px"
                        }}
                    >
                        {visible.map((g, i) => (
                            <ListItem style={{ padding: 0 }} key={g.name + String(i)}>
                                <ListItemText
                                    secondaryTypographyProps={{ style: { color: !g.isExpired && "#f44336" } }}
                                    primary={`${g.name}`}
                                    secondary={
                                        expiredGuarantees ?
                                            `Venceu em ${moment(g.expiration).format("DD/MM/YYYY")}.` :
                                            `Data de Vencimento: ${moment(g.expiration).format("DD/MM/YYYY")}.`
                                    }
                                />
                            </ListItem>
                        ))}
                    </ListStyle>

                </>
                :
                <>
                    {expiredGuarantees ?
                        <Typography> Nenhuma garantia vencida </Typography>
                        :
                        <Typography> Nenhuma garantia cadastrada </Typography>
                    }
                </>
            }
        </>


    )
}
