import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
} from '@material-ui/core';
import { ExpandMore } from "@material-ui/icons";
import RenderFilesImgVideo from "~/utils/RenderFilesImgVideo";
import BackIndex from "~/components/Navbar/BackIndex";
import api from "~/services/api";
import { CenterSpinnerButton } from "~/components/Spinner";
import { toast } from "react-toastify";

const ContainerBack = styled(Container)`
    background-color: #FFFFFF;
    min-height: 100vh;
    padding: 0px;
`

const AccordionStyled = styled(Accordion)`
    background-Color: rgba(0, 107, 234, 0.1);
    box-Shadow: 0px 4px 4px 0px #00000040;
    border-Radius: 6px;
    position: relative;
`

const DivAcordion = styled.div`
    margin-Top: 20px;
    position: relative;
    border-Radius: 6px;
    overflow: hidden;
    box-Shadow: 0px 4px 4px 0px #00000040;
`

const LabelText = styled(Typography).attrs({
    variant: "body1",
    color: "primary",
})`
    font-weight: bold !important;
    word-break: break-word;
`


const DivSumary = styled.div`
    margin: 0px 30px 0px 30px;
    position: relative;
    @media (max-width: 999px) {
        margin: 0px 5px 0px 5px;
  }                         
`

const LabelMonths = (data) => {
    switch (data) {
    case "1": return "Janeiro";
    case "2": return "Fevereiro";
    case "3": return "Março";
    case "4": return "Abril";
    case "5": return "Maio";
    case "6": return "Junho";
    case "7": return "Julho";
    case "8": return "Agosto";
    case "9": return "Setembro";
    case "10": return "Outubro";
    case "11": return "Novembro";
    case "12": return "Dezembro";
    default: return "Indeterminado";
    }
};

export default function MonitorListDates() {
    const { token, guid } = useParams();
    const params = (new URLSearchParams(useLocation().search).get("filter"));
    const [workMonitorBuilding, setworkMonitorBuilding] = useState({ timeline: [] })
    const [expanded, setExpanded] = React.useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        async function getMonitors() {
            setLoading(true);
            try {
                const data = await api.get(`/v1/monitor/${token}/timeline?onlyVideo=${Boolean(params)}`);
                setworkMonitorBuilding(data.data)
                setLoading(false)
            } catch (e) {
                toast.error("Não foi possível localizar os detalhes do acompanhamento da obra");
                setLoading(false);
            }
        }

        getMonitors();

    }, [guid, params, token])

    const handleChange = (panel) => () => {
        setExpanded((expanded) => ({ ...expanded, [panel]: !expanded[panel] }));
    };

    return (
        <ContainerBack fluid={true}>

            {loading &&
                <div
                    style={{
                        marginTop: "35px",
                        textAlign: "center"
                    }}
                >
                    <LabelText>  <CenterSpinnerButton color={"#000"} /> </LabelText>
                </div>
            }

            <DivSumary>
                <BackIndex link={`/${token}/workMonitor/`} workMonitorBuilding={workMonitorBuilding} />

                {workMonitorBuilding.timeline?.map((year, i) => (
                    <div key={i}>
                        <div
                            style={{
                                marginTop: "30px",
                                textAlign: "center"
                            }}
                        >
                            <LabelText>  {year.key} </LabelText>
                        </div>

                        <div style={{ marginTop: "-15px" }}>
                            {year.value?.map(([label, attachments], i) => (
                                <DivAcordion key={i}>

                                    <AccordionStyled
                                        TransitionProps={{ unmountOnExit: true }}
                                        onChange={handleChange(`${year.key}-${label}`)}
                                        expanded={expanded[`${year.key}-${label}`] || false}
                                    >
                                        <AccordionSummary expandIcon={<ExpandMore />}
                                            style={{ backgroundColor: "rgba(var(--theme-color-rgb,  0, 107, 234), 0.2)",
                                                boxShadow: "0px 4px 4px 0px #00000040",
                                                minHeight: "unset",
                                                height: "48px",
                                            }}
                                        >
                                            <LabelText>
                                                {LabelMonths(label)}
                                            </LabelText>
                                        </AccordionSummary>

                                        <AccordionDetails
                                            style={{ backgroundColor: "rgba(var(--theme-color-rgb,  0, 107, 234), 0.4)", }}
                                        >
                                            <Grid container>
                                                <RenderFilesImgVideo attachments={attachments} />
                                            </Grid>
                                        </AccordionDetails>
                                    </AccordionStyled>

                                </DivAcordion>
                            ))}
                        </div>
                    </div>

                ))}

            </DivSumary>


        </ContainerBack >
    );
}
