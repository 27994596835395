import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import 'moment/locale/pt-br'
import api from '~/services/api';
import { Box, Typography, AccordionSummary, Accordion, AccordionDetails, ImageList } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Loading from '~/components/loading';
import { ExpandMore } from '@material-ui/icons';
import { ClickableAttachment } from '~/components/Sac/Attachment';
import { DividerStyle } from '.';

const DivAcordion = styled.div`
    margin-Top: 10px;
    margin-bottom: 10px;
    position: relative;
    border-Radius: 6px;
    overflow: hidden;
    box-Shadow: 0px 4px 4px 0px #00000040;
`

const AccordionStyled = styled(Accordion)`
    box-Shadow: 0px 4px 4px 0px #00000040;
    border-Radius: 6px;
    position: relative;
`

const StyledGrid = styled(ImageList)`
    img, video {
        max-width: 100%;
        max-height: 50px !important;
    }
    > div {
        border-radius: 6px;
        min-width: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer-card {
        border-radius: 6px;
        display: flex;
        width: 80%;
        text-align: center;
        justify-content: center;
    }
    .footer-card a {
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`

export default function ProjectArchitect() {

    const { token } = useParams()
    const [loading, setLoading] = useState(false)
    const [listProjectSendArchitect, setListProjectSendArchitect] = useState([])
    const [expanded, setExpanded] = useState({});

    const handleChange = (panel) => () => {
        setExpanded((expanded) => ({ ...expanded, [panel]: !expanded[panel] }));
    };

    useEffect(() => {
        async function loadData() {
            try {
                setLoading(true)
    
                const response = await api.get(`v1/${token}/customization `);
    
                if (response.status !== 200) {
                    toast.error(response.data.message)
                } else {
                    setListProjectSendArchitect(response.data.versions)
                }
    
            } catch (err) {
                toast.error(err.response?.data?.message || 'Não foi possível localizar nenhum projeto');
            }
    
            setLoading(false)
        }

        loadData();
    }, [token]);

    return (
        <div>
            <Container className='container-fluid-folder' fluid={true}>
                <Container className='container-folder-page'>
                    <br />

                    <div style={{ display: 'flex' }}>
                        <Link to={`/${token}/customizacoes`}>
                            <ArrowBack fontSize='large' />
                        </Link>
                        <h2 style={{ marginLeft: 30 }}> Projetos enviados </h2>
                        {loading && <div style={{ marginTop: "5px" }}><Loading /></div>}
                    </div>

                    <Box
                        display="flex"
                        flexDirection="column"
                    >

                        <DividerStyle />

                        {Boolean(listProjectSendArchitect.length) && !loading &&

                            listProjectSendArchitect.map((project, i) => (
                                <DivAcordion>
                                    <AccordionStyled
                                        TransitionProps={{ unmountOnExit: true }}
                                        onChange={project.attachments.length > 0 && handleChange(i)}
                                        expanded={expanded[i] || false}
                                        square
                                    >
                                        <AccordionSummary
                                            expandIcon={
                                                <ExpandMore style={{ color: project.attachments.length < 1 && "rgba(0, 107, 234, 4%)" }} />
                                            }

                                            style={{
                                                cursor: project.attachments.length > 0 ? "pointer" : "auto",
                                                backgroundColor: "rgba(0, 107, 234, 0.1)",
                                                boxShadow: "0px 4px 4px 0px #00000040",
                                                minHeight: "unset",
                                                height: "48px",
                                                display: "flex",
                                                padding: "0px 16px",
                                                outline: "0",
                                                position: "relative",
                                                alignItems: "center",
                                                userSelect: "none",
                                                borderRadius: "0",
                                                verticalAlign: "middle",
                                                color: "inherit",
                                                border: "0",
                                                margin: "0",
                                                justifyContent: "center",
                                                textDecoration: "none",
                                            }}
                                        >

                                            <div style={{ display: "flex", width: "100%", alignItems: "center", }}>
                                                <Typography>Projeto:&nbsp;{project.request.type}</Typography>
                                                <Typography
                                                    variant='caption'
                                                    style={{ marginLeft: "auto" }}
                                                >
                                                    Versão&nbsp;{project.version}
                                                </Typography>
                                            </div>
                                        </AccordionSummary>

                                        <AccordionDetails style={{ backgroundColor: "#FAFAFA" }} >
                                            <>
                                                {Boolean(project.attachments.length) ? (
                                                    <StyledGrid
                                                        rowHeight={70}
                                                        cols={1}
                                                    >
                                                        {project.attachments.filter(i => i.attachment_url).map(item => (
                                                            <ClickableAttachment
                                                                attachment={{ attachment_url: item.attachment_url, name: item.name }}
                                                            />
                                                        ))}
                                                    </StyledGrid>
                                                ) : (
                                                    <Typography>Nenhum projeto adicionado</Typography>
                                                )}
                                            </>
                                        </AccordionDetails>
                                    </AccordionStyled>

                                </DivAcordion>
                            ))
                        }
                    </Box>

                </Container>
            </Container>
        </div >
    );
}

