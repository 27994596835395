import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Container } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { CenterSpinnerButton } from '~/components/Spinner';
import Modal from '~/components/Modal';
import 'moment/locale/pt-br'
import api from '~/services/api';
import { Box, Typography } from '@material-ui/core';
import Button from '~/components/Button/Button';
import FBButton from '~/components/buttons/FBButton';
import ArrowBack from '@material-ui/icons/ArrowBack';

export default function SelectScheduleProvider() {
    const { guid } = useParams();

    const [data, setData] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [map, setMap] = useState(new Map());
    const [selectedDate, setSelectedDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory()
    const selectedDay = new URLSearchParams(history.location.search).get("day")
    const { token } = useParams()

    useEffect(() => {
        async function getSlots() {
            try {
                if (!guid) {
                    return;
                }
                const response = await api.get(`v1/sac/slots/${guid}`);

                if (response.status !== 200) {
                    toast.error(response.data.message)
                } else if (response.data.slots) {
                    const byDay = response.data.slots.reduce((acc, next) => {
                        const day = moment(next.start);
                        const dayKey = day.format('YYYY/MM/DD');
                        const element = acc.get(dayKey) || { day, slots: [] };
                        element.slots.push(next);
                        acc.set(dayKey, element);
                        return acc;
                    }, new Map());
                    setMap(byDay);
                    setData(Array.from(byDay.values()));
                    setLoading(false);
                }

            } catch (err) {
                setLoading(false);
                toast.error(err.response?.data?.message || 'Não foi possível localizar a disponibilidade');
            }
        }

        getSlots();
    }, [guid]);

    const timeSlots = selectedDay ? map.get(selectedDay) : {}

    function selectDate(guid) {
        setSelectedDate(data.flatMap(m => m.slots).find(s => s.guid === guid))
    }

    const confirmingDateRef = useRef(false)

    async function confirmDate() {
        try {
            if (!confirmingDateRef.current) {
                confirmingDateRef.current = true
                const response = await api.post(`v1/sac/slots/${guid}`, { slot_guid: selectedDate.guid });
                if (response.status === 200) {
                    toast.success(`Visita agendada com sucesso`)
                    setPhoneNumber(response.data.phoneNumber || '')
                } else {
                    toast.error("Houve um falha ao selecionar a data")
                }
                confirmingDateRef.current = false
            }
        } catch (err) {
            confirmingDateRef.current = false
            toast.error(err.response.data?.message || "Houve um falha ao selecionar a data")
        }
    }

    if (phoneNumber !== null) {
        return (
            <Container className='container-fluid-folder' fluid={true}>
                <Container className='container-folder-page'>
                    <Link to={`/${token}`}>
                        <div style={{ display: 'flex' }}>
                            <ArrowBack cursor="pointer" fontSize="large" /> <h2 style={{ marginLeft: 15, marginBottom: 15 }}> Visita agendada </h2>
                        </div>
                    </Link>
                    {phoneNumber ? (
                        <p> Você receberá uma confirmação no número <b>{phoneNumber}</b> e no seu e-mail. </p>
                    ) : (
                        <p>Sua visita foi agendada com sucesso.</p>
                    )}
                </Container>
            </Container>
        )
    }
    return (
        <div>
            <Modal show={Boolean(selectedDate)} title="Confirmar agendamento" handleClose={() => setSelectedDate(null)} className="schedule-modal">
                {selectedDate && (
                    <>
                        <Box sx={{ p: 1 }}>
                            <p>
                                Agendar visita para o dia {moment(selectedDate.start).format("DD/MM/YYYY")}, das {moment(selectedDate.start).format("HH:mm")} até as {moment(selectedDate.end).format("HH:mm")}.
                            </p>
                            <br />
                            <Box style={{ width: '100%' }} display="inline-flex" justifyContent="flex-end">
                                <FBButton style={{ width: 200, marginRight: 10 }} variant="outlined" color="primary" onClick={() => setSelectedDate(null)}> Cancelar </FBButton>
                                <FBButton style={{ width: 200 }} onClick={confirmDate} primary> Confirmar </FBButton>
                            </Box>
                        </Box>
                    </>
                )}
            </Modal>
            <Container className='container-fluid-folder' fluid={true}>
                <Container className='container-folder-page'>
                    <br />

                    <Link to={(timeSlots && timeSlots.slots) ? `/${token}/sac/schedule/${guid}` : `/${token}`}>
                        <div style={{ display: 'flex' }}>
                            <ArrowBack cursor="pointer" fontSize="large" /> <h2 style={{ marginLeft: 15, marginBottom: 15 }}> Agendar visita </h2>
                        </div>
                    </Link>

                    <p> Selecione a data e hora para a visita do profissional </p>

                    <Box display="inline-flex" flexDirection="column" style={{ width: '100%' }}>
                        {loading ? (
                            <CenterSpinnerButton color={'#000'} />
                        ) : (timeSlots && timeSlots.slots) ?
                            timeSlots.slots.map(slot => (
                                <Button
                                    class="day-inspection-button"
                                    key={slot.guid}
                                    onClick={() => selectDate(slot.guid)}
                                >
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <Typography variant="h6">

                                        <b> {momentTimeZone(slot.start).tz("America/Sao_Paulo").format('HH:mm')} </b> até
                                        <b> {momentTimeZone(slot.end).tz("America/Sao_Paulo").format('HH:mm')}  </b> 

                                        </Typography>
                                        &nbsp;
                                        <Typography style={{color: "#6a6a6a"}}variant='caption'> (BRT) </Typography>
                                    </Box>
                                </Button>
                            ))
                            : data.map(slot => (
                                <Link
                                    className="day-inspection-button"
                                    key={slot.day.format('YYYY/MM/DD')}
                                    to={`?day=${slot.day.format('YYYY/MM/DD')}`}
                                >
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <Typography variant="h5"> <b> {slot.day.locale("pt-br").format('dddd, DD/MM/YYYY')} </b> </Typography>
                                    </Box>
                                </Link>
                            ))}
                    </Box>
                </Container>
            </Container>
        </div>
    );
}
