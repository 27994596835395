import { useTheme } from "@material-ui/core";
import {createContext, useContext, useMemo} from "react";

export const CustomThemeContext = createContext({
    setTheme: () => null,
    reset: () => null,
})


export const useThemeModifier = () => {
    return useContext(CustomThemeContext)
}



export const usePrimaryColor = () => {
    const theme = useTheme()
    return useMemo(() => {
        console.log(theme?.palette?.primary?.main)
        return theme?.palette?.primary?.main
    }, [theme?.palette?.primary?.main])

}
