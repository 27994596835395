import produce from "immer";

const INITIAL_STATE = {
    detail: null,
    all: [],
};

export default function sac(state = INITIAL_STATE, action) {
    return produce(state, (draft) => {
        switch (action.type) {
        case "@unit/save": {
            draft.detail = action.payload.data;
            break;
        }
        default:
        }
    });
}
