import React, { useCallback, useRef, useState } from "react";
import { Chip, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import moment from "moment";
import Input from "~/components/Input/Input";
import { DividerDiv, DividerStyle, ScrollBarStyle } from "../Custom";
import styled from "styled-components";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {KeyboardArrowRight} from "@material-ui/icons";
import {Link, useParams} from "react-router-dom";

const ListStyle = styled(List)`
    display: block;
    overflow: auto;
${ScrollBarStyle}
`


export const labelRecurrenceTaks = (recurrence) => {
    switch (recurrence) {
    case 1: return "Diário";
    case 7: return "Semanal";
    case 15: return "Quinzenal";
    case 30: return "Mensal";
    case 60: return "Bimestral";
    case 90: return "Trimestral";
    case 120: return "Quadrimestral";
    case 180: return "Semestral";
    case 365: return "Anual";
    case 730: return "Bienal";
    case 1095: return "Trienal";
    case 1825: return "A cada 5 anos";
    default: return "Indeterminado";
    }
};


export const RecurrenceFlag = ({ recurrence, last }) => {
    const label = labelRecurrenceTaks(recurrence)
    const className = last ? "success" : "error"

    const dateLabel = last ? moment(last).format("DD/MM/YYYY") :""

    return (
        <>
            <Chip className={`chip-${className}`} size="small" label={label} />
            {Boolean(dateLabel) && (<>
                &nbsp;
                <Chip size="small" label={`Última: ${dateLabel}`} />
            </>)}
        </>
    )

}

export default function MaintenancesList({ list }) {
    const { token } = useParams()

    const [search, setSearch] = useState()
    const [inputSearch, setInputSearch] = useState()
    const searchRef = useRef(null)

    const update = useCallback((ev) => {
        const value = ev.target.value
        clearTimeout(searchRef.current)
        setSearch(value)
        searchRef.current = setTimeout(() => {
            setInputSearch(value)
        }, 300)
    }, [])

    const visible = inputSearch ? list.filter(g => g.name.toLowerCase().includes(inputSearch.toLowerCase())) : list

    return (
        <>

            {list.length ?
                <>

                    <form onSubmit={ev => { ev.preventDefault(); }}>
                        <div style={{ padding: "5px" }}>
                            <Input
                                maxLength={50}
                                onChange={update}
                                value={search}
                                autoFocus
                                placeholder="Pesquise pelo nome"
                            />
                        </div>
                        <button value="Pesquisar" type="submit" style={{ display: "none" }}> </button>
                    </form>
                    <ListStyle style={{ marginTop: "5px" }}>
                        {visible.map((g, i) => (
                            <React.Fragment key={g.id}>
                                {Boolean(i) && <DividerStyle />}
                                <Link to={`/${token}/manutencoes/${g.id}`}>
                                    <ListItem>
                                        <ListItemText
                                            primary={g.name}
                                            secondary={<RecurrenceFlag recurrence={g.recurrence} last={g.last}/>}
                                        />
                                        <ListItemSecondaryAction>
                                            <KeyboardArrowRight />
                                        </ListItemSecondaryAction>

                                    </ListItem>
                                </Link>
                            </React.Fragment>
                        ))}
                    </ListStyle>

                </>
                :
                <>
                    <Typography> Nenhuma manutenção cadastrada </Typography>
                </>
            }
        </>


    )
}
