import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    drawerWidth: 260,
    mobileGutterSpacing: 2,
    overrides: {
        MuiTimelineItem: {
            missingOppositeContent: {
                "&:before": {
                    display: "none"
                }
            }
        }
    },
    typography: {
        button: {
            textTransform: "none"
        },
        h5: {
            fontSize: "1.75rem",
            fontWeight: 500
        },
        filtersTitleFontSize: '1.50rem',
        formLabelFontSize: '1.375rem',
        letterIconFontSize: '1.125rem',
        chipFontSize: '0.625rem',
        fontWeight: 300,
        fontFamily: [
            'Poppins',
            'Ubuntu',
            'Roboto'
        ].join(','),
        secondaryFontFamily:
            'Ubuntu'
    },
    palette: {
        primary: {
            main: '#3279CD',
            dark: '#23548f',
            contrastText: '#fafafa'
        },
        secondary: {
            main: '#56C492',
            contrastText: '#fff'
        }
    },
    background: {
        default: "#f1f1f2",
        fastbuiltDefault: "#fafafa",
    },
    fastbuiltShadow: "0px 0px 4px rgba(8, 22, 48, 0.1)"
});

export default theme
