import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from 'react-bootstrap';
import { Typography } from '@material-ui/core';
import { CenterSpinnerButton } from '~/components/Spinner';
import Input from '~/components/Input/Input';
import api from '~/services/api';
import FBButton from '~/components/buttons/FBButton';
import { useForm } from 'react-hook-form';
import { handleChangeFormatDocument, validateDocument } from '~/utils/formatters';
import history from '~/services/history';
import BackIndex from '~/components/Navbar/BackIndex';
import { Attachment, WatchLater } from "@material-ui/icons";
import moment from 'moment';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector } from '@mui/lab';
import { ButtonLink } from '~/utils/ButtonLink';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';

function AttachmentsInpections() {

    const { token } = useParams();
    const [attachmentsInpections, setAttachmentsInpections] = useState([]);
    const [loading, setLoading] = useState(false);

    const methods = useForm({ defaultValues: { client_document: undefined } })
    const { register, formState: { errors }, handleSubmit, setValue } = methods

    useEffect(() => {
        setValue("client_document", undefined)
        setAttachmentsInpections([])

        async function getUnitDetails() {
            setLoading(true);
            try {
                await api.get(`v1/units/${token}`);
            } catch (e) {
                console.error(e);
                toast.error("Não foi possível localizar a unidade.");
                history.push("/");
            }
            setLoading(false);
        }

        getUnitDetails();
    }, [token]);

    const onSubmit = async (ev) => {
        setLoading(true);
        try {

            const response = await api.get(`v1/inspection/attachments/${token}?client_document=${ev.client_document}`);

            if (response.status === 200) {
                if (response.data.attachments.length === 0) {
                    toast.warn("Não foi encontrado nenhum relatório para CPF/CNPJ digitado")
                }
                setAttachmentsInpections(response.data.attachments);
            } else {
                toast.error(response?.data?.message || 'Não foi possível localizar os relatórios');
            }

        } catch (error) {
            toast.error(error.response?.data?.message || 'Não foi possível localizar os relatórios');
        }

        setValue("client_document", undefined)
        setLoading(false);
    }

    return (
        <div>
            <Container
                className='container-fluid-folder'
                fluid={true}
                style={{ backgroundColor: !loading && attachmentsInpections.length === 0 && "rgb(0 0 0 / 55%)" }}
            >

                <div style={{ position: "fixed", top: "30vh", width: "100%" }}>
                    {loading && (<CenterSpinnerButton color={'#000'} />)}
                </div>

                {!loading && attachmentsInpections.length === 0 &&
                    <div style={{ position: "fixed", top: "40vh", width: "100%", padding: "5px", display: "flex", justifyContent: "center" }}>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            style={{
                                borderRadius: "6px",
                                padding: "5px",
                                backgroundColor: "#FFFFFF",
                                maxWidth: "400px"
                            }}
                        >
                            <Typography variant='caption'> Insira o número do seu CPF/CNPJ para ter acesso aos documentos </Typography>

                            <Input
                                maxLength={50}
                                placeholder="Insira seu CPF/CNPJ"
                                cursor={"client_document"}
                                name="client_document"
                                {...register("client_document", { required: true, validate: { cpf: validateDocument } })}
                                onChange={handleChangeFormatDocument}
                                errors={errors}
                            />

                            <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                                <FBButton
                                    style={{ width: "100px", marginRight: "10px" }}
                                    variant="outlined"
                                    onClick={() => history.push(`/${token}`)}
                                >
                                    Voltar
                                </FBButton>

                                <FBButton
                                    style={{ width: "100px" }}
                                    color='primary'
                                    type="submit"
                                >
                                    Validar
                                </FBButton>
                            </div>
                        </form>
                    </div>
                }

                <Container className='container-folder-page'>

                    <div style={{ marginTop: "5px" }}>
                        {attachmentsInpections.length !== 0 && <BackIndex link={`/${token}/`} />}
                        <br />
                        <h1 style={{ textAlign: "center" }}> Relatórios de vistoria </h1>
                        <br />

                        {!loading && attachmentsInpections.length !== 0 && (

                            <Timeline
                                sx={{
                                    [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.2, },
                                }}
                            >
                                {attachmentsInpections.map((att, i, timeline) => (
                                    <TimelineItem key={i}>

                                        <TimelineSeparator>
                                            {Boolean(i) && <TimelineConnector />}
                                            <TimelineDot>
                                                {(att.generated_at || att.html_key) ? <Attachment /> : <WatchLater />}
                                            </TimelineDot>
                                            {i !== timeline.length - 1 ? <TimelineConnector /> : <><br /><br /></>}
                                        </TimelineSeparator>

                                        <TimelineOppositeContent
                                            sx={{ py: '12px', px: 2 }}
                                            style={{
                                                minWidth: "250px",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start"
                                            }}
                                        >

                                            <Typography variant="h6" component="span">
                                                <a target="_blank" href={att.attachment_url} rel="noopener noreferrer">
                                                    <ButtonLink>
                                                        <Typography variant="h6"> {att.name} </Typography>
                                                    </ButtonLink>
                                                </a>
                                            </Typography>

                                            {att.approval.signed_date ?
                                                (<>
                                                    <Typography variant="caption">
                                                        Assinado pelo cliente em {moment(att.approval?.signed_date).format("DD/MM/YYYY")}
                                                    </Typography>
                                                </>
                                                ) : (
                                                    <>
                                                        <Typography variant="caption">Aguardando assinatura</Typography>
                                                        <FBButton
                                                            variant="outlined"
                                                            primary
                                                            size="small"
                                                            onClick={() => history.push(`/${token}/inspection/sign/${att.approval.guid}`)}
                                                            style={{ backgroundColor: "white" }}
                                                        >
                                                            Assinar
                                                        </FBButton>
                                                    </>
                                                )
                                            }

                                        </TimelineOppositeContent>
                                    </TimelineItem>
                                ))}
                            </Timeline>

                        )}
                    </div>


                </Container>
            </Container>
        </div>
    );
}

export default AttachmentsInpections;
