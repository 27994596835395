import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Container } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import 'moment/locale/pt-br'
import api from '~/services/api';
import { Box, Divider, Typography, ImageList } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import styled, { css } from 'styled-components';
import Loading from '~/components/loading';
import { KeyboardArrowRight } from '@material-ui/icons';
import { ChatMessageFooter } from '~/utils/Chat';
import moment from 'moment';
import { ClickableAttachment } from '~/components/Sac/Attachment';
import { ButtonLink } from '~/utils/ButtonLink';
import FBButton from '~/components/Button/FBButton';
import Modal from '~/components/Modal';

export const ScrollBarStyle = css`
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 6px;
    }
    ::-webkit-scrollbar {
        width: 7px;
        background-color: transparent;
        height: 7px;
        border-radius: 6px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        width: 7px;
        border-radius: 6px;
    }
    ::-webkit-scrollbar-thumb {
        background: #888;
        width: 5px;
        border-radius: 6px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
        border-radius: 6px;
    }
`

export const StyledGrid = styled(ImageList)`
    height: 110px;

    @media (max-width: 999px) {
         max-width: 90%;
    }

    ${ScrollBarStyle}

    img, video {
        max-width: 100%;
        max-height: 50px !important;
    }
    > div {
        border-radius: 6px;
        min-width: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer-card {
        border-radius: 6px;
        display: flex;
        width: 80%;
        text-align: center;
        justify-content: center;
    }
    .footer-card a {
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`

const DivButton = styled.div`
    margin: 10px 0px 0px 0px;
    max-width: 250px;
    @media (max-width: 999px) {
         max-width: 100%;
    }
`

const DivSumaryLink = styled.div`
    position: relative;
    display: flex;
`

const DivChat = styled.div`
    margin-top: 10px;
    overflow-y: auto;
    flex: 1 1 0%;
    background-color: white;
    border-radius: 7px;
    padding: 15px;
    width: 100%;
    border: 1px solid rgba(0,0,0,15%);

    ${ScrollBarStyle}
`

const DivMessage = styled.div`

    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    box-shadow: 0px 0px 4px rgba(8,22,48,0.1);
    border-radius: 10px 10px 10px 10px;
    padding: 5px;
    margin-right: 10px;
    margin-left: 20%;
    word-break: break-word;
    align-items: flex-end;
    max-width: 80%;
    background-color: var(--theme-color-opaque, #EAF1FB);
    color: rgba(255,255,255,100%);
    white-space: break-spaces;

    ${({ chat }) => chat && chat.system ?
    css`
        background-color: var(--theme-color-transparent, #7ea6df);
        text-align: center;
        max-width: 60%;
        color: rgba(49,119,204,100%);
        align-items: center;
        border: 1px solid rgba(235,235,235,70%);
    ` : chat.created_by &&
        css`
        background-color: rgba(255,255,255,100%);
        border: 1px solid rgba(0,0,0,5%);
        text-align: left;
        max-width: 80%;
        color: rgba(0,0,0,100%);
        align-items: flex-start;
        margin-left: 0%;
        margin-right: 20%;
    `}
`

export default function Custom() {

    const { token } = useParams()
    const history = useHistory()

    const [loading, setLoading] = useState(false)

    const [chatId, setChatId] = useState(undefined)
    const [listProjectSendArchitect, setListProjectSendArchitect] = useState([])

    const [listProjectApproved, setListProjectApproved] = useState([])
    const [chats, setChats] = useState([])

    const [listProjectAccept, setListProjectAccept] = useState([])
    const [modalEstimateOpen, setModalEstimateOpen] = useState(false)

    const messagesEndRef = useRef(null);

    useEffect(() => {
        async function loadData() {
            try {
                setLoading(true)
                const response = await api.get(`v1/${token}/customization `);
    
                if (response.status !== 200) {
                    toast.error(response.data.message)
                } else {
                    setChatId(response.data.chat.id)
                    setChats(response.data.chat.messages)
                    setListProjectSendArchitect(response.data.versions)
    
                    setListProjectApproved(response.data.estimates.filter(e => e.approved))
    
                    const estimateNotApproved = response.data.estimates.filter(e => !e.approved)
                    setListProjectAccept(estimateNotApproved)
    
                    setTimeout(() => {
                        scrollToBottom()
                    }, 500);
                }
    
            } catch (err) {
                toast.error(err.response?.data?.message || 'Não foi possível localizar a solicitação de personalização');
            }
    
            setLoading(false)
        }

        loadData();
    }, [token]);

    const sendMessage = async message => {
        try {
            await api.post(`/v1/${token}/customization/chat/${chatId}/message?`, message)
            const response = await api.get(`v1/${token}/customization `);
        
            if (response.status !== 200) {
                toast.error(response.data.message || "Erro ao regarregar o chat")
            } else {
                setChatId(response.data.chat.id)
                setChats(response.data.chat.messages)
        
                setTimeout(() => {
                    scrollToBottom()
                }, 500);
            }
        } catch (e) {
            console.error(e)
            toast.error("Erro ao enviar mensagem")
            return false
        }

        toast.success("Mensagem enviada")
        return true
    }

    async function getUrlAttachments(params) {
        let url
        try {
            url = await api.get(`/v1/${token}/customization/chat/${chatId}/attachment?extension=${params.extension}`)
        } catch (e) {
            console.error(e)
        }
        return url
    }

    function scrollToBottom() {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
    }

    return (
        <div>
            <Container className='container-fluid-folder' fluid={true}>
                <Container className='container-folder-page'>
                    <br />
                    <div style={{ display: 'flex' }}>
                        <Link to={`/${token}`}>
                            <ArrowBack fontSize='large' />
                        </Link>
                        <h2 style={{ marginLeft: 30 }}> Personalizações </h2>
                        {loading && <div style={{ marginTop: "5px" }}><Loading /></div>}
                    </div>

                    <Box
                        display="inline-flex"
                        flexDirection="column"
                        style={{
                            height: "calc(100dvh - 70px)",
                            width: '100%',
                        }}>

                        {Boolean(listProjectAccept.length) && !loading &&
                            <>
                                <DivButton>
                                    <FBButton
                                        color='primary'
                                        onClick={() => setModalEstimateOpen(true)}
                                    >
                                        Aprovar Personalizações
                                    </FBButton>
                                </DivButton>

                                <Modal
                                    centered
                                    size=""
                                    dialogClassName="modal-90w"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    show={modalEstimateOpen}
                                    handleClose={() => setModalEstimateOpen(false)}
                                    style={{ padding: "15px" }}

                                >
                                    <span
                                        onClick={() => setModalEstimateOpen(false)}
                                        className="close-button icon-close"
                                    />

                                    <Typography
                                        variant='h6'
                                        style={{ marginBottom: "10px", marginTop: "20px" }}>
                                        Lista de orçamentos para aprovação
                                    </Typography>
                                    {listProjectAccept.map((item, index) => (
                                        <div key={index} style={{ width: "100%" }}>
                                            <DividerStyle />

                                            <ButtonLink
                                                onClick={() => history.push(`/${token}/customizacoes/accept_project/${item.id}`)}
                                                disabled={loading || false}
                                                style={{ width: "100%" }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifycontent: "space-between",
                                                        width: "100%",
                                                        alignitems: "center",
                                                        padding: "5px"
                                                    }}
                                                >
                                                    <Typography> {item.title} </Typography>
                                                    <Typography style={{ marginLeft: "auto" }}> <KeyboardArrowRight /></Typography>
                                                </div>
                                            </ButtonLink>
                                        </div>
                                    ))}

                                    <DividerStyle />


                                </Modal>
                            </>
                        }

                        {Boolean(listProjectApproved.length) && !loading &&
                            <>
                                <DividerStyle />

                                <ButtonLink
                                    onClick={() => history.push(`/${token}/customizacoes/approved_project`)}
                                    disabled={loading}
                                    style={{ width: "100%" }}
                                >
                                    <DivSumaryLink>
                                        <Typography> Orçamentos aprovados </Typography>
                                        <Typography style={{ marginLeft: "auto" }}> <KeyboardArrowRight /></Typography>
                                    </DivSumaryLink>
                                </ButtonLink>

                                <div style={{ marginBottom: "-10px" }}>
                                    <DividerStyle />
                                </div>
                            </>
                        }

                        {Boolean(listProjectSendArchitect.length) && !loading &&
                            <>
                                {!listProjectApproved.length ? <DividerStyle /> : <div style={{ marginTop: "10px" }} />}
                                <ButtonLink
                                    onClick={() => history.push(`/${token}/customizacoes/list_project_send`)}
                                    disabled={loading}
                                    style={{ width: "100%" }}
                                >
                                    <DivSumaryLink>
                                        <Typography> Projetos </Typography>
                                        <Typography style={{ marginLeft: "auto" }}> <KeyboardArrowRight /></Typography>
                                    </DivSumaryLink>
                                </ButtonLink>
                                <div style={{ marginBottom: "-10px" }}>
                                    <DividerStyle />
                                </div>
                            </>
                        }

                        <DivChat>
                            {chats?.length ? (
                                chats.map((chat, i) => (
                                    <Box key={i}>
                                        {chat.created_by &&
                                            <Typography
                                                variant="body1"
                                                style={{ marginLeft: "5px", marginBottom: "2px" }}
                                            >
                                                {chat.created_by.name}
                                            </Typography>
                                        }
                                        <DivMessage chat={chat}>
                                            <Typography variant='body2'> {chat.text} </Typography>

                                            {Boolean(chat.attachments.filter(i => i.attachment_url).length) &&
                                                <StyledGrid
                                                    rowHeight={70}
                                                    cols={1}
                                                >
                                                    {chat.attachments.filter(i => i.attachment_url).map((item, i) => (
                                                        <ClickableAttachment
                                                            key={i}
                                                            attachment={{ attachment_url: item.attachment_url, name: item.name }}
                                                        />
                                                    ))}
                                                </StyledGrid>
                                            }

                                            <div style={{ marginLeft: "auto" }}>
                                                <Typography style={{ fontSize: "10px" }} variant='caption'>
                                                    {moment(chat.created_at).format("DD/MM/YYYY HH:mm")}
                                                </Typography>
                                            </div>

                                        </DivMessage>
                                    </Box>
                                ))

                            ) : chats ? (
                                <Typography variant="caption">
                                    Nenhuma conversa iniciada.
                                </Typography>
                            ) : null}

                            <div ref={messagesEndRef} />

                        </DivChat>
                        <DividerStyle />

                        <div style={{ margin: "0px 0px 10px 0px" }} >
                            <ChatMessageFooter
                                uploadStrategy="public"
                                onSubmit={sendMessage}
                                uploadFile={params => getUrlAttachments(params)}
                            />
                        </div>
                    </Box>
                </Container>
            </Container>
        </div >
    );
}

const DividerDiv = styled.div`
    margin: 10px 0px 10px 0px;     
    width: 100%;
`

export const DividerStyle = () => {
    return (
        <DividerDiv >
            <Divider style={{ backgroundColor: "#474747", width: "100%", margin: "0px 0px 0px -10px" }} />
        </DividerDiv>
    )
}
