import React, { useState, useEffect } from "react";

import "./Sac.sass";

import { toast } from "react-toastify";
import { Container } from "react-bootstrap";
import api from "~/services/api";
import moment from "moment";

export default ({ push, id_conversation, hideTitle, token }) => {

    const [serviceRequest, setRequests] = useState([]);

    useEffect(() => {
        async function getServices() {
            try {
                const response = await api.get(
          `v1/units/${token}/services_request/specific/${id_conversation}`
                );
                setRequests(response.data)
            } catch (e) {
                toast.error("Não foi possível localizar as mensagens da solicitação.");
            }
        }

        getServices();
    }, [id_conversation]);

    const formatDateTime = (dateTime) => {
        var date = dateTime.substr(0, 10);
        var time = dateTime.substr(11, 5);

        var dateClean = date.split("-");

        return `${dateClean[2]}/${dateClean[1]}/${dateClean[0]} ${time}`;
    };

    return (
        <Container className="container-conversation">
            {!hideTitle && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "inherit",
                        alignItems: "center",
                    }}
                >
                    <span onClick={() => push("conversation-screen")} className="icon-back" />
                    <h2>Agenda de prestadores</h2>
                </div>
            )}
            {(serviceRequest.map((provider, i) => {
                const { date, visit_date, array } = provider;
                return (
                    <ul key={i}>
                        <div className="body-chat-box">
                            <div className="container-chat-buttons">
                                <div>
                                    {array && (
                                        <div className="service-provider">
                                            <div>
                                                <span>
                                                    {visit_date ? formatDateTime(visit_date) : moment(date).format("DD/MM/YYYY HH:mm")}
                                                </span>
                                            </div>
                                            <div className="service-provider-detail">
                                                {array.image_url &&
                          <div>
                              <img alt="imagem" src={array.image_url} />
                          </div>
                                                }
                                                <div>
                                                    <p>{array.name}</p>
                                                    <p>{array.phone}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </ul>
                )
            }))}
        </Container>
    );
};
