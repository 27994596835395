import React from "react";
import "./Navbar.sass";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { track } from "~/services/track";
import styled from "styled-components";
import { KeyboardArrowLeft } from "@material-ui/icons";

const NavbarStyled = styled(Navbar)`
  display: unset;
`

const LabelIndex = styled.label`
  color: #145198;
  font-Size: 16px;
  word-break: break-word;
  cursor: pointer;
`
const LabelUnit = styled.label`
  color: #081630;
  font-Size: 18px;
  word-break: break-word;
  font-weight: bold;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin: 20px 0px 20px 0px;
  @media (max-width: 800px) {
    margin: 20px 0px 0px 0px;
  }   
`

export default function BackIndex({ link, workMonitorBuilding, label }) {

  return (
    <NavbarStyled>

      <Navbar.Brand>
        <Link
          onClick={() => track(`VOLTAR_PARA: ${link}`)}
          to={link}
        >
          <LabelIndex>
            {label || <><KeyboardArrowLeft /> Voltar </>}
          </LabelIndex>
        </Link>
      </Navbar.Brand>

      {workMonitorBuilding &&
        <div style={{ textAlign: "center" }}>
          <LabelUnit>
            {workMonitorBuilding.buildingName} - {workMonitorBuilding.unitName}
          </LabelUnit>
        </div>
      }

    </NavbarStyled>
  );
};
