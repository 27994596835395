import React, { Fragment, useState, useEffect, useCallback } from "react";
import "./Sac.sass";
import { Container, Col } from "react-bootstrap";
import Modal from "~/components/Modal";
import { CenterSpinnerButton } from "~/components/Spinner";
import FormScreen from "./FormScreen";
import ServicesRequest from "./ServicesRequest";
import FormClient from "./FormClient";
import SuggestionsScreen from "./SuggestionsScreen";
import GuaranteesScreen from "./GuaranteesScreen";
import api from "~/services/api";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as UnitActions from "~/store/modules/unit/actions";
import { toast } from "react-toastify";
import history from "~/services/history";
import WarningOpeningSac from "./WarningOpeningSac";
import { Box, Typography } from "@material-ui/core";
import { PageHeader } from "../Layout";
import { usePromise } from "~/utils/use-promise";
import FBButton from "../Button/FBButton";
import { SacCard } from "../Card/card";
import Alert from '@mui/material/Alert';

export default function Sac() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState("");
    const unit_detail = useSelector((store) => store.unit.detail);
    const [construction_system, setConstructionSystem] = useState({ type: undefined, guid: undefined, key: undefined });
    const [suggestions, setSuggestions] = useState(null);
    const [guarantees, setGuarantees] = useState(null);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [documentNumber, setDocumentNumber] = useState("");
    const [acceptedOpeningTerm, setAcceptedOpeningTerm] = useState(null);
    const [warningOpeningSac, setWarningOpeningSac] = useState("");
    const [blockNewSacOnPendingSignature, setBlockNewSacOnPendingSignature] = useState(false);
    const [documentRequired, setDocumentRequired] = useState(false);

    const { token } = useParams();
    const [promise, setPromise] = useState(() => api.get(`v1/units/${token}/services_request?sort=created_at.desc`))
    const { data: services = [], loaded } = usePromise(promise)

    useEffect(() => {
        async function getUnitDetails() {
            try {
                const response = await api.get(`v1/units/${token}`);
                setLoading(false);

                let unit;

                if (token.length === 5) {
                    unit = response.data.find((u) => u.token === token);
                } else {
                    unit = response.data.find((u) => u.legacy_guid === token);
                }

                dispatch(UnitActions.saveUnit(unit));
                setWarningOpeningSac(unit.building.warning_opening_sac);
                setBlockNewSacOnPendingSignature(unit.blockNewSacOnPendingSignature)
                setDocumentRequired(unit.requiredInsertionDocumentNewSac)

            } catch (e) {
                console.error(e);
                toast.error("Não foi possível localizar o código informado.");
                history.push("/");
            }

        }

        getUnitDetails();
    }, [token]);

    function closeModal() {
        setSteps([])
    }

    const finalized = services.filter(s => s.custom_status ? ["FINALIZED", "CANCELED"].includes(s.custom_status?.default_status) : [4, 5].includes(s.status))
    const ongoing = services.filter(s => s.custom_status ? !["FINALIZED", "CANCELED"].includes(s.custom_status.default_status) : ![4, 5].includes(s.status))
    const askedSignature = services.some(s => s.custom_status ? ["ASKED_SIGNATURE"].includes(s.custom_status?.default_status) : [6].includes(s.status))

    const [steps, setSteps] = useState([])

    const createSac = () => {
        if (warningOpeningSac && warningOpeningSac !== '<p><br></p>') {
            setSteps(["warning-opening-sac"])
        } else {
            setSteps(["form-client"]);
        }
    }


    const push = useCallback((s) => {
        setSteps(st => Array.isArray(s) ? s : [...st, s])
    }, [])

    const reset = () => {
        setPromise(api.get(`v1/units/${token}/services_request?sort=created_at.desc`))
        setSteps([])
    }

    const previous = useCallback(() => {
        setSteps(st => {
            st.pop()
            return [...st]
        })

    }, [])

    function renderStep() {
        const last = steps[steps.length - 1]
        switch (last) {
        case "warning-opening-sac":
            return <WarningOpeningSac 
                push={push} 
                warningOpeningSac={warningOpeningSac}
                setAcceptedOpeningTerm={setAcceptedOpeningTerm}
            />
        case "options-screen":
            return (
                <ServicesRequest
                    guidBuilding={unit_detail?.building?.guid}
                    push={push}
                    previous={previous}
                    token={token}
                    setGuarantees={setGuarantees}
                    constructionSystem={construction_system}
                    setSuggestions={setSuggestions}
                    setConstructionSystem={setConstructionSystem}
                />
            )
        case "suggestions-screen":
            return (
                <SuggestionsScreen
                    previous={previous}
                    suggestions={suggestions}
                    setGuarantees={setGuarantees}
                    constructionSystem={construction_system}
                    token={token}
                    push={push}
                />
            )
        case "expired-guarantees":
            return (
                <GuaranteesScreen
                    previous={previous}
                    guarantees={guarantees}
                    push={push}
                />
            )
        case "form-client":
            return <FormClient
                unit={unit_detail}
                documentRequired={documentRequired}
                push={push}
                previous={previous}
                setModalOpen={closeModal}
                setName={setName}
                setPhone={setPhone}
                setDocumentNumber={setDocumentNumber}
            />
        case "form-screen":
            return <FormScreen
                reset={reset}
                previous={previous}
                construction_system={construction_system}
                name={name}
                phone={phone}
                documentNumber={documentNumber}
                acceptedOpeningTerm={acceptedOpeningTerm}
            />
        case "emergency-screen":
            return (
                <Container className="container-emergency">
                    {loading ? (
                        <CenterSpinnerButton />
                    ) : (
                        unit_detail && (
                            <Col>
                                <span className="icon-marker" />
                                <p>Por favor, entre em contato com a construtora.</p>
                                <div>
                                    {unit_detail.company_phone && (
                                        <Fragment>
                                            <span className="icon-marker" />
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={`https://api.whatsapp.com/send?phone=55${unit_detail.company_phone}`}
                                            >
                                                <p>{unit_detail.company_phone}</p>
                                            </a>
                                        </Fragment>
                                    )}
                                </div>
                            </Col>
                        )
                    )}
                </Container>
            )
        }
    }

    return (
        <Fragment>

            <Modal
                centered
                size="xl"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                show={Boolean(steps.length)}
                handleClose={() => setSteps([])}
            >
                <span onClick={() => setSteps([])} className="close-button icon-close" />
                {renderStep()}
            </Modal>
            <Container className='container-fluid-folder' fluid={true}>
                <div className="container" style={{ padding: 0 }}>
                    <PageHeader backTo={`/${token}`} > Assistência Técnica </PageHeader>

                    <Box sx={{ p: 3 }} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                        {blockNewSacOnPendingSignature && askedSignature &&
                            <Box marginBottom="5px" width="100%">
                                <Alert severity="warning" style={{ textAlign: "center", alignItems: "center" }} >
                                    <Typography variant="caption"> Por favor, assine os chamados anteriores antes de abrir um novo.</Typography>
                                </Alert>
                            </Box>
                        }

                        <FBButton
                            disabled={blockNewSacOnPendingSignature && askedSignature}
                            variant="outlined"
                            style={{ backgroundColor: "white" }}
                            color='primary'
                            onClick={createSac}
                        >
                            Nova solicitação
                        </FBButton>
                    </Box>

                    <Box display="flex" flexDirection="column">
                        <Typography style={{ margin: "10px 0" }} variant="caption" align="center">
                            Chamados em aberto
                        </Typography>
                        {loaded && !ongoing.length ? (
                            <Typography style={{ margin: "10px 0" }} variant="caption" align="center"> Nenhuma solicitação em aberto </Typography>
                        ) : ongoing.map(d => <SacCard sac={d} key={d.id} />)}
                        {Boolean(finalized.length) && (
                            <>
                                <Typography style={{ margin: "10px 0" }} variant="caption" align="center">
                                    Chamados finalizados
                                </Typography>
                                {finalized.map(d => <SacCard sac={d} key={d.id} />)}
                            </>)}
                    </Box>

                </div>
            </Container>

        </Fragment>
    );
}
