import React, {useState} from 'react'
import { Box, Typography } from "@material-ui/core"
import { Link } from "react-router-dom"
import styled from 'styled-components'
import { track } from '~/services/track'
import { colorForStatus, textForStatus } from '../Sac/ListScreen'
import { ArrowDownward, NavigateNext } from '@material-ui/icons'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import {ClickableAttachment} from '../Sac/Attachment'
import {Badge} from '@mui/material'


const StyledBox = styled(Box)`
    background-color: white;;
    border-radius: 15px;
    color: #00224A;
    transition: 0.5s;
    margin-bottom: 15px;
    * {
        font-family: var(--font-family) !important;
    }
    img, svg {
        width: 25px;
        height: 25px;
    }
    &:hover {
        transform: scale(1.02);
        box-shadow: 0px 0px 4px rgba(8, 22, 48, 0.1);
    }
`
export const Card = ({ children, icon, to, event, onClick, metadata, badgeContent }) => {

    function click(ev) {
        if (event) { track(event, metadata) }
        if (onClick) { onClick(ev) }
    }
    const content = (
        <StyledBox sx={{ p: 2, pl: 2.5, pr: 2.5 }} display="flex" style={{ gap: 2 }} justifyContent="space-between">
            <Typography>
                {children}
            </Typography>
            <Badge className="custom-badge" color="error" anchorOrigin={{ vertical: 'top', horizontal: 'left', }} badgeContent={badgeContent} >
                <div>
                    {icon}
                </div>
            </Badge>
        </StyledBox>
    )
    if (to) {
        return ( <Link to={to} onClick={click}> {content} </Link>)
    }
    return content
}


const StyledLink = styled(Link)`
  border-radius: 15px;
  padding: 3px 8px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(197, 223, 255, 0.2);
  margin-bottom: 5px;
  font-size: 15px;
  box-shadow: 0px 1px 6px rgba(8,22,48,0.15);
  svg {
    width: 15px;
    height: 15px;
  }
`

export const ListCard = ({ items, title }) => {
    const [allVisible, setAllVisible] = useState(false)
    function expand(ev) {
        ev.preventDefault()
        setAllVisible(true)
    }
    return (
        <StyledBox sx={{ p: 2, pl: 2.5, pr: 2.5 }} display="flex" style={{ gap: 2 }} justifyContent="space-between" flexDirection="column">
            <Typography variant="caption"> {title} </Typography>
            {(allVisible ? items : items.slice(0, 2)).map(i => (
                <ClickableAttachment key={i.guid} className="recent_file_card" attachment={i} />
            ))}
            {(!allVisible && items.length > 2) && <StyledLink onClick={expand}>
                <Typography variant="caption">
                    Mostrar todos 
                </Typography>
                <ArrowDownward size="small"/>
            </StyledLink>}
        </StyledBox>
    )

}


const SacBox = styled(Box)`
    background-color: white;
    border-bottom: 1px solid rgba(10, 10, 10, 0.2);
`
export const SacCard = ({ sac }) => {
    const { token } = useParams()
    return (
        <Link to={`/${token}/assistencia/chamado/${sac.guid}`}>
            <SacBox sx={{ p: 2 }} display="flex" justifyContent="space-between">
                <span>{sac.id}</span>
                <span>
                    <p
                        style={{ backgroundColor: sac.custom_status?.hex }}
                        className={`sac-status status-${colorForStatus(sac.status)}`}
                    >
                        {sac.custom_status?.label || textForStatus(sac.status)}
                    </p>
                </span>
                <Badge className="custom-badge" color="error" anchorOrigin={{ vertical: 'top', horizontal: 'left', }} badgeContent={sac.client_unread_count} >
                    <NavigateNext />
                </Badge>
            </SacBox>
        </Link>
    )
}
