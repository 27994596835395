import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { ReactComponent as Calendar } from "../../assets/folder_icons/calendar.svg";
import api from "~/services/api";
import { PageHeader } from "../Layout";
import { usePromise } from "~/utils/use-promise";
import Loading from "../loading";
import ConversationScreenV2 from "./ConversationScreenV2";
import { colorForStatus, textForStatus } from "./ListScreen";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom"
import Modal from "../Modal";
import SchedulesScreen from "./SchedulesScreen";
import FBButton from "../Button/FBButton";
import {usePrimaryColor} from "~/contexts/custom-theme";


const Fixed = styled(FBButton)`
    position: absolute !important;
    width: 35px;
    right: 15px;
    top: 15px;
    img {
        width: 30px;
    }
`

export default function UnitSac() {
    const { hash, pathname  } = useLocation() 

    const history = useHistory();
    const { token, sacGuid } = useParams();

    const [promise, setPromise] = useState(() => {
        if (sacGuid) {
            return api.get(`v1/units/${token}/services_request/${sacGuid}`)
        } else {
            return Promise.reject()
        }
    }, [sacGuid, token])

    const { data: sac, loaded } = usePromise(promise)
    const refresh = useCallback(() => {
        setPromise(api.get(`v1/units/${token}/services_request/${sacGuid}`))
    }, [sacGuid])

    const primaryColor = usePrimaryColor()
    if (!loaded) {
        return <Loading />
    }

    function closeProvider() {
        history.push(pathname, { hash: ""})
    }


    return (
        <Container className='container-fluid-folder' fluid={true} style={{ display: "flex", height: '100dvh', minHeight: 'unset'}}>

            <Modal
                centered
                size="xl"
                title="Agenda de prestadores"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                show={hash === "#provider"}
                handleClose={closeProvider}
            >
                <SchedulesScreen token={token} hideTitle push={closeProvider} id_conversation={sac.id} />
            </Modal>
            <Box display="flex" flexDirection="column" className="container container-conversation-v2" style={{ padding: 0 }}>
                <PageHeader backTo={`/${token}/assistencia`}>
                    Chamado {sac.id}
                    <Fixed variant="text" size="small"  onClick={() => history.push("#provider")}>
                        <Calendar style={{ fill: primaryColor }} />
                    </Fixed>
                </PageHeader>
                <Box sx={{ p: 2 }} display="flex" justifyContent="center">
                    <p
                        style={{ backgroundColor: sac.custom_status?.hex }}
                        className={`sac-status status-${colorForStatus(sac.status)}`}
                    >
                        {sac.custom_status?.label || textForStatus(sac.status)}
                    </p>
                </Box>
                <ConversationScreenV2 refresh={refresh} serviceRequest={sac} messages={sac.service_request_messages} />
            </Box>
        </Container>
    );
}
