import React from "react";
import { sortBy } from 'lodash';
import "./Sac.sass";
import { Col, Container, Row } from "react-bootstrap";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ClickableAttachment } from "./Attachment";
import { track } from "~/services/track";
import { Answer } from "~/pages/Faq/Question";
import api from "~/services/api";
import FBButton from "../Button/FBButton";
import {ModalHeader} from "../Layout";

export default ({ guidUnit, push, previous, suggestions, constructionSystem, token, setGuarantees }) => {
  const advance = async () => {
    track("ABRIR_CHAMADO_IGNORANDO_SUGESTOES")
    try {
      const params = [];

      constructionSystem?.type && params.push("key=" + constructionSystem.type);
      constructionSystem?.key && params.push("name=" + constructionSystem.key);

      const { data } = await api.get(`/v1/units/${token || guidUnit}/expired-guarantees` + ((params.length || "") && "?" + params.join("&")))

      if (data.result?.length) {
        setGuarantees(data.result)
        push("expired-guarantees")
      } else {
        push("form-screen")
      }
    } catch (err) {
      push("form-screen")
    }

  }
  return (
    <Container>
      <ModalHeader title="Perguntas Frequentes" previous={previous} />
      <Col style={{ display: 'block' }}>
        {suggestions.map(c => (
          <Accordion
            style={{ width: '100%' }}
            onChange={(_, open) => track(open ? "SUGESTAO_ABRIU_PERGUNTA" : "SUGESTAO_FECHOU_PERGUNTA", {
              question: c.question
            })}
            key={c.question}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              {c.question}
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Typography style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>
                  <Answer text={c.answer} />
                </Typography>
                <Row>
                  {sortBy((c.questions_files || []), ["name"]).map(questionFile => (
                    <ClickableAttachment key={questionFile.guid} attachment={questionFile} />
                  ))}
                </Row>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </Col>
      <br />

      <div className="v2-modal-footer">
        {previous &&
          <FBButton
            style={{ width: "100px", marginRight: "10px" }}
            variant="outlined"
            onClick={() => previous()}
          >
            Voltar
          </FBButton>
        }
        <FBButton
          primary
          style={{ width: "200px" }}
          onClick={advance}
        >
          Quero abrir um chamado
        </FBButton>
      </div>
    </Container>
  );
};
