import React from "react";
import moment from "moment";
import noPhoto from "~/assets/imgs/common/noPhoto.jpeg";

import ic_battery from "../assets/folder_icons/icon_battery.svg";
import ic_book_1 from "../assets/folder_icons/icon_book_1.svg";
import ic_calendar from "../assets/folder_icons/calendar.svg";
import ic_book_2 from "../assets/folder_icons/icon_book_2.svg";
import ic_book_3 from "../assets/folder_icons/icon_book_3.svg";
import ic_book_4 from "../assets/folder_icons/icon_book_4.svg";
import ic_book_5 from "../assets/folder_icons/icon_book_5.svg";
import ic_brick from "../assets/folder_icons/icon_brick.svg";
import ic_building_1 from "../assets/folder_icons/icon_building_1.svg";
import ic_building_2 from "../assets/folder_icons/icon_building_2.svg";
import ic_building_3 from "../assets/folder_icons/icon_building_3.svg";
import ic_building_4 from "../assets/folder_icons/icon_building_4.svg";
import ic_building_5 from "../assets/folder_icons/icon_building_5.svg";
import ic_camera from "../assets/folder_icons/icon_camera.svg";
import ic_connection from "../assets/folder_icons/icon_connection.svg";
import ic_construction_1 from "../assets/folder_icons/icon_construction_1.svg";
import ic_construction_2 from "../assets/folder_icons/icon_construction_2.svg";
import ic_construction_3 from "../assets/folder_icons/icon_construction_3.svg";
import ic_construction_4 from "../assets/folder_icons/icon_construction_4.svg";
import ic_construction_5 from "../assets/folder_icons/icon_construction_5.svg";
import ic_construction_6 from "../assets/folder_icons/icon_construction_6.svg";
import ic_construction_7 from "../assets/folder_icons/icon_construction_7.svg";
import ic_construction_8 from "../assets/folder_icons/icon_construction_8.svg";
import ic_construction_9 from "../assets/folder_icons/icon_construction_9.svg";
import ic_construction_10 from "../assets/folder_icons/icon_construction_10.svg";
import ic_construction_11 from "../assets/folder_icons/icon_construction_11.svg";
import ic_construction_12 from "../assets/folder_icons/icon_construction_12.svg";
import ic_construction_13 from "../assets/folder_icons/icon_construction_13.svg";
import ic_construction_14 from "../assets/folder_icons/icon_construction_14.svg";
import ic_construction_15 from "../assets/folder_icons/icon_construction_15.svg";
import ic_construction_16 from "../assets/folder_icons/icon_construction_16.svg";
import ic_construction_17 from "../assets/folder_icons/icon_construction_17.svg";
import ic_costumer from "../assets/folder_icons/icon_costumer.svg";
import ic_door from "../assets/folder_icons/icon_door.svg";
import ic_drop_1 from "../assets/folder_icons/icon_drop_1.svg";
import ic_drop_2 from "../assets/folder_icons/icon_drop_2.svg";
import ic_drop_3 from "../assets/folder_icons/icon_drop_3.svg";
import ic_home_1 from "../assets/folder_icons/icon_home_1.svg";
import ic_home_2 from "../assets/folder_icons/icon_home_2.svg";
import ic_home_3 from "../assets/folder_icons/icon_home_3.svg";
import ic_home_4 from "../assets/folder_icons/icon_home_4.svg";
import ic_lightbulb_1 from "../assets/folder_icons/icon_lightbulb_1.svg";
import ic_lightbulb_2 from "../assets/folder_icons/icon_lightbulb_2.svg";
import ic_lightbulb_3 from "../assets/folder_icons/icon_lightbulb_3.svg";
import ic_picture_1 from "../assets/folder_icons/icon_picture_1.svg";
import ic_picture_2 from "../assets/folder_icons/icon_picture_2.svg";
import ic_refresh from "../assets/folder_icons/icon_refresh.svg";
import ic_sync from "../assets/folder_icons/icon_sync.svg";
import ic_task_1 from "../assets/folder_icons/icon_task_1.svg";
import ic_task_2 from "../assets/folder_icons/icon_task_2.svg";
import ic_done from "../assets/folder_icons/done_24px.svg";
import ic_done_all from "../assets/folder_icons/done_all_24px.svg";
import ic_done_outline from "../assets/folder_icons/done_outline_24px.svg";
import ic_donut_large from "../assets/folder_icons/donut_large_24px.svg";
import ic_donut_small from "../assets/folder_icons/donut_small_24px.svg";
import ic_drag_indicator from "../assets/folder_icons/drag_indicator_24px.svg";
import ic_eject from "../assets/folder_icons/eject_24px.svg";
import ic_euro_symbol from "../assets/folder_icons/euro_symbol_24px.svg";
import ic_exit_to_app from "../assets/folder_icons/exit_to_app_24px.svg";
import ic_explore from "../assets/folder_icons/explore_24px.svg";
import ic_explore_off from "../assets/folder_icons/explore_off_24px.svg";
import ic_extension from "../assets/folder_icons/extension_24px.svg";
import ic_face from "../assets/folder_icons/face_24px.svg";
import ic_face_unlock from "../assets/folder_icons/face_unlock_24px.svg";
import ic_favorite from "../assets/folder_icons/favorite_24px.svg";
import ic_favorite_border from "../assets/folder_icons/favorite_border_24px.svg";
import ic_find_replace from "../assets/folder_icons/find_replace_24px.svg";
import ic_fingerprint from "../assets/folder_icons/fingerprint_24px.svg";
import ic_flight_land from "../assets/folder_icons/flight_land_24px.svg";
import ic_flight_takeoff from "../assets/folder_icons/flight_takeoff_24px.svg";
import ic_flip_to_back from "../assets/folder_icons/flip_to_back_24px.svg";
import ic_flip_to_front from "../assets/folder_icons/flip_to_front_24px.svg";
import ic_g_translate from "../assets/folder_icons/g_translate_24px.svg";
import ic_gavel from "../assets/folder_icons/gavel_24px.svg";
import ic_grade from "../assets/folder_icons/grade_24px.svg";
import ic_group_work from "../assets/folder_icons/group_work_24px.svg";
import ic_help from "../assets/folder_icons/help_24px.svg";
import ic_help_outline from "../assets/folder_icons/help_outline_24px.svg";
import ic_highlight_off from "../assets/folder_icons/highlight_off_24px.svg";
import ic_history from "../assets/folder_icons/history_24px.svg";
import ic_home from "../assets/folder_icons/home_24px.svg";
import ic_horizontal_split from "../assets/folder_icons/horizontal_split_24px.svg";
import ic_http from "../assets/folder_icons/http_24px.svg";
import ic_https from "../assets/folder_icons/https_24px.svg";
import ic_important_devices from "../assets/folder_icons/important_devices_24px.svg";
import ic_info from "../assets/folder_icons/info_24px.svg";
import ic_info_outline from "../assets/folder_icons/info_outline_24px.svg";
import ic_input from "../assets/folder_icons/input_24px.svg";
import ic_invert_colors from "../assets/folder_icons/invert_colors_24px.svg";
import ic_label from "../assets/folder_icons/label_24px.svg";
import ic_label_off from "../assets/folder_icons/label_off_24px.svg";
import ic_label_outline from "../assets/folder_icons/label_outline_24px.svg";
import ic_language from "../assets/folder_icons/language_24px.svg";
import ic_launch from "../assets/folder_icons/launch_24px.svg";
import ic_lightbulb_outline from "../assets/folder_icons/lightbulb_outline_24px.svg";
import ic_line_style from "../assets/folder_icons/line_style_24px.svg";
import ic_line_weight from "../assets/folder_icons/line_weight_24px.svg";
import ic_list from "../assets/folder_icons/list_24px.svg";
import Folder from "../assets/imgs/common/folder.svg";
import icon_weather_2 from "../assets/folder_icons/icon_weather_2.svg"
import icon_weather_1 from "../assets/folder_icons/icon_weather_1.svg"
import phone from "../assets/folder_icons/icon_phone.svg"
import icon_smartphone from "../assets/folder_icons/icon_smartphone.svg"
import icon_hot from "../assets/folder_icons/icon_hot.svg"
import icon_crop from "../assets/folder_icons/icon_crop.svg"
import ic_air_conditioner_1 from "../assets/folder_icons/air-conditioner.png";
import ic_air_conditioner_2 from "../assets/folder_icons/ar_condicionado.png";
import ic_attention from "../assets/folder_icons/atencao.png";
import ic_security_camera_1 from "../assets/folder_icons/camera-de-seguranca.png";
import ic_security_camera_2 from "../assets/folder_icons/camera-de-seguranca_(1).png";
import ic_truck from "../assets/folder_icons/caminhao-de-bombeiros.png";
import ic_eletric_grill from "../assets/folder_icons/churrasqueira-eletrica.png";
import ic_cooker from "../assets/folder_icons/cooker.png";
import ic_tips from "../assets/folder_icons/dicas.png";
import ic_digital_print from "../assets/folder_icons/digitalizacao-de-impressao-digital.png";
import ic_pool_stairs from "../assets/folder_icons/escadas-da-piscina.png";
import ic_tour from "../assets/folder_icons/excursao-virtual.png";
import ic_extinguisher from "../assets/folder_icons/extintor-de-incendio.png";
import ic_gas_oven from "../assets/folder_icons/fogao-a-gas.png";
import ic_pictures from "../assets/folder_icons/fotos.png";
import ic_grill_1 from "../assets/folder_icons/grill.png";
import ic_grill_2 from "../assets/folder_icons/grill_(1).png";
import ic_planet from "../assets/folder_icons/iconmonstr-networking-6.png";
import ic_atomic from "../assets/folder_icons/iconmonstr-networking-7.png";
import ic_alert_1 from "../assets/folder_icons/iconmonstr-new-1.png";
import ic_alert_2 from "../assets/folder_icons/iconmonstr-new-2.png";
import ic_fireplace_1 from "../assets/folder_icons/lareira.png";
import ic_fireplace_2 from "../assets/folder_icons/lareira_(1).png";
import ic_manual from "../assets/folder_icons/manual.png";
import ic_pool from "../assets/folder_icons/piscina.png";
import ic_playground from "../assets/folder_icons/playground.png";
import ic_goalkeeper_1 from "../assets/folder_icons/porteiro.png";
import ic_goalkeeper_2 from "../assets/folder_icons/porteiro_(1).png";
import ic_project from "../assets/folder_icons/projeto.png";
import ic_slider from "../assets/folder_icons/slider.png";
import ic_virtual_tour from "../assets/folder_icons/Tour_Virtual.png";
import ic_video_play from "../assets/folder_icons/jogo-de-video.png";
import icon_luz from "../assets/folder_icons/icon_luz.svg"
import icon_torneira from "../assets/folder_icons/icon_torneira.svg"
import icon_parede from "../assets/folder_icons/icon_parede.svg"
import icon_janela from "../assets/folder_icons/icon_janela.svg"
import icon_pincel from "../assets/folder_icons/icon_pincel.svg"
import icon_pingo from "../assets/folder_icons/icon_pingo.svg"
import icon_churras from "../assets/folder_icons/icon_churras.svg"
import icon_crack from "../assets/folder_icons/icon_crack.svg"
import icon_outros from "../assets/folder_icons/icon_outros.svg"

import { ReactComponent as IconFolder } from "../assets/imgs/common/folder.svg";
import { ReactComponent as IconBattery } from "../assets/folder_icons/icon_battery.svg";
import { ReactComponent as IconBook1 } from "../assets/folder_icons/icon_book_1.svg";
import { ReactComponent as IconBook2 } from "../assets/folder_icons/icon_book_2.svg";
import { ReactComponent as IconBook3 } from "../assets/folder_icons/icon_book_3.svg";
import { ReactComponent as IconBook4 } from "../assets/folder_icons/icon_book_4.svg";
import { ReactComponent as IconBook5 } from "../assets/folder_icons/icon_book_5.svg";
import { ReactComponent as IconBrick } from "../assets/folder_icons/icon_brick.svg";
import { ReactComponent as IconBuilding1 } from "../assets/folder_icons/icon_building_1.svg";
import { ReactComponent as IconBuilding2 } from "../assets/folder_icons/icon_building_2.svg";
import { ReactComponent as IconBuilding3 } from "../assets/folder_icons/icon_building_3.svg";
import { ReactComponent as IconBuilding4 } from "../assets/folder_icons/icon_building_4.svg";
import { ReactComponent as IconBuilding5 } from "../assets/folder_icons/icon_building_5.svg";
import { ReactComponent as IconCamera } from "../assets/folder_icons/icon_camera.svg";
import { ReactComponent as IconConnection } from "../assets/folder_icons/icon_connection.svg";
import { ReactComponent as IconConstruction1 } from "../assets/folder_icons/icon_construction_1.svg";
import { ReactComponent as IconConstruction2 } from "../assets/folder_icons/icon_construction_2.svg";
import { ReactComponent as IconConstruction3 } from "../assets/folder_icons/icon_construction_3.svg";
import { ReactComponent as IconConstruction4 } from "../assets/folder_icons/icon_construction_4.svg";
import { ReactComponent as IconConstruction5 } from "../assets/folder_icons/icon_construction_5.svg";
import { ReactComponent as IconConstruction6 } from "../assets/folder_icons/icon_construction_6.svg";
import { ReactComponent as IconConstruction7 } from "../assets/folder_icons/icon_construction_7.svg";
import { ReactComponent as IconConstruction8 } from "../assets/folder_icons/icon_construction_8.svg";
import { ReactComponent as IconConstruction9 } from "../assets/folder_icons/icon_construction_9.svg";
import { ReactComponent as IconConstruction10 } from "../assets/folder_icons/icon_construction_10.svg";
import { ReactComponent as IconConstruction11 } from "../assets/folder_icons/icon_construction_11.svg";
import { ReactComponent as IconConstruction12 } from "../assets/folder_icons/icon_construction_12.svg";
import { ReactComponent as IconConstruction13 } from "../assets/folder_icons/icon_construction_13.svg";
import { ReactComponent as IconConstruction14 } from "../assets/folder_icons/icon_construction_14.svg";
import { ReactComponent as IconConstruction15 } from "../assets/folder_icons/icon_construction_15.svg";
import { ReactComponent as IconConstruction16 } from "../assets/folder_icons/icon_construction_16.svg";
import { ReactComponent as IconConstruction17 } from "../assets/folder_icons/icon_construction_17.svg";
import { ReactComponent as Iconcostumer } from "../assets/folder_icons/icon_costumer.svg";
import { ReactComponent as Icondoor } from "../assets/folder_icons/icon_door.svg";
import { ReactComponent as IconDrop1 } from "../assets/folder_icons/icon_drop_1.svg";
import { ReactComponent as IconDrop2 } from "../assets/folder_icons/icon_drop_2.svg";
import { ReactComponent as IconDrop3 } from "../assets/folder_icons/icon_drop_3.svg";
import { ReactComponent as IconHome1 } from "../assets/folder_icons/icon_home_1.svg";
import { ReactComponent as IconHome2 } from "../assets/folder_icons/icon_home_2.svg";
import { ReactComponent as IconHome3 } from "../assets/folder_icons/icon_home_3.svg";
import { ReactComponent as IconHome4 } from "../assets/folder_icons/icon_home_4.svg";
import { ReactComponent as IconLightBulb1 } from "../assets/folder_icons/icon_lightbulb_1.svg";
import { ReactComponent as IconLightBulb2 } from "../assets/folder_icons/icon_lightbulb_2.svg";
import { ReactComponent as IconLightBulb3 } from "../assets/folder_icons/icon_lightbulb_3.svg";
import { ReactComponent as IconPicture1 } from "../assets/folder_icons/icon_picture_1.svg";
import { ReactComponent as IconPicture2 } from "../assets/folder_icons/icon_picture_2.svg";
import { ReactComponent as IconRefresh } from "../assets/folder_icons/icon_refresh.svg";
import { ReactComponent as IconSync } from "../assets/folder_icons/icon_sync.svg";
import { ReactComponent as IconTask1 } from "../assets/folder_icons/icon_task_1.svg";
import { ReactComponent as IconTask2 } from "../assets/folder_icons/icon_task_2.svg";
import { ReactComponent as Done24px } from "../assets/folder_icons/done_24px.svg";
import { ReactComponent as DoneAll24px } from "../assets/folder_icons/done_all_24px.svg";
import { ReactComponent as DoneOutline24px } from "../assets/folder_icons/done_outline_24px.svg";
import { ReactComponent as DonutLarge24px } from "../assets/folder_icons/donut_large_24px.svg";
import { ReactComponent as DonutSmall24px } from "../assets/folder_icons/donut_small_24px.svg";
import { ReactComponent as DragIndicator24px } from "../assets/folder_icons/drag_indicator_24px.svg";
import { ReactComponent as Eject24px } from "../assets/folder_icons/eject_24px.svg";
import { ReactComponent as EuroSymbol24px } from "../assets/folder_icons/euro_symbol_24px.svg";
import { ReactComponent as ExitToApp24px } from "../assets/folder_icons/exit_to_app_24px.svg";
import { ReactComponent as Explore24px } from "../assets/folder_icons/explore_24px.svg";
import { ReactComponent as ExploreOff24px } from "../assets/folder_icons/explore_off_24px.svg";
import { ReactComponent as Extension24px } from "../assets/folder_icons/extension_24px.svg";
import { ReactComponent as Face24px } from "../assets/folder_icons/face_24px.svg";
import { ReactComponent as FaceUnlock24px } from "../assets/folder_icons/face_unlock_24px.svg";
import { ReactComponent as Favorite24px } from "../assets/folder_icons/favorite_24px.svg";
import { ReactComponent as FavoriteBorder24px } from "../assets/folder_icons/favorite_border_24px.svg";
import { ReactComponent as FindReplace24px } from "../assets/folder_icons/find_replace_24px.svg";
import { ReactComponent as FingerPrint24px } from "../assets/folder_icons/fingerprint_24px.svg";
import { ReactComponent as FlightLand24px } from "../assets/folder_icons/flight_land_24px.svg";
import { ReactComponent as FlightTakeOff24px } from "../assets/folder_icons/flight_takeoff_24px.svg";
import { ReactComponent as FlipToBack24px } from "../assets/folder_icons/flip_to_back_24px.svg";
import { ReactComponent as FlipToFront24px } from "../assets/folder_icons/flip_to_front_24px.svg";
import { ReactComponent as GTranslate24px } from "../assets/folder_icons/g_translate_24px.svg";
import { ReactComponent as Gavel24px } from "../assets/folder_icons/gavel_24px.svg";
import { ReactComponent as Grade24px } from "../assets/folder_icons/grade_24px.svg";
import { ReactComponent as GroupWork24px } from "../assets/folder_icons/group_work_24px.svg";
import { ReactComponent as Help24px } from "../assets/folder_icons/help_24px.svg";
import { ReactComponent as HelpOutline24px } from "../assets/folder_icons/help_outline_24px.svg";
import { ReactComponent as HighLightOff24px } from "../assets/folder_icons/highlight_off_24px.svg";
import { ReactComponent as History24px } from "../assets/folder_icons/history_24px.svg";
import { ReactComponent as Home24px } from "../assets/folder_icons/home_24px.svg";
import { ReactComponent as HorizontalSplit24px } from "../assets/folder_icons/horizontal_split_24px.svg";
import { ReactComponent as Http24px } from "../assets/folder_icons/http_24px.svg";
import { ReactComponent as Https24px } from "../assets/folder_icons/https_24px.svg";
import { ReactComponent as ImportantDevices24px } from "../assets/folder_icons/important_devices_24px.svg";
import { ReactComponent as Info24px } from "../assets/folder_icons/info_24px.svg";
import { ReactComponent as InfoOutline24px } from "../assets/folder_icons/info_outline_24px.svg";
import { ReactComponent as Input24px } from "../assets/folder_icons/input_24px.svg";
import { ReactComponent as InvertColors24px } from "../assets/folder_icons/invert_colors_24px.svg";
import { ReactComponent as Label24px } from "../assets/folder_icons/label_24px.svg";
import { ReactComponent as LabelOff24px } from "../assets/folder_icons/label_off_24px.svg";
import { ReactComponent as LabelOutline24px } from "../assets/folder_icons/label_outline_24px.svg";
import { ReactComponent as Language24px } from "../assets/folder_icons/language_24px.svg";
import { ReactComponent as Launch24px } from "../assets/folder_icons/launch_24px.svg";
import { ReactComponent as LightbulbOutline24px } from "../assets/folder_icons/lightbulb_outline_24px.svg";
import { ReactComponent as LineStyle24px } from "../assets/folder_icons/line_style_24px.svg";
import { ReactComponent as LineWeight24px } from "../assets/folder_icons/line_weight_24px.svg";
import { ReactComponent as List24px } from "../assets/folder_icons/list_24px.svg";
import { ReactComponent as IconCrop } from "../assets/folder_icons/icon_crop.svg";
import { ReactComponent as IconHot } from "../assets/folder_icons/icon_hot.svg";
import { ReactComponent as IconPhone } from "../assets/folder_icons/icon_phone.svg";
import { ReactComponent as IconSmartphone } from "../assets/folder_icons/icon_smartphone.svg";
import { ReactComponent as IconWeather1 } from "../assets/folder_icons/icon_weather_1.svg";
import { ReactComponent as IconWeather2 } from "../assets/folder_icons/icon_weather_2.svg";
import { ReactComponent as Cooker } from "../assets/folder_icons/cooker.svg";
import { ReactComponent as IconmonstrNetworking6 } from "../assets/folder_icons/iconmonstr-networking-6.svg";
import { ReactComponent as IconmonstrNetworking7 } from "../assets/folder_icons/iconmonstr-networking-7.svg";
import { ReactComponent as IconmonstrNew1 } from "../assets/folder_icons/iconmonstr-new-1.svg";
import { ReactComponent as IconmonstrNew2 } from "../assets/folder_icons/iconmonstr-new-2.svg";
import { ReactComponent as TourVirtual } from "../assets/folder_icons/Tour_Virtual.svg";
import { ReactComponent as IconLuz } from "../assets/folder_icons/icon_luz.svg"
import { ReactComponent as IconTorneira } from "../assets/folder_icons/icon_torneira.svg"
import { ReactComponent as IconParede } from "../assets/folder_icons/icon_parede.svg"
import { ReactComponent as IconJanela } from "../assets/folder_icons/icon_janela.svg"
import { ReactComponent as IconPincel } from "../assets/folder_icons/icon_pincel.svg"
import { ReactComponent as IconOingo } from "../assets/folder_icons/icon_pingo.svg"
import { ReactComponent as IconChurras } from "../assets/folder_icons/icon_churras.svg"
import { ReactComponent as IconCrack } from "../assets/folder_icons/icon_crack.svg"
import { ReactComponent as IconOutros } from "../assets/folder_icons/icon_outros.svg"
import { ReactComponent as AirConditioner } from "../assets/folder_icons/air-conditioner.svg";
import { ReactComponent as ArCondicionado } from "../assets/folder_icons/ar_condicionado.svg";
import { ReactComponent as Atencao } from "../assets/folder_icons/atencao.svg";
import { ReactComponent as CameraSeguranca1 } from "../assets/folder_icons/camera-de-seguranca.svg";
import { ReactComponent as CameraSeguranca2 } from "../assets/folder_icons/camera-de-seguranca_(1).svg";
import { ReactComponent as Caminha } from "../assets/folder_icons/caminhao-de-bombeiros.svg";
import { ReactComponent as ChurrasqueiraEletrica } from "../assets/folder_icons/churrasqueira-eletrica.svg";
import { ReactComponent as Dicas } from "../assets/folder_icons/dicas.svg";
import { ReactComponent as Digitalizaca } from "../assets/folder_icons/digitalizacao-de-impressao-digital.svg";
import { ReactComponent as Escada } from "../assets/folder_icons/escadas-da-piscina.svg";
import { ReactComponent as ExcursaoVirtual } from "../assets/folder_icons/excursao-virtual.svg";
import { ReactComponent as Extinto } from "../assets/folder_icons/extintor-de-incendio.svg";
import { ReactComponent as Fog } from "../assets/folder_icons/fogao-a-gas.svg";
import { ReactComponent as Fotos } from "../assets/folder_icons/fotos.svg";
import { ReactComponent as Grill } from "../assets/folder_icons/grill.svg";
import { ReactComponent as Gril } from "../assets/folder_icons/grill_(1).svg";
import { ReactComponent as Lareira } from "../assets/folder_icons/lareira.svg";
import { ReactComponent as Lareir } from "../assets/folder_icons/lareira_(1).svg";
import { ReactComponent as Manual } from "../assets/folder_icons/manual.svg";
import { ReactComponent as Piscina } from "../assets/folder_icons/piscina.svg";
import { ReactComponent as Playground } from "../assets/folder_icons/playground.svg";
import { ReactComponent as Porteiro } from "../assets/folder_icons/porteiro.svg";
import { ReactComponent as Porteir } from "../assets/folder_icons/porteiro_(1).svg";
import { ReactComponent as Projeto } from "../assets/folder_icons/projeto.svg";
import { ReactComponent as Slider } from "../assets/folder_icons/slider.svg";
import { ReactComponent as Jog } from "../assets/folder_icons/jogo-de-video.svg"
import { ReactComponent as Calendar } from "../assets/folder_icons/calendar.svg";

export const renderIconSvg = (icon) => {
    const style = { ...icon.style }
    switch (icon?.name) {
    case "folder":
        return <IconFolder style={{ ...style }} />;
    case "luz":
        return <IconLuz style={{ ...style }} />;
    case "torneira":
        return <IconTorneira style={{ ...style }} />;
    case "parede":
        return <IconParede style={{ ...style }} />;
    case "janela":
        return <IconJanela style={{ ...style }} />;
    case "pincel":
        return <IconPincel style={{ ...style }} />;
    case "pingo":
        return <IconOingo style={{ ...style }} />;
    case "churras":
        return <IconChurras style={{ ...style }} />;
    case "crack":
        return <IconCrack style={{ ...style }} />;
    case "outros":
        return <IconOutros style={{ ...style }} />;
    case "ic_battery":
        return <IconBattery style={{ ...style }} />;
    case "ic_book_1":
        return <IconBook1 style={{ ...style }} />;
    case "ic_book_2":
        return <IconBook2 style={{ ...style }} />;
    case "ic_book_3":
        return <IconBook3 style={{ ...style }} />;
    case "ic_book_4":
        return <IconBook4 style={{ ...style }} />;
    case "ic_book_5":
        return <IconBook5 style={{ ...style }} />;
    case "ic_brick":
        return <IconBrick style={{ ...style }} />;
    case "ic_building_1":
        return <IconBuilding1 style={{ ...style }} />;
    case "ic_building_2":
        return <IconBuilding2 style={{ ...style }} />;
    case "ic_building_3":
        return <IconBuilding3 style={{ ...style }} />;
    case "ic_building_4":
        return <IconBuilding4 style={{ ...style }} />;
    case "ic_building_5":
        return <IconBuilding5 style={{ ...style }} />;
    case "ic_camera":
        return <IconCamera style={{ ...style }} />;
    case "ic_connection":
        return <IconConnection style={{ ...style }} />
    case "ic_construction_1":
        return <IconConstruction1 style={{ ...style }} />
    case "ic_construction_2":
        return <IconConstruction2 style={{ ...style }} />
    case "ic_construction_3":
        return <IconConstruction3 style={{ ...style }} />
    case "ic_construction_4":
        return <IconConstruction4 style={{ ...style }} />
    case "ic_construction_5":
        return <IconConstruction5 style={{ ...style }} />
    case "ic_construction_6":
        return <IconConstruction6 style={{ ...style }} />
    case "ic_construction_7":
        return <IconConstruction7 style={{ ...style }} />
    case "ic_construction_8":
        return <IconConstruction8 style={{ ...style }} />
    case "ic_construction_9":
        return <IconConstruction9 style={{ ...style }} />
    case "ic_construction_10":
        return <IconConstruction10 style={{ ...style }} />
    case "ic_construction_11":
        return <IconConstruction11 style={{ ...style }} />
    case "ic_construction_12":
        return <IconConstruction12 style={{ ...style }} />
    case "ic_construction_13":
        return <IconConstruction13 style={{ ...style }} />
    case "ic_construction_14":
        return <IconConstruction14 style={{ ...style }} />
    case "ic_construction_15":
        return <IconConstruction15 style={{ ...style }} />
    case "ic_construction_16":
        return <IconConstruction16 style={{ ...style }} />
    case "ic_construction_17":
        return <IconConstruction17 style={{ ...style }} />
    case "ic_costumer":
        return <Iconcostumer style={{ ...style }} />;
    case "ic_door":
        return <Icondoor style={{ ...style }} />;
    case "ic_drop_1":
        return <IconDrop1 style={{ ...style }} />;
    case "ic_drop_2":
        return <IconDrop2 style={{ ...style }} />;
    case "ic_drop_3":
        return <IconDrop3 style={{ ...style }} />;
    case "ic_home_1":
        return <IconHome1 style={{ ...style }} />;
    case "ic_home_2":
        return <IconHome2 style={{ ...style }} />;
    case "ic_home_3":
        return <IconHome3 style={{ ...style }} />;
    case "ic_home_4":
        return <IconHome4 style={{ ...style }} />;
    case "ic_lightbulb_1":
        return <IconLightBulb1 style={{ ...style }} />;
    case "ic_lightbulb_2":
        return <IconLightBulb2 style={{ ...style }} />;
    case "ic_lightbulb_3":
        return <IconLightBulb3 style={{ ...style }} />;
    case "ic_picture_1":
        return <IconPicture1 style={{ ...style }} />;
    case "ic_picture_2":
        return <IconPicture2 style={{ ...style }} />;
    case "ic_refresh":
        return <IconRefresh style={{ ...style }} />;
    case "ic_sync":
        return <IconSync style={{ ...style }} />;
    case "ic_task_1":
        return <IconTask1 style={{ ...style }} />;
    case "ic_task_2":
        return <IconTask2 style={{ ...style }} />;
    case "ic_done":
        return <Done24px style={{ ...style }} />;
    case "ic_done_all":
        return <DoneAll24px style={{ ...style }} />;
    case "ic_done_outline":
        return <DoneOutline24px style={{ ...style }} />;
    case "ic_donut_large":
        return <DonutLarge24px style={{ ...style }} />;
    case "ic_donut_small":
        return <DonutSmall24px style={{ ...style }} />;
    case "ic_drag_indicator":
        return <DragIndicator24px style={{ ...style }} />;
    case "ic_eject":
        return <Eject24px style={{ ...style }} />;
    case "ic_euro_symbol":
        return <EuroSymbol24px style={{ ...style }} />;
    case "ic_exit_to_app":
        return <ExitToApp24px style={{ ...style }} />;
    case "ic_explore":
        return <Explore24px style={{ ...style }} />;
    case "ic_explore_off":
        return <ExploreOff24px style={{ ...style }} />;
    case "ic_extension":
        return <Extension24px style={{ ...style }} />;
    case "ic_face":
        return <Face24px style={{ ...style }} />;
    case "ic_face_unlock":
        return <FaceUnlock24px style={{ ...style }} />;
    case "ic_favorite":
        return <Favorite24px style={{ ...style }} />;
    case "ic_favorite_border":
        return <FavoriteBorder24px style={{ ...style }} />;
    case "ic_find_replace":
        return <FindReplace24px style={{ ...style }} />;
    case "ic_fingerprint":
        return <FingerPrint24px style={{ ...style }} />;
    case "ic_flight_land":
        return <FlightLand24px style={{ ...style }} />;
    case "ic_flight_takeoff":
        return <FlightTakeOff24px style={{ ...style }} />;
    case "ic_flip_to_back":
        return <FlipToBack24px style={{ ...style }} />;
    case "ic_flip_to_front":
        return <FlipToFront24px style={{ ...style }} />;
    case "ic_g_translate":
        return <GTranslate24px style={{ ...style }} />;
    case "ic_gavel":
        return <Gavel24px style={{ ...style }} />;
    case "ic_grade":
        return <Grade24px style={{ ...style }} />;
    case "ic_group_work":
        return <GroupWork24px style={{ ...style }} />;
    case "ic_help":
        return <Help24px style={{ ...style }} />;
    case "ic_help_outline":
        return <HelpOutline24px style={{ ...style }} />;
    case "ic_highlight_off":
        return <HighLightOff24px style={{ ...style }} />;
    case "ic_history":
        return <History24px style={{ ...style }} />;
    case "ic_home":
        return <Home24px style={{ ...style }} />;
    case "ic_horizontal_split":
        return <HorizontalSplit24px style={{ ...style }} />;
    case "ic_http":
        return <Http24px style={{ ...style }} />;
    case "ic_https":
        return <Https24px style={{ ...style }} />;
    case "ic_important_devices":
        return <ImportantDevices24px style={{ ...style }} />;
    case "ic_info":
        return <Info24px style={{ ...style }} />;
    case "ic_info_outline":
        return <InfoOutline24px style={{ ...style }} />;
    case "ic_input":
        return <Input24px style={{ ...style }} />;
    case "ic_invert_colors":
        return <InvertColors24px style={{ ...style }} />;
    case "ic_label":
        return <Label24px style={{ ...style }} />;
    case "ic_label_off":
        return <LabelOff24px style={{ ...style }} />;
    case "ic_label_outline":
        return <LabelOutline24px style={{ ...style }} />;
    case "ic_language":
        return <Language24px style={{ ...style }} />;
    case "ic_launch":
        return <Launch24px style={{ ...style }} />;
    case "ic_lightbulb_outline":
        return <LightbulbOutline24px style={{ ...style }} />;
    case "ic_line_style":
        return <LineStyle24px style={{ ...style }} />;
    case "ic_line_weight":
        return <LineWeight24px style={{ ...style }} />;
    case "ic_list":
        return <List24px style={{ ...style }} />;
    case "ic_crop":
        return <IconCrop style={{ ...style }} />;
    case "ic_hot":
        return <IconHot style={{ ...style }} />;
    case "ic_phone":
        return <IconPhone style={{ ...style }} />;
    case "ic_smarthphone":
        return <IconSmartphone style={{ ...style }} />;
    case "ic_weather_1":
        return <IconWeather1 style={{ ...style }} />;
    case "ic_weather_2":
        return <IconWeather2 style={{ ...style }} />;
    case "ic_cooker":
        return <Cooker style={{ ...style }} />;
    case "ic_planet":
        return <IconmonstrNetworking6 style={{ ...style }} />;
    case "ic_atomic":
        return <IconmonstrNetworking7 style={{ ...style }} />;
    case "ic_alert_1":
        return <IconmonstrNew1 style={{ ...style }} />;
    case "ic_alert_2":
        return <IconmonstrNew2 style={{ ...style }} />;
    case "ic_virtual_tour":
        return <TourVirtual style={{ ...style }} />;
    case "ic_tips":
        return <Dicas style={{ ...style }} />;
    case "ic_digital_print":
        return <Digitalizaca style={{ ...style }} />;
    case "ic_pool_stairs":
        return <Escada style={{ ...style }} />;
    case "ic_tour":
        return <ExcursaoVirtual style={{ ...style }} />;
    case "ic_extinguisher":
        return <Extinto style={{ ...style }} />;
    case "ic_gas_oven":
        return <Fog style={{ ...style }} />;
    case "ic_pictures":
        return <Fotos style={{ ...style }} />;
    case "ic_air_conditioner_1":
        return <AirConditioner style={{ ...style }} />;
    case "ic_air_conditioner_2":
        return <ArCondicionado style={{ ...style }} />;
    case "ic_attention":
        return <Atencao style={{ ...style }} />;
    case "ic_security_camera_1":
        return <CameraSeguranca1 style={{ ...style }} />;
    case "ic_security_camera_2":
        return <CameraSeguranca2 style={{ ...style }} />;
    case "ic_truck":
        return <Caminha style={{ ...style }} />
    case "ic_eletric_grill":
        return <ChurrasqueiraEletrica style={{ ...style }} />;
    case "ic_video_play":
        return <Jog style={{ ...style }} />;
    case "ic_grill_1":
        return <Grill style={{ ...style }} />;
    case "ic_grill_2":
        return <Gril style={{ ...style }} />;
    case "ic_fireplace_1":
        return <Lareira style={{ ...style }} />;
    case "ic_fireplace_2":
        return <Lareir style={{ ...style }} />;
    case "ic_manual":
        return <Manual style={{ ...style }} />;
    case "ic_pool":
        return <Piscina style={{ ...style }} />;
    case "ic_playground":
        return <Playground style={{ ...style }} />;
    case "ic_goalkeeper_1":
        return <Porteiro style={{ ...style }} />;
    case "ic_goalkeeper_2":
        return <Porteir style={{ ...style }} />;
    case "ic_project":
        return <Projeto style={{ ...style }} />;
    case "ic_slider":
        return <Slider style={{ ...style }} />;
    case "ic_calendar":
        return <Calendar style={{ ...style }} />;
    default:
        return <IconOutros style={{ ...style }} />;
    }
};

export function iconForFolder(folder) {
    if (folder.icon && folder.icon.length > 0) {
        switch (folder.icon) {
        case "luz":
            return icon_luz;
        case "torneira":
            return icon_torneira;
        case "parede":
            return icon_parede;
        case "janela":
            return icon_janela;
        case "pincel":
            return icon_pincel;
        case "pingo":
            return icon_pingo;
        case "churras":
            return icon_churras;
        case "crack":
            return icon_crack;
        case "outros":
            return icon_outros;
        case "ic_done":
            return ic_done;
        case "ic_done_all":
            return ic_done_all;
        case "ic_done_outline":
            return ic_done_outline;
        case "ic_donut_large":
            return ic_donut_large;
        case "ic_donut_small":
            return ic_donut_small;
        case "ic_drag_indicator":
            return ic_drag_indicator;
        case "ic_eject":
            return ic_eject;
        case "ic_euro_symbol":
            return ic_euro_symbol;
        case "ic_exit_to_app":
            return ic_exit_to_app;
        case "ic_explore":
            return ic_explore;
        case "ic_explore_off":
            return ic_explore_off;
        case "ic_extension":
            return ic_extension;
        case "ic_face":
            return ic_face;
        case "ic_face_unlock":
            return ic_face_unlock;
        case "ic_favorite":
            return ic_favorite;
        case "ic_favorite_border":
            return ic_favorite_border;
        case "ic_find_replace":
            return ic_find_replace;
        case "ic_fingerprint":
            return ic_fingerprint;
        case "ic_flight_land":
            return ic_flight_land;
        case "ic_flight_takeoff":
            return ic_flight_takeoff;
        case "ic_flip_to_back":
            return ic_flip_to_back;
        case "ic_flip_to_front":
            return ic_flip_to_front;
        case "ic_g_translate":
            return ic_g_translate;
        case "ic_gavel":
            return ic_gavel;
        case "ic_grade":
            return ic_grade;
        case "ic_group_work":
            return ic_group_work;
        case "ic_help":
            return ic_help;
        case "ic_help_outline":
            return ic_help_outline;
        case "ic_highlight_off":
            return ic_highlight_off;
        case "ic_history":
            return ic_history;
        case "ic_home":
            return ic_home;
        case "ic_horizontal_split":
            return ic_horizontal_split;
        case "ic_http":
            return ic_http;
        case "ic_https":
            return ic_https;
        case "ic_important_devices":
            return ic_important_devices;
        case "ic_info":
            return ic_info;
        case "ic_info_outline":
            return ic_info_outline;
        case "ic_input":
            return ic_input;
        case "ic_invert_colors":
            return ic_invert_colors;
        case "ic_label":
            return ic_label;
        case "ic_label_off":
            return ic_label_off;
        case "ic_label_outline":
            return ic_label_outline;
        case "ic_language":
            return ic_language;
        case "ic_launch":
            return ic_launch;
        case "ic_lightbulb_outline":
            return ic_lightbulb_outline;
        case "ic_line_style":
            return ic_line_style;
        case "ic_line_weight":
            return ic_line_weight;
        case "ic_list":
            return ic_list;
        case "ic_calendar":
            return ic_calendar;
        case "ic_battery":
            return ic_battery;
        case "ic_book_1":
            return ic_book_1;
        case "ic_book_2":
            return ic_book_2;
        case "ic_book_3":
            return ic_book_3;
        case "ic_book_4":
            return ic_book_4;
        case "ic_book_5":
            return ic_book_5;
        case "ic_brick":
            return ic_brick;
        case "ic_building_1":
            return ic_building_1;
        case "ic_building_2":
            return ic_building_2;
        case "ic_building_3":
            return ic_building_3;
        case "ic_building_4":
            return ic_building_4;
        case "ic_building_5":
            return ic_building_5;
        case "ic_camera":
            return ic_camera;
        case "ic_connection":
            return ic_connection;
        case "ic_construction_1":
            return ic_construction_1;
        case "ic_construction_2":
            return ic_construction_2;
        case "ic_construction_3":
            return ic_construction_3;
        case "ic_construction_4":
            return ic_construction_4;
        case "ic_construction_5":
            return ic_construction_5;
        case "ic_construction_6":
            return ic_construction_6;
        case "ic_construction_7":
            return ic_construction_7;
        case "ic_construction_8":
            return ic_construction_8;
        case "ic_construction_9":
            return ic_construction_9;
        case "ic_construction_10":
            return ic_construction_10;
        case "ic_construction_11":
            return ic_construction_11;
        case "ic_construction_12":
            return ic_construction_12;
        case "ic_construction_13":
            return ic_construction_13;
        case "ic_construction_14":
            return ic_construction_14;
        case "ic_construction_15":
            return ic_construction_15;
        case "ic_construction_16":
            return ic_construction_16;
        case "ic_construction_17":
            return ic_construction_17;
        case "ic_costumer":
            return ic_costumer;
        case "ic_door":
            return ic_door;
        case "ic_drop_1":
            return ic_drop_1;
        case "ic_drop_2":
            return ic_drop_2;
        case "ic_drop_3":
            return ic_drop_3;
        case "ic_home_1":
            return ic_home_1;
        case "ic_home_2":
            return ic_home_2;
        case "ic_home_3":
            return ic_home_3;
        case "ic_home_4":
            return ic_home_4;
        case "ic_lightbulb_1":
            return ic_lightbulb_1;
        case "ic_lightbulb_2":
            return ic_lightbulb_2;
        case "ic_lightbulb_3":
            return ic_lightbulb_3;
        case "ic_picture_1":
            return ic_picture_1;
        case "ic_picture_2":
            return ic_picture_2;
        case "ic_refresh":
            return ic_refresh;
        case "ic_sync":
            return ic_sync;
        case "ic_task_1":
            return ic_task_1;
        case "ic_task_2":
            return ic_task_2;
        case "ic_weather_2":
            return icon_weather_2;
        case "ic_weather_1":
            return icon_weather_1;
        case "ic_phone":
            return phone;
        case "ic_smarthphone":
            return icon_smartphone
        case "ic_hot":
            return icon_hot
        case "ic_crop":
            return icon_crop
        case "ic_air_conditioner_1":
            return ic_air_conditioner_1;
        case "ic_air_conditioner_2":
            return ic_air_conditioner_2;
        case "ic_attention":
            return ic_attention;
        case "ic_security_camera_1":
            return ic_security_camera_1;
        case "ic_security_camera_2":
            return ic_security_camera_2;
        case "ic_truck":
            return ic_truck;
        case "ic_eletric_grill":
            return ic_eletric_grill;
        case "ic_cooker":
            return ic_cooker;
        case "ic_tips":
            return ic_tips;
        case "ic_digital_print":
            return ic_digital_print;
        case "ic_pool_stairs":
            return ic_pool_stairs;
        case "ic_tour":
            return ic_tour;
        case "ic_extinguisher":
            return ic_extinguisher;
        case "ic_gas_oven":
            return ic_gas_oven;
        case "ic_pictures":
            return ic_pictures;
        case "ic_grill_1":
            return ic_grill_1;
        case "ic_grill_2":
            return ic_grill_2;
        case "ic_planet":
            return ic_planet;
        case "ic_atomic":
            return ic_atomic;
        case "ic_alert_1":
            return ic_alert_1;
        case "ic_alert_2":
            return ic_alert_2;
        case "ic_fireplace_1":
            return ic_fireplace_1;
        case "ic_fireplace_2":
            return ic_fireplace_2;
        case "ic_manual":
            return ic_manual;
        case "ic_pool":
            return ic_pool;
        case "ic_playground":
            return ic_playground;
        case "ic_goalkeeper_1":
            return ic_goalkeeper_1;
        case "ic_goalkeeper_2":
            return ic_goalkeeper_2;
        case "ic_project":
            return ic_project;
        case "ic_slider":
            return ic_slider;
        case "ic_virtual_tour":
            return ic_virtual_tour;
        case "ic_video_play":
            return ic_video_play;
        default:
            return Folder;
        }
    } else {
        return Folder;
    }
}

export function onErrorImage(e, style) {
    let styleImage;
    if (style) styleImage = style;
    else styleImage = "height: 100%;";

    e.target.src = noPhoto;
    e.target.style = styleImage;
}

export function formatDateHour(date) {
    return moment(date).format("HH:mm");
}

export function formatDate(date) {
    return moment(date).format("D/MM/YYYY, HH:mm");
}

export function clearDigitsSpecialChars(value) {
    if (value) {
        const anyNonDigitRegExp = /[^0-9*]/g
        return value.toString().replace(anyNonDigitRegExp, '')
    }

    return ""
}

export function formatPhone(value) {
    let rawValue = clearDigitsSpecialChars(value)

    let front = 4
    if (rawValue.length > 10) {
        front = 5
    }

    let newValue = ''
    if (rawValue.length > 0) {
        newValue += '(' + rawValue.substr(0, 2)
    }

    if (rawValue.length > 2) {
        newValue += ') ' + rawValue.substr(2, front)
    }

    if (rawValue.length >= 7) {
        newValue += '-' + rawValue.substr(front + 2, 4)
    }

    return newValue
}
export const randomValue = () => new Date().getMilliseconds() * Math.round(Math.random() * 1000)


export const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    console.log(result, hex)
    if (!result) return hexToRgb('#3279CD')
    return [parseInt(result[1], 16), parseInt(result[2], 16),parseInt(result[3], 16)]
}
