import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Box, Divider } from '@material-ui/core';
import axios from 'axios';
import { CenterSpinnerButton } from '~/components/Spinner';
import Input from '~/components/Input/Input';
import api from '~/services/api';
import { Signature } from '~/components/Signature';
import Label from '~/components/Label';
import Checkbox from '~/components/Checkbox';
import InputImage from '~/components/Input/InputImage';
import FBButton from '~/components/buttons/FBButton';
import { ScrollBarStyle } from '../Custom';
import "./Inspection.sass";
import BackIndex from '~/components/Navbar/BackIndex';

export const StyledLink = styled.a`
    color: #3279CD;
`;
export const ImageContainer = styled.div`
    background-color: #f8f8f8;
`;

const DivHtml = styled.div`
    background-color: white;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    height: 250px;
    overflow-x: auto;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #808080;
    margin-bottom: 10px;

    p {
        margin: 0px;
        padding: 0px;
    }
    h1, h2, h3, h4, h5, h6 {
        margin: 0px;
        padding: 0px;
    }

    ${ScrollBarStyle}
`

export default function InspectionSign() {
    const { guid } = useParams();

    const [data, setData] = useState(null);
    const [completed, setCompleted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [termApprove, setTermApprove] = useState(false);
    const [reportApprove, setReportApprove] = useState(false);
    const [info, setInfo] = useState({});

    const { token } = useParams();

    useEffect(() => {
        async function getSignaturedata() {
            try {
                if (!guid) {
                    return;
                }

                const response = await api.get(`v1/inspection/sign/${guid}`);
                if (response.status !== 200) {
                    toast.error(response.data.message);
                } else if (response.data) {
                    setData(response.data);
                    setLoading(false);
                }
            } catch (error) {
                if (error.response?.status === 400) {
                    setCompleted(true)
                }
                setLoading(false);
                toast.error(error.response?.data?.message || 'Não foi possível localizar a vistoria');
            }
        }

        getSignaturedata();
    }, [guid]);

    async function handleImageChange(event) {
        if (!event?.target?.files) {
            return
        }

        const [selfie] = Array.from(event.target.files);

        if (!selfie) {
            return
        }

        setInfo(info => ({ ...info, selfie: { name: selfie.name, loading: true } }));

        const result = await api.get(`/v1/inspection/sign/${guid}/url?extension=${selfie.type}`);
        setInfo(info => ({ ...info, selfie: { ...info.selfie, ...result.data } }));
        const options = { headers: { 'Content-Type': selfie.type } };

        await axios.create().put(result.data.upload, selfie, options);
        setInfo(info => ({ ...info, selfie: { ...info.selfie, loading: false } }));
    }

    const drawingRef = useRef(null);

    function submit(approved, ev) {
        ev.preventDefault()
        ev.stopPropagation()

        if (!termApprove || !reportApprove) {
            return toast.error('É necessário aprovar o termo da vistoria e o relatório.');
        }

        if (drawingRef.current.lines.length === 0) {
            return toast.error('A assinatura não pode estar em branco!');
        }

        if (data.approval.requires_selfie && (!info.selfie || info.selfie.loading === true)) {
            return toast.error('A selfie com o documento é obrigatória');
        }

        if (!info.document) {
            return toast.error('O documento é obrigatório');
        }

        drawingRef.current.canvas.drawing.toBlob(async data => {
            setLoading(true);
            const fileType = data.type;
            // Blob-to-file
            data.lastModifiedDate = new Date();
            data.name = `signature_${Number(new Date())}.png`;
            try {
                const result = await api.get(`/v1/inspection/sign/${guid}/url?extension=${fileType}`);

                const options = { headers: { 'Content-Type': fileType } };
                await axios.create().put(result.data.upload, data, options);

                const resultSign = await api.post(`/v1/inspection/sign/${guid}`, {
                    document: info.document,
                    selfie: info.selfie?.fileKey,
                    signature: result.data.fileKey
                }
                );
                if (resultSign.status === 200) {
                    toast.success(approved ? "Vistoria aprovada com sucesso " : "Vistoria reprovada com sucesso")
                    setCompleted(true)
                }

                setLoading(false);
            } catch (err) {
                console.error(err);
                toast.error(err?.response?.data?.message || 'Não foi possível fazer o upload da imagem!');
                setLoading(false);
            }
        });

    }

    if (completed) {
        return (
            <div>
                <Container className='container-fluid-folder' fluid={true}>
                    <Container className='container-folder-page'>
                        <BackIndex link={`/${token}/`} />
                        <br />
                        <h1> Vistoria aprovada! </h1>
                    </Container>
                </Container>
            </div>
        )
    }
    return (
        <div>
            <Container className='container-fluid-folder' fluid={true}>
                <Container className='container-folder-page'>
                    <br />
                    <h1 style={{ textAlign: "center" }}> Aprovar vistoria </h1>
                    <Box display='inline-flex' flexDirection='column' style={{ width: '100%' }}>

                        {loading && (
                            <CenterSpinnerButton color={'#000'} />
                        )}

                        {!loading && data && (
                            <>
                                <Label text='Relatório da vistoria' style={{ margin: "0px" }} />
                                <StyledLink
                                    target='_blank'
                                    rel='noopener noreferrer' href={data?.reportUrl}
                                >
                                    Relatorio.pdf
                                </StyledLink>

                                <Checkbox
                                    checked={reportApprove}
                                    onChange={() => setReportApprove(v => !v)}
                                    label={'Eu li e aceito o relatório da vistoria!'}
                                />

                                <Divider style={{ width: "100%", margin: "10px 0px 10px -10px", backgroundColor: "rgb(0 0 0)" }} />

                                {data.termType === "PDF" ?
                                    data.termUrl && (
                                        <>
                                            <Label text='Termo de aprovação' style={{ margin: "0px" }} />

                                            <StyledLink
                                                target='_blank'
                                                rel='noopener noreferrer' href={data && data.termUrl}
                                            >
                                                termo-de-aprovacao.pdf
                                            </StyledLink>

                                            <Checkbox
                                                checked={termApprove}
                                                onChange={() => setTermApprove(v => !v)}
                                                label={'Eu li e aceito o termo de aprovação!'}
                                            />
                                        </>
                                    )
                                    :
                                    data.termHtml && (
                                        <>
                                            <DivHtml dangerouslySetInnerHTML={{ __html: data.termHtml }} />
                                            <Checkbox
                                                checked={termApprove}
                                                onChange={() => setTermApprove(v => !v)}
                                                label={'Eu li e aceito o termo de aprovação!'}
                                            />
                                        </>
                                    )
                                }

                                <Divider style={{ width: "100%", margin: "10px 0px 10px -10px", backgroundColor: "rgb(0 0 0)" }} />

                                <Label text='Insira o seu cpf' />
                                <Input value={info.document || null} maxLength={50} autoFocus placeholder='xxx.xxx.xxx-xx' onChange={ev => setInfo({ ...info, document: ev.target.value })} />

                                {data.approval.requires_selfie && (
                                    <>
                                        <Label text='Tire uma selfie com o seu documento' />
                                        <ImageContainer>
                                            <InputImage
                                                id='selfie'
                                                labelAdd="sua foto"
                                                result={info?.selfie?.result}
                                                loading={info.selfie?.loading}
                                                idLabel={'dropContainer'}
                                                handleImageChange={handleImageChange}
                                                onDrop={handleImageChange}
                                                accept='image/jpg,image/png'
                                                onDragOver={event => event.preventDefault()}
                                            >
                                                {info.selfie?.loading === false && <img src={info.selfie?.result} alt='selfie' />}
                                                {info.selfie?.loading === true && 'Enviando...'}
                                            </InputImage>
                                        </ImageContainer>
                                    </>
                                )}

                                <Signature ref={drawingRef} text='Assine a aprovação da vistoria' widthCanva="100%" />

                                <Box display="flex">
                                    <FBButton style={{ width: 100 }} primary className='mr-1' variant="outlined"
                                        onClick={() => drawingRef.current.undo()}
                                    >
                                        Desfazer
                                    </FBButton>
                                    <FBButton style={{ width: 100, marginLeft: 10, marginBottom: 20 }} primary className='mr-1' variant="outlined"
                                        onClick={() => drawingRef.current.clear()}
                                    >
                                        Limpar
                                    </FBButton>
                                </Box>

                                <Box display="flex" style={{ marginBottom: 80 }}>
                                    <FBButton disabled={data?.approval?.requires_selfie && !(info.selfie && info.selfie.loading === false)} style={{ marginRight: 10 }} primary onClick={(ev) => submit(true, ev)}> Aprovar vistoria </FBButton>
                                </Box>
                            </>
                        )}
                    </Box>
                </Container>
            </Container>
        </div>
    );
}
