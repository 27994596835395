import mixpanel from 'mixpanel-browser'
import api from './api'

export function initTracking() {
    const key = process.env.REACT_APP_MIXPANEL_KEY
    mixpanel.init(key || "DEV_TEST", { debug: !key })
}

export function setTrackingCompany(superInfo) {
    try {
        mixpanel.register(superInfo)
        mixpanel.add_group("company", superInfo.companyName)
    } catch (err) {
        console.error(err)
    }
}

export async function fetchCompanyIfEmpty(code) {
    try {
        if (!mixpanel.get_property("companyName")) {
            return await fetchCompany(code)
        }
    } catch (err) {
        console.error(err)
    }
}

export async function fetchCompany(code) {
    try {
        if (code !== "inspection" && code !== "assistencia") {
            const response = await api.get(`v1/units/${code}`);
            const [unit] = response.data

            const {company_id, building} = unit
            if (building.company) {
                const { company } = building
                setTrackingCompany({
                    companyName: company.name,
                    state: company.state,
                    city: company.city,
                    companyId: company.id,
                    userToken: code
                });

            } else {
                const company = await api.get(`v1/companies/${company_id}`);
                    setTrackingCompany({
                    companyName: company.data.name,
                    userToken: code,
                    state: company.data.state,
                    city: company.data.city,
                    companyId: company_id,
                })
            }

        }
    } catch (err) {
        console.error(err)
    }

}

export function track(ev, props) {
    try {
        mixpanel.track(ev, props)
    } catch (err) {
        console.error(err)
    }
}
