import React, { useState, useEffect, Fragment } from "react";
import "./Sac.sass";
import { Container, Row, Col } from "react-bootstrap";
import { CenterSpinnerButton } from "~/components/Spinner";
import { formatDate } from "~/services/utils";
import { useParams } from "react-router-dom";
import { Tooltip, Typography } from "@material-ui/core";
import FBButton from "../Button/FBButton";

export const textForStatus = (status) => {
    switch (status) {
    case 0:
        return "Pendente Resposta";
    case 1:
        return "Aguardando construtora";
    case 2:
        return "Agendado";
    case 3:
        return "Concluído";
    case 4:
        return "Finalizado";
    case 5:
        return "Cancelado";
    case 6:
        return "Aguardando Assinatura";
    case 7:
        return "Assinado"
    case 8:
        return "Agenda Liberada"
    default:
        return ""
    }
};
export const colorForStatus = (status) => {
    switch (status) {
    case 0:
        return "magenta";
    case 1:
        return "orange";
    case 2:
        return "blue";
    case 4:
        return "green";
    case 5:
        return "gray";
    case 6:
        return "purple";
    case 7:
        return "blue";
    case 8:
        return "hot-pink"
    default:
        return "";
    }
};

export default ({ push, setGuidConversation, setIdConversation, warningOpeningSac }) => {
    const [loading, setLoading] = useState(true);
    const [device, setDevice] = useState("");
    const [services, setServices] = useState("");
    const { token } = useParams();

    useEffect(() => {
        const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i;
        if (navigator.userAgent.match(regex) !== null) {
            setDevice(navigator.userAgent.match(regex)[0]);
        }
    }, []);

    useEffect(() => {
        // async function getServices() {
        //     try {
        //         const response = a;

        //         setServices(response.data);
        //         setLoading(false);
        //     } catch (e) {
        //         setLoading(false);
        //         setServices([]);
        //         toast.error("Não foi possível localizar os serviços.");
        //     }
        // }
        // getServices();
    }, [token]);

    function pushConversation(guid, id) {
        setGuidConversation(guid);
        setIdConversation(id)
        push("conversation-screen");
    }


    const clientRequest = () => {
        setGuidConversation(null);
        if(warningOpeningSac){
            push("warning-opening-sac");
        }else{
            push("form-client");
        }
    }

    return (
        <Container className="container-tickets">
            <h2> <b>Assistência Técnica</b></h2>
            <br />
            {loading ? (
                <CenterSpinnerButton color={"#000"} />
            ) : (
                <Fragment>
                    <div>

                        <FBButton
                            style={{ maxWidth: "150px" }}
                            color='primary'
                            onClick={clientRequest}
                        >
                          Nova solicitação
                        </FBButton>

                    </div>
                    <h4 style={{ margin: "20px 0px 10px 0px" }}>Solicitações em aberto</h4>

                    {services && services.length === 0 ?
                        (
                            <p>Sem solicitações.</p>
                        ) :
                        <div className="box-tickets">
                            <ul>
                                {device === "" ? (
                                    <Row>
                                        <Col lg={3} md={3}>
                                            <h6>Situação</h6>
                                        </Col>
                                        <Col lg={2} md={2}>
                                            <h6>Solicitação</h6>
                                        </Col>

                                        <Col lg={3} md={3}>
                                            <h6>Mensagem Inicial</h6>
                                        </Col>
                                        <Col lg={2} md={2}>
                                            <h6>Abertura</h6>
                                        </Col>
                                    </Row>
                                ) : null}
                                {services.map((service, i) => {
                                    const {
                                        status,
                                        created_at,
                                        guid,
                                        first_message,
                                        id,
                                    } = service;
                                    return (
                                        <li key={i} onClick={() => pushConversation(guid, id)}>
                                            <Row className="service-row" style={{ position: "relative" }}>
                                                <Col lg={3} md={3} sm={12}>
                                                    <p
                                                        style={{ backgroundColor: service.custom_status?.hex, ...(device ? { width:  "100%", textAlign: "center" }: {})}}
                                                        className={`sac-status status-${colorForStatus(status)}`}
                                                    >
                                                        {service.custom_status?.label || textForStatus(status)}
                                                    </p>
                                                </Col>
                                                {!device && <Col className="id" lg={2} md={2}>
                                                    <p>{id}</p>
                                                </Col>}

                                                <Col className="first-message" lg={3} md={3} sm={12}>
                                                    <Tooltip title={first_message}>
                                                        <p>{first_message}</p>
                                                    </Tooltip>
                                                </Col>
                                                <Col lg={2} md={2} sm={12}>
                                                    <small style={device ? { marginTop: 10, width: "100%", display: "flex", justifyContent: "space-between" }:{}}>
                                                        {device && <b>{id}</b>}
                                                        {formatDate(created_at)}
                                                    </small>
                                                </Col>
                                            </Row>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    }
                </Fragment>
            )}
        </Container>
    );
};
