import React, { useEffect, useMemo, useState } from "react";
import "./Login.sass";
import Input from "~/components/Input/Input";
import { toast } from "react-toastify";
import { Container, Col, Row } from "react-bootstrap";
import Logo from "~/assets/imgs/common/logo.svg";
import LogoBlack from "~/assets/imgs/common/logo-black.png";
import { useHistory } from "react-router-dom";
import config from "~/config";
import FBButton from "~/components/Button/FBButton";
import styled from 'styled-components'
import { useLocation } from "react-router-dom";
import api from "~/services/api";

const StyleRow = styled(Row)`
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
`

export default function Home() {
    const { search } = useLocation()
    const authenticated = useMemo(() => {
        return search && new URLSearchParams(search).get("auth_unit")
    }, [search])
    const [code, setCode] = useState(() => authenticated || "");
    useEffect(() => {
        console.log(authenticated)
        if (authenticated) {
            setCode(authenticated || "")
        }
    }, [authenticated])
    const [password, setPassword] = useState("");
    const history = useHistory();

    function handleChange(event) {
        if (event.target.name === "password") {
            setPassword(event.target.value.toUpperCase());
        } else {
            setCode(event.target.value.toUpperCase());
        }
    }

    function signin() {
        if (code.length > 4) {
            if (password) {
                api.get(`v1/units/${code}`, { headers: { 'x-pwd': password }})
                    .then(() => {
                        history.push(`/${code}`);
                    }).catch(err => {
                        const { response } = err

                        if (response?.status === 401 && response?.headers?.['x-invalid-password'] === 'true') {
                            toast.error("Senha inválida")
                        } else {
                            toast.error(response.message || "Senha ou unidade inválida")
                        }

                    });
            } else {
                history.push(`/${code}`);
            }
        } else {
            toast.error("Informe um código para entrar.");
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") signin();
    };

    if (window.location.href.indexOf("#") !== -1) {
        let splitUrl = window.location.href.split("/")

        let joinedUrl = splitUrl[0] + '//' + splitUrl[2] + '/' + splitUrl[4]

        window.location.replace(joinedUrl)
    }

    return (
        <div>
            <Container className="container-fluid-login" fluid={true}>
                <Container className="main-container-login">
                    <div>
                        <img alt="Logo" src={Logo} />
                    </div>
                    <Col
                        style={{
                            marginBottom: "15vh",
                            marginTop: "auto",
                            flexGrow: "0",
                        }}
                    >
                        <StyleRow>
                            <h2
                                style={{
                                    color: "white",
                                    fontSize: "26px",
                                    lineHeight: "40px",
                                    fontWeight: "300",
                                }}
                            >
                Informe seu código
                                <br /> para acessar:
                            </h2>
                        </StyleRow>
                        <StyleRow>
                            <Input
                                placeholder="Ex: A13VD5"
                                value={code}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                            />
                        </StyleRow>
                        {authenticated &&
                            <StyleRow>
                                <Input
                                    labelClass="c-white"
                                    label="Senha"
                                    name="password"
                                    autoFocus={Boolean(code)}
                                    style={{ marginTop: 3 }}
                                    placeholder="Ex: 3219AD"
                                    value={password}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </StyleRow>
                        }
                        <StyleRow>
                            <FBButton
                                style={{
                                    maxWidth: "335px",
                                    marginLeft: "10px",
                                }}
                                color='primary'
                                onClick={signin}
                            >
                Entrar
                            </FBButton>
                        </StyleRow>
                    </Col>

                    <div className="container-footer">
                        <img src={LogoBlack} alt={config.title} />
                    </div>
                </Container>
            </Container>
        </div>
    );
}
