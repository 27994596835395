import React from 'react';
import FBButton from "./FBButton";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import useTheme from "@material-ui/core/styles/useTheme";

function FBMainAction(props, ref) {
    const theme = useTheme();

    return props.type === 'fab' ?
        <Fab color="primary" aria-label="add" style={{
            position: 'fixed',
            top: '80%',
            left: 0,
            marginLeft: theme.spacing(2),
            zIndex: 1
        }}
            {...props}
        >
            <AddIcon />
        </Fab> :
    <FBButton
        {...props}
        primary
        ref={ref}
        style={{
            width: "auto",
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            textTransform: "none",
            fontWeight: '500',
            ...props.style
        }}
    >{props.children}</FBButton>;
}

export default React.forwardRef(FBMainAction)