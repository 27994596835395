import React from "react";

import "./Checkbox.sass";

export default ({ label, children, ...props }) => {
  return (
    <label className="label-checkbox">
      <input {...props} className="check-box-primary" type="checkbox" />
      <span className="label-text" />
      {label}
    </label>
  );
};
