const formatted = (value, format) => {
    if (!value) { return value }

    value = replaceNumbers(value);
    let newValue = "";

    let i = 0;
    for (const char of format) {
        if (i >= value.length) {
            break;
        }
        if (char === "x") {
            newValue += value[i];
            i += 1;
        } else {
            newValue += char;
        }
    }

    return newValue;
};

export function clearDigitsSpecialChars(value) {
    if (value) {
        const anyNonDigitRegExp = /[^0-9]/g
        return value.toString().replace(anyNonDigitRegExp, '')
    }

    return ""
}

export const replaceNumbers = (value) => {
    return value.replace(/[^0-9]/g, "");
};

export const formatDocument = (value) => {
    const newValue = replaceNumbers(value);
    const format = newValue.length <= 11 ? "xxx.xxx.xxx-xx" : "xx.xxx.xxx/xxxx-xx";
    return formatted(newValue, format);
};

export const handleChangeFormatDocument = (e) => {
    let { value } = e.target;
    e.target.value = formatDocument(value);
};

const formatPhone = (value) => {
    const newValue = replaceNumbers(value);
    const format = newValue.length <= 10 ? "(xx) xxxx-xxxx" : "(xx) xxxxx-xxxx";
    return formatted(newValue, format);
};

export const handleChangeFormatPhone = (e) => {
    let { value } = e.target;
    e.target.value = formatPhone(value);
};

export const validateDocument = (value) => {
    value = replaceNumbers(value);
    if (value.length === 11) {
        let sum;
        let result;
        sum = 0;
        if (
            value === "00000000000" ||
      value === "11111111111" ||
      value === "22222222222" ||
      value === "33333333333" ||
      value === "44444444444" ||
      value === "55555555555" ||
      value === "66666666666" ||
      value === "77777777777" ||
      value === "88888888888" ||
      value === "99999999999"
        ) return false;

        for (let i = 1; i <= 9; i++)
            sum = sum + parseInt(value.substring(i - 1, i)) * (11 - i);
        result = (sum * 10) % 11;

        if (result === 10 || result === 11) result = 0;
        if (result !== parseInt(value.substring(9, 10))) return false;

        sum = 0;
        for (let i = 1; i <= 10; i++)
            sum = sum + parseInt(value.substring(i - 1, i)) * (12 - i);
        result = (sum * 10) % 11;

        if (result === 10 || result === 11) result = 0;
        return result === parseInt(value.substring(10, 11));
    } else {
        let cnpj = value.replace(/[^\d]+/g, "");

        if (cnpj === "") return false;

        if (cnpj.length !== 14) return false;

        // Elimina CNPJs invalidos conhecidos
        if (
            cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999"
        )
            return false;

        // Valida DVs
        let tamanho = cnpj.length - 2;
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado !== parseInt(digitos.charAt(0))) return false;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        return resultado === parseInt(digitos.charAt(1));
    }
};

export const validatePhone = (value) => {
    return replaceNumbers(value).length >= 10;
};

export const validateEmail = (value) => {
    const user = value.substring(0, value.indexOf("@"));
    const domain = value.substring(value.indexOf("@") + 1, value.length);

    if ((user.length >= 1) &&
    (domain.length >= 3) &&
    (user.search("@") === -1) &&
    (domain.search("@") === -1) &&
    (user.search(" ") === -1) &&
    (domain.search(" ") === -1) &&
    (domain.search(".") !== -1) &&
    (domain.indexOf(".") >= 1) &&
    (domain.lastIndexOf(".") < domain.length - 1)) {
        return true
    }

    return false

}