import React, { useState, useEffect } from "react";
import "./Sac.sass";
import { toast } from "react-toastify";
import history from "~/services/history";
import api from "~/services/api";
import { Box, Typography } from "@material-ui/core";
import FBButton from "../Button/FBButton";
import { FBMuiAutocomplete } from "../Input/FBAutocomplete";

const listIdetify = [
    { label: "Proprietário do Imóvel", value: "owner" },
    { label: "Inquilino do imóvel", value: "tenant" },
    { label: "Síndico", value: "manager" },
]

export default ({ guid_conversation, push, register, handleSubmit, listBuilding, getValues }) => {

    const [listUnit, setListUnit] = useState([]);

    function onSubmit() {
        push("form-client")
    }

    useEffect(() => {
        if (guid_conversation) {
            push("conversation-screen")
        }

    }, [guid_conversation]);

    useEffect(() => {
        async function getUnitDetails() {
            try {

                const guidBuilding = getValues("guidBuilding")
                if (!guidBuilding) { return }

                const { data } = await api.get(`/v1/units/${guidBuilding}/list_unit`);

                if (data.units.length) {
                    setListUnit(data.units.map(v => ({ value: v.guid, label: v.name })))
                } else {
                    toast.error("Não foi possível carregar as unidades, favor tentar novamente mais tarde");
                    history.push("/");
                }

            } catch (e) {
                toast.error("Erro no link, favor tentar novamente mais tarde");
            }

        }
        getUnitDetails();

    }, [getValues("guidBuilding")]);

    return <div style={{ padding: "10px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={1}>
                <Box mt={2}>
                    <Typography style={{ display: "flex" }} variant="caption">Selecione seu empreendimento</Typography>
                    <FBMuiAutocomplete
                        disabled={!listBuilding.length}
                        style={{ width: "100%" }}
                        {...register("guidBuilding", { required: true })}
                        name="guidBuilding"
                        getOptionId={p => p?.value}
                        getOptionLabel={p => p?.label}
                        options={listBuilding}
                    />
                </Box>

                <Box mt={2}>
                    <Typography style={{ display: "flex" }} variant="caption">Selecione sua unidade</Typography>
                    <FBMuiAutocomplete
                        disabled={!listUnit.length}
                        style={{ width: "100%" }}
                        {...register("guidUnit", { required: true })}
                        name="guidUnit"
                        getOptionId={p => p?.value}
                        getOptionLabel={p => p?.label}
                        options={listUnit}
                    />
                </Box>

                <Box mt={2}>
                    <Typography style={{ display: "flex" }} variant="caption">Identifique-se</Typography>
                    <FBMuiAutocomplete
                        disabled={!listUnit.length}
                        style={{ width: "100%" }}
                        {...register("identify", { required: true })}
                        name="identify"
                        getOptionId={p => p?.value}
                        getOptionLabel={p => p?.label}
                        options={listIdetify}
                    />
                </Box>
            </Box>

            <div style={{ display: "flex", justifyContent: "center" }}>
                <FBButton
                    style={{ width: "100px" }}
                    color='primary'
                    type="submit"
                >
                    Próximo
                </FBButton>
            </div>
        </form>
    </div>
}
