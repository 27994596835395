import React from "react";

import { Spinner } from "react-bootstrap";
import styled from "styled-components";

const Div = styled.div`
  display: flex;
  color: ${(props) => props.color};
  padding: 30px;
  justify-content: center !important;
  align-items: center !important;
`;

export function CenterSpinnerButton({ color }) {
  return (
    <Div color={color ? color : "#fff"}>
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    </Div>
  );
}
