import React from "react";

import GlobalStyle from "./styles/global";
import "./styles/icons-guide.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Switch } from "react-router-dom";
// import "video-react/dist/video-react.css";

import { ToastContainer, Slide } from "react-toastify";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./store";
import Routes from "./routes";
import history from "./services/history";
import { initTracking } from "./services/track";

import "~/pages/Home/Home.sass"
import { StyleSheetManager } from "styled-components";
import theme from "./contexts/theme";
import { createTheme, ThemeProvider } from "@material-ui/core";
import {CustomThemeContext} from "./contexts/custom-theme";
import {hexToRgb} from "./services/utils";
import useEvent from "./services/use-event";

initTracking()

function App() {
    const [themeValue, setThemeValue] = React.useState(() => theme)
    const customThemeValue = React.useMemo(() => ({
        setTheme: (customTheme) => { 
            if (customTheme?.color) {
                const rgb = hexToRgb(customTheme.color)
                const t = createTheme(theme, {
                    palette: { primary: {
                        main: `rgba(${rgb.join(",")}, 0.95)`,
                        light: `rgba(${rgb.join(",")}, 0.60)`,
                        dark: customTheme.color
                    }}})

                setThemeValue(t)
            } else {
                setThemeValue(theme)
            }
        },
        reset: () => setThemeValue(theme),
        isOriginal: themeValue === theme
    }), [themeValue])

    useEvent("custom-theme", (props) => {
        customThemeValue.setTheme({ color: props.detail.color })
    }, [customThemeValue])

    const mainColor = themeValue.palette.primary.dark
    const themeColor = hexToRgb(mainColor).join(",")
    return (
        <CustomThemeContext.Provider value={customThemeValue}>
            <ThemeProvider theme={themeValue}>
                <StyleSheetManager disableCSSOMInjection={true}>
                    <Provider store={store}>
                        <Router history={history}>
                            <Switch>
                                <Routes />
                            </Switch>
                            <GlobalStyle primary={customThemeValue.isOriginal ? hexToRgb(themeValue.palette.primary.main).join(","): themeColor} theme-color={themeColor}  />
                            <ToastContainer autoClose={3000} draggable transition={Slide} />
                        </Router>
                    </Provider>
                </StyleSheetManager>
            </ThemeProvider>
        </CustomThemeContext.Provider>
    );
}

export default App;
