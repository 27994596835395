import React, { Fragment } from "react";
import Label from "../Label";
import styles from "./select.module.css";

function Select({ label, line, children, ...props }) {
  return label ? (
    <Fragment>
      <Label text={label} />
      <select
        className={`${styles.select} ${line ? styles.line : null}`}
        {...props}
      >
        {children}
      </select>
    </Fragment>
  ) : (
    <select
      className={`${styles.select} ${line ? styles.line : null}`}
      {...props}
    >
      {children}
    </select>
  );
}

Select.Option = ({ children, ...props }) => (
  <option {...props}>{children}</option>
);

export default Select;
