import React, { useState, useEffect, useRef } from "react";
import "./Folder.sass";
import { toast } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "~/components/Navbar";
import Select from "~/components/Select/";
import Input from "~/components/Input/Input";
import { CenterSpinnerButton } from "~/components/Spinner";
import ButtonPrimary from "~/components/Button/ButtonPrimary";
import ButtonSecondary from "~/components/Button/ButtonSecondary";
import Label from "~/components/Label";
import api from "~/services/api";
import { track, fetchCompany } from "~/services/track";
import history from "~/services/history";
import { useParams } from "react-router-dom";
import { Tune } from "@material-ui/icons";
import { renderIconSvg } from "~/services/utils";
import { ImageList, Typography } from "@material-ui/core";
import { ClickableAttachment } from "~/components/Sac/Attachment";
import styled from 'styled-components';
import {usePrimaryColor} from "~/contexts/custom-theme";

const Grid = styled(ImageList)`

    img, video {
        max-width: 100%;
        height: 100px !important;
    }

    > div {
        border-radius: 6px;
        min-width: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footer-card {
        border-radius: 6px;
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .footer-card a {
        margin-top: 5px; 
        color: #000;
        font-size: 12px;
        font-weight: 600;
        word-wrap: break-word;
        text-align: center;
    }
`

export default function Folder() {
    const { token, guid } = useParams();
    const ref_drop_down = useRef();
    const [index_drop_down, setIndexDp] = useState("");
    const [second_page, setSecondPage] = useState(false);
    const [open_filter, setOpenFilter] = useState(false);

    //data and loading
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(true);

    //filter
    const ref_filter = useRef();
    const [filter, setFilter] = useState({
        type: "",
        name: "",
        tag: "",
    });

    useEffect(() => {
        async function getFolders() {
            try {
                const response = await api.get(
          `v1/units/${token}/folders/${guid}`
                );
                setLoading(false);
                setData(response.data);
                if (response.data.parent_folder_guid) {
                    setSecondPage(true);
                } else {
                    setSecondPage(false);
                }
                fetchCompany(token)
            } catch (e) {
                setLoading(false);
                toast.error("Não foi possível localizar as pastas");
            }
        }

        getFolders();
    }, [guid, token]);

    //track mouse actions
    useEffect(() => {
        document.addEventListener("mousedown", handleClickFilter);
        return () => {
            document.removeEventListener("mousedown", handleClickFilter);
        };
    }, []);

    function handleClickFilter(e) {
    // inside click
        if (document.getElementsByClassName("filter-side-bar").length !== 0) {
            if (ref_filter.current.contains(e.target)) {
                return;
            }
            // outside click
            setOpenFilter(false);
        }
    }

    async function fetchData(event) {
        event.preventDefault();
        setLoading(true);

        const filterKeys = Object.keys(filter);

        let query = `?unit_token=${token}`;

        filterKeys.map((key) => {
            const value = filter[key];
            if (value !== "") {
                if (query === "") {
                    return (query += `?${key}=${value}`);
                } else {
                    return (query += `&${key}=${value}`);
                }
            } else {
                return "";
            }
        });

        try {
            const response = await api.get(`v1/folders/${guid}${query}`);
            setData(response.data);
            setSecondPage(false);
            setOpenFilter(false);
            setFilter({ type: "", name: "", tag: ""});
            setLoading(false);
        } catch (e) {
            setLoading(false);
            toast.error("Não foi possível localizar os arquivos");
        }
    }

    function openDropDown(i) {
        setIndexDp(index_drop_down === i ? "" : i);
    }

    function OpenFilter() {
        const filterState = !open_filter
        track(filterState ? "FILTER_OPEN" : "FILTER_CLOSE")
        setOpenFilter(!open_filter);
        setFilter({ type: "",name: "",tag: ""});
    }

    function openFolder(folder, index) {
        setLoading(true);
        if (folder.child_folders && folder.child_folders.length > 0) {
            track("NESTED_FOLDER_OPEN", { folder: folder.name })
            history.push(`/${token}/folder/${folder.guid}`);
        } else if (folder.files.length > 0) {
            const isClosing = index_drop_down === index;
            track(isClosing ? "FECHOU_PASTA" : "EXPANDIU_PASTA", { folder: folder.name })
            openDropDown(index);
        }
        setLoading(false);
    }

    const primaryColor = usePrimaryColor()
    return (
        <div>
            <Container className="container-fluid-folder" fluid={true}>
                <Navbar folder={true} link={`/${token}`} />
                <Container className="container-folder-page">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {second_page && (
                            <span
                                style={{ cursor: "pointer" }}
                                className="icon-back-no-style"
                                onClick={() => history.goBack()}
                            />
                        )}
                        <Typography variant="h4" style={{ marginLeft: "15px" }}>{data && data.name} </Typography>
                    </div>
                    <div className="filter-button-container">
                        <div onClick={OpenFilter}>
                            <Tune />
                        </div>
                    </div>
                    <div
                        ref={ref_filter}
                        className={`filter-side-bar ${open_filter && "filter-show"}`}
                    >
                        <div>
                            <h3>Filtros</h3>
                            <span className="icon-close" onClick={OpenFilter} />
                        </div>
                        <div>
                            <Label text="Pesquisa por Tag" />
                            <Input
                                maxLength={50}
                                value={filter.tag}
                                placeholder="Pesquise arquivos por tag"
                                onChange={(e) => setFilter({ ...filter, tag: e.target.value })}
                            />
                            <Label text="Nome do arquivo" />
                            <Input
                                maxLength={50}
                                value={filter.name}
                                placeholder="Pesquise um arquivo por nome"
                                onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                            />
                            <Label text="Tipo do arquivo" />
                            <Select
                                value={filter.type}
                                onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                            >
                                <Select.Option selected value={""} disabled hidden>
                  Pesquise um arquivo por tipo
                                </Select.Option>
                                <Select.Option value={""}></Select.Option>
                                <Select.Option value={"image"}>Imagem</Select.Option>
                                <Select.Option value={"video"}>Vídeo</Select.Option>
                                <Select.Option value={"pdf"}>PDF</Select.Option>
                            </Select>
                        </div>
                        <div className="filter-controls-wrapper">
                            <ButtonSecondary
                                onClick={() => setFilter({ type: "", name: "", tag: "" })}
                            >
                Limpar
                            </ButtonSecondary>
                            <ButtonPrimary onClick={(e) => fetchData(e)}>
                Filtrar
                            </ButtonPrimary>
                        </div>
                    </div>

                    {loading ? (
                        <CenterSpinnerButton color={"#000"} />
                    ) : data ? (
                        data.child_folders.length === 0 ? (
                            data.files.length === 0 ? (
                                <p className="p">Pasta vazia.</p>
                            ) : (
                                data.files.length > 0 && (
                                    <Grid
                                        rowHeight={70}
                                        cols={3}
                                    >
                                        {data.files.map((file, i) => (
                                            <ClickableAttachment 
                                                key={i}
                                                attachment={{ attachment_url: file.attachment_url, name: file.name }}
                                            />
                                        ))}
                                    </Grid>
                                )
                            )
                        ) : (
                            data.child_folders.map((folder, index) => {
                                return (
                                    <Col
                                        key={index}
                                        className="container-folder-list"
                                        style={{
                                            cursor: (folder?.child_folders?.length > 0) || folder.files.length > 0 ? "pointer" : "unset"
                                        }}
                                        onClick={() => openFolder(folder, index)}
                                    >
                                        <Row
                                            onClick={() => folder.files.length > 0 && openDropDown(index)}
                                        >
                                            <div style={{ display: "flex" }}>
                                                {renderIconSvg({
                                                    name: folder.icon || "folder",
                                                    style: { fill: primaryColor, width: "30px" }
                                                })}
                                                <div
                                                    style={{
                                                        marginLeft: "15px",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        width: "100%",
                                                        textAlign: "center",
                                                        alignItems: "center"
                                                    }}>

                                                    <div >
                                                        <Typography variant="body1">{folder.name}</Typography>
                                                        {folder.length > 0 && (<span>{`${folder.length} itens`}</span>)}
                                                    </div>
                                                    <div>
                                                        {folder.files.length > 0 && (
                                                            <span
                                                                style={{ top: "0px" }}
                                                                onClick={() => openDropDown(index)}
                                                                className={index === index_drop_down ? "icon-back-no-style" : "icon-next-no-style"}
                                                            />
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        </Row>
                                        <div
                                            ref={ref_drop_down}
                                            className={index === index_drop_down ? "drop-active" : "drop-down-folder"}
                                        >
                                            <Grid rowHeight={70} cols={3}>
                                                {folder.files.length > 0 && (
                                                    folder.files.map((file, i) => (
                                                        <ClickableAttachment 
                                                            key={i}
                                                            attachment={{ attachment_url: file.attachment_url, name: file.name }}
                                                        />
                                                    ))
                                                )}
                                            </Grid>
                                        </div>
                                    </Col>
                                )
                            })
                        )
                    ) : (
                        <p>Não foi possível encontrar as pastas.</p>
                    )}
                </Container>
            </Container>
        </div>
    );
}
