import React, { useState } from 'react'
import { Box, TextField, Typography } from "@material-ui/core"
import styled from 'styled-components'
import { AddAPhoto, AttachFile, Send } from '@material-ui/icons'
import FBButton from '~/components/Button/FBButton'
import axios from 'axios'
import { toast } from 'react-toastify'
import Loading from '~/components/loading';
import { TrashCanButton } from '../ButtonLink'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { CenterSpinnerButton } from '~/components/Spinner'

const Container = styled(Box)`
    position: relative;
`

const DragContainer = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border: 1px dashed gray;
`

export const StyledTextField = styled(TextField)`
        width: 100%;
        margin-right: 10px;

        & label {
            color: rgba(8, 22, 48, 0.7);
        }
        
        & input[class*="ShadeTextField-input"] {
            padding:0.6rem;
            font-weight: 300;
            height:52px;
            box-sizing: border-box;
        }
`;

export const ChatMessageFooter = ({ onSubmit, uploadFile, uploadStrategy = "public", loading }) => {

    const [files, setFiles] = useState([])

    const addFiles = (files) => {
        const newFiles = files.map(file => ({ id: Math.floor(Math.random() * 10000), file, loading: true }))
        setFiles(f => [...f, ...newFiles])
        newFiles.map(async f => {

            try {
                const result = await uploadFile({ extension: f.file.type })

                if (!result) {
                    setFiles(fs => fs.filter(file => file.id !== f.id))
                    toast.warn("Erro ao carregar anexo")
                    return
                }

                const { data } = result

                if (uploadStrategy === "public") {

                    await axios.put(data.uploadUrl, f.file, { headers: { "Content-Type": f.file.type } });

                    setFiles(fs => fs.map(file => {
                        if (file.id === f.id) {
                            file.result = {
                                name: file.file.name,
                                attachment_url: data.resultUrl
                            }
                            file.loading = false
                        }

                        return file
                    }))

                } else {
                    // TODO implementar estratégia pra upload privado
                }
            } catch (err) {
                toast.error(err.response?.data?.message || "Falha ao enviar arquivo")
            } finally {
                setFiles(fs => fs.map(file => {
                    if (file.id === f.id) {
                        file.loading = false
                    }

                    return file
                }))
            }
        })
    }
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ noClick: true, onDrop: addFiles })
    const inputProps = getInputProps()

    const { handleSubmit, register, reset } = useForm()

    const submit = async (ev) => {
        if (files.some(f => f.loading)) {
            return toast.warn("É necessário aguardar todos os arquivos carregarem")
        }
        const resultSend = await onSubmit({
            ...ev,
            attachments: files.map(f => f.result)
        })
        if (!resultSend) { return }
        setFiles([])
        reset()
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Container display="flex" {...getRootProps()} flexDirection="column">
                {isDragActive && (
                    <DragContainer>
                        <Typography align='center' variant="caption">
                            Solte os arquivos para adicionar
                            <br />
                            <AddAPhoto />
                        </Typography>
                    </DragContainer>
                )}
                <Box display="flex" alignItems="center">
                    <input {...inputProps} />

                    <StyledTextField
                        cursor={"text"}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            style: {
                                border: "none",
                                backgroundColor: "white",
                                padding: "5px",
                                borderRadius: "6px",
                                minHeight: "50px"
                            },
                            readOnly: false,
                            disableUnderline: true,
                            endAdornment: (
                                <FBButton
                                    style={{ borderRadius: '50%', width: 50, height: 50, marginRight: 5, minWidth: "unset" }}
                                    variant="text"
                                    onClick={ev => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        inputProps.ref.current.click();
                                    }}
                                >
                                    <AttachFile fontSize="small" />
                                </FBButton>
                            )
                        }}
                        multiline
                        style={{ flex: 1, outline: "none", border: "none", marginRight: "10px" }}
                        {...register("text", { required: true })}
                        placeholder="Digite a sua mensagem..."
                    />
                    <FBButton
                        style={{ borderRadius: '50%', width: 50, height: 50, minWidth: "unset" }}
                        type="submit"
                        primary
                        disabled={loading}
                    >
                        {loading ? <CenterSpinnerButton color={"#000"} /> : <Send fontSize="small" />}
                    </FBButton>
                </Box>
                <Box display="flex" flexDirection="column">
                    {files.map(f => (
                        <Typography key={f.id} variant="caption">
                            {f.file.name}
                            {f.loading && (<Loading size="small" />)}
                            <TrashCanButton
                                title="Remover anexo"
                                onClick={() => setFiles(fs => fs.filter(file => file.id !== f.id))}
                            />
                        </Typography>
                    ))}
                </Box>
            </Container>
        </form>
    )

}
