import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Chip, Typography } from "@material-ui/core";
import { useFormContext } from 'react-hook-form';

const GUID = op => op.guid
const NAME = l => l.name

export const FBMuiAutocomplete = React.forwardRef(({ getOptionId = GUID, label, getOptionLabel = NAME, options, ...props }, ref) => {
  const { setValue, watch, formState: { errors } } = useFormContext()
  const value = watch(props.name)
  const autoCompleteValue = Array.isArray(value) ? (options.filter(u => (value || []).includes(getOptionId(u))) || []) :
    options.find(o => value && getOptionId(o) === value) || null

  const autoComplete = (
    <Autocomplete
      noOptionsText={"Sem opções"}
      ref={ref}
      value={autoCompleteValue}
      getOptionLabel={getOptionLabel}
      options={options}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip color="primary" {...getTagProps({ index })} label={getOptionLabel(option)} />
        ))
      }
      renderInput={(params) =>
      (
        <>
          <TextField
            label={<label>{label}</label>}
            {...params} variant="outlined" />
          {errors?.[props.name]?.type === "required" && (
            <Typography color="error">Campo obrigatório.</Typography>
          )}
          {errors?.[props.name]?.type === "notEmpty" && (
            <Typography color="error">Campo obrigatório.</Typography>
          )}
        </>
      )
      }
      {...props}

      DisableClearable
      hasClearIcon
      clearText={"Limpar"}
      
      onChange={(ev, value) => setValue(props.name, Array.isArray(value) ? value.map(getOptionId) : getOptionId(value))}
    />
  )

  return autoComplete
})