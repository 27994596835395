import React from "react";
import Button from "@material-ui/core/Button";
import styled, { css } from "styled-components";
import {useTheme} from "@material-ui/core/styles";

const StyledButton = styled(Button)`
    ${({ 'data-shape': shape }) => shape === "rounded" && css`
        border-radius: 25px;
    `}
    ${props => !props.size && css`
      font-size: ${props => props.theme.typography.body1.fontSize};
      width: 100%;
      font-weight:normal;
      text-transform: none!important;
      height: 50px !important;
    `}
    ${props => props['data-whitebg'] && css`
      background-color: white !important;
    `}

    && {
        line-height: 1.1rem;
    }
    outline: none;
`;

function FBButton({ primary, shape, className, ...props }, ref) {
    const theme = useTheme();

    return <StyledButton
        ref={ref}
        variant="contained"
        color={primary ? 'primary' : props.color}
        className={["FBButton", className].filter(Boolean).join(" ")}
        data-shape={shape}
        theme={theme}
        {...props}
    >{props.children}</StyledButton>
}
export default React.forwardRef(FBButton)
