import React from 'react'
import Label from "~/components/Label";

export function TermoDeCompromisso() {
    return (
      <>
        <Label text="SEÇÃO 1 - INFORMAÇÕES GERAIS" />
        <p>
          A presente Política de Privacidade contém informações sobre coleta,
          uso, armazenamento, tratamento e proteção dos dados pessoais dos
          usuários do aplicativo FastBuilt, com a finalidade de demonstrar
          absoluta transparência quanto ao assunto e esclarecer a todos
          interessados sobre os tipos de dados que são coletados, os motivos da
          coleta e a forma como os usuários podem gerenciar ou excluir as suas
          informações pessoais.
        </p>
        <p>
          Esta Política de Privacidade aplica-se a todos os usuários do
          aplicativo FastBuilt e integra os Termos e Condições Gerais de Uso do
          aplicativo, devidamente inscrita no CNPJ sob o nº 36.674.688/0001-32,
          situado em Blumenau-SC, à rua Antônio da Veiga, 105 – sala 15, CEP
          89012-500, doravante nominada Contratada.
        </p>
        <p>
          O presente documento foi elaborado em conformidade com a Lei Geral de
          Proteção de Dados Pessoais (Lei 13.709/18), o Marco Civil da Internet
          (Lei 12.965/14) (e o Regulamento da UE n. 2016/6790). Ainda, o
          documento poderá ser atualizado em decorrência de eventual atualização
          normativa, razão pela qual se convida o usuário a consultar
          periodicamente esta seção.
        </p>
        <Label text="SEÇÃO 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO E DO VISITANTE?" />
        <p>
          Os dados pessoais do usuário e visitante são recolhidos pela
          plataforma da seguinte forma:
        </p>
        {/* <ul>
          <li> */}
        <p>
          • Quando o usuário cria uma conta/perfil na plataforma FastBuilt:
          esses dados são os dados de identificação básicos, como e-mail, nome
          completo, cidade de residência, profissão, CPF/CNPJ. A partir deles,
          podemos identificar o usuário e o visitante, além de garantir uma
          maior segurança e bem-estar às suas necessidades. Ficam cientes os
          usuários e visitantes de que seu perfil na plataforma estará acessível
          a empresa que contratou a Plataforma FastBuilt e liberou o acesso ao
          usuário e visitante.
        </p>
        {/* </li>
          <li> */}
        <p>
          • Quando um usuário e visitante acessa o aplicativo FastBuilt: as
          informações sobre interação e acesso são coletadas pela empresa para
          garantir uma melhor experiência ao usuário e visitante. Estes dados
          podem tratar sobre as palavras-chaves utilizadas em uma busca, o
          compartilhamento de um documento específico, comentários,
          visualizações de páginas, perfis, a URL de onde o usuário e visitante
          provêm, o navegador que utilizam e seus IPs de acesso, dentre outras
          que poderão ser armazenadas e retidas.
        </p>
        {/* </li>
        </ul> */}
        <Label text="SEÇÃO 3 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USUÁRIO E VISITANTE?" />
        <p>
          Os dados pessoais do usuário e visitante recolhidos são os seguintes:
        </p>
        <p>
          • Dados para a criação da conta/perfil na plataforma FastBuilt:
          e-mail, nome completo, cidade de residência, profissão, CPF/CNPJ.
        </p>
        <p>
          • Dados para otimização da navegação: acesso a páginas, palavras-chave
          utilizadas na busca, recomendações, comentários, interação com outros
          perfis e usuários, perfis seguidos, endereço de IP, alterações,
          inclusões e exclusões de dados.
        </p>
        <p>
          • Dados relacionados a contratos: diante da formalização do contrato
          de compra e venda ou de prestação de serviços entre a plataforma e o
          usuário e visitante poderão ser coletados e armazenados dados
          relativos a execução contratual, inclusive as comunicações realizada
          entre a empresa e o usuário.
        </p>
        <Label text="SEÇÃO 4 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO USUÁRIO E VISITANTE?" />
        <p>
          • Bem-estar do usuário e visitante: aprimorar o produto e/ou serviço
          oferecido, facilitar, agilizar e cumprir os compromissos estabelecidos
          entre o usuário e a empresa, melhorar a experiência dos usuários e
          fornecer funcionalidades específicas a depender das características
          básicas do usuário.
        </p>
        <p>
          • Melhorias da plataforma: compreender como o usuário utiliza os
          serviços da plataforma, para ajudar no desenvolvimento de negócios e
          técnicas.
        </p>
        <p>
          • Anúncios: apresentar anúncios personalizados para o usuário com base
          nos dados fornecidos.
        </p>
        <p>
          • Comercial: os dados são usados para personalizar o conteúdo
          oferecido e gerar subsídio à plataforma para a melhora da qualidade no
          funcionamento dos serviços.
        </p>
        <p>
          • Previsão do perfil do usuário: tratamento automatizados de dados
          pessoais para avaliar o uso na plataforma.
        </p>
        <p>
          • Dados de cadastro: para permitir o acesso do usuário a determinados
          conteúdos da plataforma, exclusivo para usuários cadastrados
        </p>
        <p>
          • Dados de contrato: conferir às partes segurança jurídica e facilitar
          a conclusão do negócio.
        </p>
        <p>
          O tratamento de dados pessoais para finalidades não previstas nesta
          Política de Privacidade somente ocorrerá mediante comunicação prévia
          ao usuário, de modo que os direitos e obrigações aqui previstos
          permanecem aplicáveis.
        </p>
        <Label text="SEÇÃO 5 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?" />
        <p>
          Os dados pessoais do usuário e visitante são armazenados pela
          plataforma durante o período necessário para a prestação do serviço ou
          o cumprimento das finalidades previstas no presente documento,
          conforme o disposto no inciso I do artigo 15 da Lei 13.709/18. Os
          dados podem ser removidos ou anonimizados a pedido do usuário,
          excetuando os casos em que a lei oferecer outro tratamento.
        </p>
        <p>
          Ainda, os dados pessoais dos usuários apenas podem ser conservados
          após o término de seu tratamento nas seguintes hipóteses previstas no
          artigo 16 da referida lei:
        </p>
        <p>
          I - cumprimento de obrigação legal ou regulatória pelo controlador;
        </p>
        <p>
          II - estudo por órgão de pesquisa, garantida, sempre que possível, a
          anonimização dos dados pessoais;
        </p>
        <p>
          III - transferência a terceiro, desde que respeitados os requisitos de
          tratamento de dados dispostos nesta Lei;
        </p>
        <p>
          IV - uso exclusivo do controlador, vedado seu acesso por terceiro, e
          desde que anonimizados os dados.
        </p>
        <Label text="SEÇÃO 6 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS" />
        <p>
          A plataforma se compromete a aplicar as medidas técnicas e
          organizativas aptas a proteger os dados pessoais de acessos não
          autorizados e de situações de destruição, perda, alteração,
          comunicação ou difusão de tais dados.
        </p>
        <p>
          A plataforma não se exime de responsabilidade por culpa exclusiva de
          terceiro, como em caso de ataque de hackers ou crackers, ou culpa
          exclusiva do usuário, como no caso em que ele mesmo transfere seus
          dados a terceiros. A plataforma se compromete a comunicar o usuário em
          caso de alguma violação de segurança dos seus dados pessoais.
        </p>
        <p>
          Os dados pessoais armazenados são tratados com confidencialidade,
          dentro dos limites legais. No entanto, podemos divulgar suas
          informações pessoais caso sejamos obrigados pela lei para fazê-lo ou
          se você violar nossos Termos de Serviço.
        </p>
        <Label text="SEÇÃO 7 - COMPARTILHAMENTO DOS DADOS" />
        <p>
          O compartilhamento de dados do usuário ocorre apenas com os dados
          referentes a publicações realizadas pelo próprio usuário, tais ações
          são compartilhadas publicamente com os outros usuários.
        </p>
        <p>
          Os dados do perfil do usuário são compartilhados publicamente em
          sistemas de busca e dentro da plataforma, sendo permitido ao usuário
          modificar tal configuração para que seu perfil não apareça nos
          resultados de busca de tais ferramentas.
        </p>
        <Label text="SEÇÃO 8 - OS DADOS PESSOAIS ARMAZENADOS SERÃO TRANSFERIDOS A TERCEIROS?" />
        <p>
          Os terceiros indicados recebem os dados na medida do necessário para
          permitir que eles realizem os serviços contratados.
        </p>
        <p>
          Com relação aos fornecedores de serviços terceirizados como
          processadores de transação de pagamento, informamos que cada qual tem
          sua própria política de privacidade. Desse modo, recomendamos a
          leitura das suas políticas de privacidade para compreensão de quais
          informações pessoais serão usadas por esses fornecedores.
        </p>
        <p>
          Os fornecedores podem ser localizados ou possuir instalações
          localizadas em países diferentes. Nessas condições, os dados pessoais
          transferidos podem se sujeitar às leis de jurisdições nas quais o
          fornecedor de serviço ou suas instalações estão localizados.
        </p>
        <p>
          Ao acessar nossos serviços e prover suas informações, você está
          consentindo o processamento, transferência e armazenamento desta
          informação em outros países.
        </p>
        <p>
          Ao ser redirecionado para um aplicativo ou site de terceiros, você não
          será mais regido por essa Política de Privacidade ou pelos Termos de
          Serviço da nossa plataforma. Não somos responsáveis pelas práticas de
          privacidade de outros sites e lhe incentivamos a ler as declarações de
          privacidade deles.
        </p>
        <Label text="SEÇÃO 9 – COOKIES OU DADOS DE NAVEGAÇÃO" />
        <p>
          Os cookies referem-se a arquivos de texto enviados pela plataforma ao
          computador do usuário e visitante e que nele ficam armazenados, com
          informações relacionadas à navegação no site. Tais informações são
          relacionadas aos dados de acesso como local e horário de acesso e são
          armazenadas pelo navegador do usuário e visitante para que o servidor
          da plataforma possa lê-las posteriormente a fim de personalizar os
          serviços da plataforma.
        </p>
        <p>
          O usuário e o visitante da plataforma FastBuilt manifesta conhecer e
          aceitar que pode ser utilizado um sistema de coleta de dados de
          navegação mediante à utilização de cookies.
        </p>
        <p>
          O cookie persistente permanece no disco rígido do usuário e visitante
          depois que o navegador é fechado e será usado pelo navegador em
          visitas subsequentes ao site. Os cookies persistentes podem ser
          removidos seguindo as instruções do seu navegador. Já o cookie de
          sessão é temporário e desaparece depois que o navegador é fechado. É
          possível redefinir seu navegador da web para recusar todos os cookies,
          porém alguns recursos da plataforma podem não funcionar corretamente
          se a capacidade de aceitar cookies estiver desabilitada.
        </p>
        <Label text="SEÇÃO 10 - CONSENTIMENTO" />
        <p>
          Ao utilizar os serviços e fornecer as informações pessoais na
          plataforma, o usuário está consentindo com a presente Política de
          Privacidade.
        </p>
        <p>
          O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar seus
          direitos de cancelar seu cadastro, acessar e atualizar seus dados
          pessoais e garante a veracidade das informações por ele
          disponibilizadas.
        </p>
        <p>
          O usuário tem direito de retirar o seu consentimento a qualquer tempo,
          para tanto deve entrar em contato através do e-mail
          contato@fastbuilt.com.br ou por correio enviado ao seguinte endereço:
          Rua Antonio da Veiga, 105, sala 15 - Blumenau/SC
        </p>
        <Label text="SEÇÃO 11 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE" />
        <p>
          Reservamos o direito de modificar essa Política de Privacidade a
          qualquer momento, então, é recomendável que o usuário e visitante
          revise-a com frequência.
        </p>
        <p>
          As alterações e esclarecimentos vão surtir efeito imediatamente após
          sua publicação na plataforma. Ao utilizar o serviço ou fornecer
          informações pessoais após eventuais modificações, o usuário e
          visitante demonstra sua concordância com as novas normas.
        </p>
        <p>
          Diante da fusão ou venda da plataforma à outra empresa os dados dos
          usuários podem ser transferidos para os novos proprietários para que a
          permanência dos serviços oferecidos.
        </p>
        <Label text="SEÇÃO 12 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS" />
        <p>
          Para a solução de controvérsias decorrentes do presente instrumento
          será aplicado integralmente o Direito brasileiro.
        </p>
        <p>
          Os eventuais litígios deverão ser apresentados no foro da comarca em
          que se encontra a sede da FastBuilt Soluções Inteligentes Ltda.
        </p>
      </>
    );
  }

