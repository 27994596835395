import "./fonts.css";
import "./icons-guide.css";
import { createGlobalStyle, css } from "styled-components";

export default createGlobalStyle`
  :root {
    --font-family: "Poppins", sans-serif;
    ${({ 'theme-color': rgbColor, primary }) => css`
        --theme-color-rgb: ${rgbColor};
        --theme-color: rgb(${rgbColor});
        --theme-color-transparent:  rgba(${rgbColor}, 0.10);
        --theme-color-opaque:  rgba(${primary || rgbColor}, 0.70);
    `}
  }
  
  #root {
    height: 100%;
  }

  html {
    height: 100%;
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    position: relative;
    height: 100%;
    margin: 0;
    font-family: var(--font-family);
  }

  a, a:hover {
    text-decoration: none;
    color: #212529;
  }


  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-family: var(--font-family);
  }

  h4 {
    font-size: 18px;
  }

  h6 {
    font-size: 15px;
  }

  p {
    font-size: 14px;
  }

  span {
    font-size: 14px;
  }

  p {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  input,
  textarea {
    border: 0;
    outline: 0;
    display: block;
    width: 100%;
    background-color: #f8f8f8;
    font-family: var(--font-family);
    font-size: 18px;
  }

  button {
    cursor: pointer;
    border: 0;
    outline: 0;
    padding: 0;
    background-color: transparent;
    font-family: var(--font-family);
  }

  .button-primary:focus{
    outline: 0px auto -webkit-focus-ring-color
  }


  table {
    thead{
      th{
        font-size: 14px;
      }
    }
  }
  .toast-success {
    background: #006634;
  }

  .toast-error {
    background: #ec1313;
  }

  .toast-warn {
    background: #f2c94c;
  }

  .toast-info {
    background: #c4c4c4;
  }

    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        font-weight: 400;
    }
    * {
    }
`;
