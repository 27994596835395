import React from 'react';
import styled from 'styled-components';

const StyledInnerHtmlContainer = styled.div`
    img {
        max-width: 100%;
    }
`;

const StyledInnerHtml = ({ htmlContent }) => {
    return (
        <StyledInnerHtmlContainer dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
};

export default StyledInnerHtml;