import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Container } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import 'moment/locale/pt-br'

import api from '~/services/api';
import { Box, Card, CardContent, Input, Tooltip, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { ClickableAttachment } from '~/components/Sac/Attachment';
import { ImageContainer, StyledLink } from '../Inspection/Sign';
import styled, { css } from 'styled-components';
import FBButton from '~/components/Button/FBButton';
import Label from '~/components/Label';
import InputImage from '~/components/Input/InputImage';
import axios from 'axios';
import { Signature } from '~/components/Signature';
import Checkbox from '~/components/Checkbox';
import Loading from '~/components/loading';
import { validateDocument } from '~/utils/formatters';

const StyledCard = styled(Card)`
    cursor: pointer;
    ${({ 'data-active': active }) => active && css`box-shadow: 0px 0px 0px 14px rgba(144, 202, 249, 0.3) !important;`}
`
export default function Finishes() {
    const [finish, setFinish] = useState(null)
    const [completed, setCompleted] = useState(null)
    const [send, setSend] = useState(false)
    const { token, guid } = useParams()
    const history = useHistory()
    const [selected, setSelected] = useState({})
    const [loading, setLoading] = useState(false)
    const drawingRef = useRef(null);
    const currentItem = new URLSearchParams(history.location.search).get("item")

    useEffect(() => {
        async function getFinish() {
            try {
                if (!guid) { return; }
                const response = await api.get(`v1/${token}/finishes/${guid}`);
                if (response.status !== 200) {
                    toast.error(response.data.message)
                } else if (response.data.result) {
                    const { result } = response.data
                    setFinish(result)
                    if (result.items.length) {
                        history.replace(`?item=${result.items[0].guid}`)
                    }
                }

            } catch (err) {
                toast.error(err.response?.data?.message || 'Não foi possível localizar a disponibilidade');
            }
        }

        getFinish();
    }, [guid, token, history]);

    const item = finish?.items.find(i => i.guid === currentItem)

    function updateSelectedOption(optionId, ev) {
        if (ev.target.role !== "dialog") {
            setSelected(s => ({ ...s, [item.guid]: optionId }))
        }
    }

    const currentIndex = finish?.items.findIndex(i => i.guid === currentItem)
    const isLast = currentIndex === finish?.items.length - 1

    function next() {
        setLoading(true)
        const index = finish.items.findIndex(i => i.guid === currentItem)
        const next = finish.items[index + 1]
        if (next) {
            history.push(`?item=${next.guid}`)
        } else {
            setCompleted(true)
        }
        setLoading(false)
    }

    const [info, setInfo] = useState({})
    async function handleImageChange(event) {
        const [selfie] = Array.from(event.target.files);
        if (!selfie) { return }

        setInfo(info => ({ ...info, selfie: { name: selfie.name, loading: true } }));

        const url = `/v1/${token}/finishes/${guid}/attachment_url?extension=${selfie.type}`
        const result = await api.get(url);
        setInfo(info => ({ ...info, selfie: { ...info.selfie, ...result.data } }));
        const options = { headers: { 'Content-Type': selfie.type } };

        await axios.put(result.data.upload, selfie, options);
        setInfo(info => ({ ...info, selfie: { ...info.selfie, loading: false } }));
    }

    function submit(ev) {
        ev.preventDefault()
        if (!info.checked) {
            return toast.warn("É necessário confirmar as opções escolhidas")
        }
        if (!info.selfie || info.selfie.loading) {
            return toast.warn("Preencha todas as informações")
        }

        if (drawingRef.current.lines.length === 0) {
            return toast.error('A assinatura não pode estar em branco!');
        }
        if (!info.document) {
            return toast.error('O documento é obrigatório');
        }
        if (!validateDocument(info.document)) {
            return toast.error('Insira um cpf válido');
        }
        drawingRef.current.canvas.drawing.toBlob(async data => {
            setLoading(true);
            const fileType = data.type;
            data.lastModifiedDate = new Date();
            data.name = `signature_${Number(new Date())}.png`;
            try {
                const url = `/v1/${token}/finishes/${guid}/attachment_url?extension=${fileType}`
                const result = await api.get(url);

                const options = { headers: { 'Content-Type': fileType } };
                await axios.create().put(result.data.upload, data, options);

                const resultSign = await api.post(`/v1/${token}/finishes/${guid}`, {
                    document: info.document,
                    selfie_key: info.selfie?.fileKey,
                    signature_key: result.data.fileKey,
                    answers: selected
                });
                if (resultSign.status === 200) {
                    toast.success("Customizações escolhidas com sucesso!")
                    setSend(true)
                }

                setLoading(false);
            } catch (err) {
                console.error(err)
                toast.error(err?.response?.data?.message || 'Não foi possível fazer o upload da imagem!');
                setLoading(false);
            }
        });
    }

    return (
        <div>
            <Container className='container-fluid-folder' fluid={true}>
                <Container className='container-folder-page'>
                    <br />
                    <Link to={`/${token}`}>
                        <div style={{ display: 'flex' }}>
                            <ArrowBack fontSize='large' />
                            <h2 style={{ marginLeft: 30 }}> Escolher customizações </h2>
                            {loading && <div style={{ marginLeft: "15px", marginTop: "5px" }}><Loading /></div>}
                        </div>
                    </Link>

                    <Box display="inline-flex" flexDirection="column" style={{ width: '100%' }}>
                        {item && (
                            <>
                                <br />
                                {!completed && <Typography variant="h4"> {item.name} </Typography>}
                                <Box display="flex" flexDirection="column">
                                    {send ?
                                        <Box
                                            height="500px"
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography variant='h6'>Obrigado por responder, sua solicitação foi enviada com sucesso para análise da construtora!</Typography>
                                        </Box>
                                        :
                                        completed ?
                                            <>
                                                <Label text='Insira o seu cpf' />
                                                <Input
                                                    style={{ maxWidth: "350px" }}
                                                    onChange={ev => setInfo({ ...info, document: ev.target.value })}
                                                    value={info.document}
                                                    maxLength={50}
                                                    autoFocus placeholder='xxx.xxx.xxx-xx'
                                                />

                                                <Label text='Tire uma selfie com o seu documento' />
                                                <Tooltip title="Clique para selecionar escolher uma foto">
                                                    <ImageContainer style={{ maxWidth: "350px" }}>
                                                        <InputImage
                                                            id='selfie'
                                                            handleImageChange={handleImageChange}
                                                            onDrop={handleImageChange}
                                                            accept="image/.jpg,image/.jpeg,image/.jpe,image/.svg,image/.tiff,image/.png,image/jpg,image/jpeg,image/svg,image/tiff,image/png"
                                                            onDragOver={event => event.preventDefault()}
                                                            image={info.selfie?.result}

                                                        >
                                                            {info.selfie?.loading === false && <img src={info.selfie?.result} alt='selfie' />}
                                                            {info.selfie?.loading === true && <Loading />}
                                                        </InputImage>
                                                    </ImageContainer>
                                                </Tooltip>

                                                <Signature ref={drawingRef} text='Assine a escolha das customizações' />
                                                <Box display="flex">
                                                    <FBButton style={{ width: 100 }} primary className='mr-1' variant="outlined"
                                                        onClick={() => drawingRef.current.undo()}
                                                    >
                                                        Desfazer
                                                    </FBButton>
                                                    <FBButton style={{ width: 100, marginLeft: 10, marginBottom: 20 }} primary className='mr-1' variant="outlined"
                                                        onClick={() => drawingRef.current.clear()}
                                                    >
                                                        Limpar
                                                    </FBButton>
                                                </Box>

                                                <Checkbox
                                                    checked={info.checked}
                                                    onChange={ev => setInfo({ ...info, checked: ev.target.checked })}
                                                    label='Estou de acordo com as escolhas selecionadas nesse formulário.'
                                                />
                                            </>
                                            : item.options.map(op => (
                                                <StyledLink key={op.guid} onClick={ev => updateSelectedOption(op.guid, ev)}>
                                                    <StyledCard data-active={selected[item.guid] === op.guid} style={{ marginTop: 25 }}>
                                                        <CardContent >
                                                            <Typography variant="caption"><small>{selected[item.guid] !== op.guid ? "Clique para selecionar" : "Opção selecionada"}
                                                            </small> </Typography>
                                                            <Box style={{ width: '100%' }} display="inline-flex" justifyContent="space-between">
                                                                <Typography> {op.name} </Typography>
                                                                <Typography> {((op.value || 0) / 100).toLocaleString("pt-BR", { style: 'currency', currency: "BRL" })} </Typography>
                                                            </Box>

                                                            <Box display="inline-flex" flexWrap="wrap">
                                                                {op.attachments.map(a => (
                                                                    <ClickableAttachment className="card-finish" hideFooter key={a.guid} attachment={a} />
                                                                ))}
                                                            </Box>
                                                        </CardContent>
                                                    </StyledCard>
                                                </StyledLink>
                                            ))
                                    }
                                </Box>
                                {!send &&
                                    <Box display="flex" justifyContent="space-between" style={{ marginTop: 25, marginBottom: 25 }}>
                                        <div>
                                            <FBButton
                                                disabled={!currentIndex} onClick={() => completed ? setCompleted(false) : history.goBack()} style={{ width: 150 }} primary variant="outlined"> Voltar </FBButton>
                                        </div>
                                        <div>
                                            {completed ?
                                                <FBButton onClick={submit} style={{ width: 150 }} disabled={!selected[item.guid]} primary> {loading ? <Loading /> : "Enviar resposta"}</FBButton>
                                                : <FBButton onClick={next} style={{ width: 150 }} disabled={!selected[item.guid]} primary>
                                                    {isLast ? "Concluir" : "Avançar"}
                                                </FBButton>
                                            }
                                        </div>
                                    </Box>
                                }
                            </>
                        )}
                    </Box>
                </Container>
            </Container>
        </div>
    );
}
