import { useEffect } from 'react';

const useEvent = (
  name,
  handler,
  target,
  options,
) => {
  useEffect(() => {
    window.addEventListener(name, handler);
    return () => {
        window.removeEventListener(name, handler);
    };
  }, [name, handler, target, JSON.stringify(options)]);
};

export default useEvent;
