import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { Grid, Tooltip, } from '@material-ui/core';
import Modal from "~/components/Modal";
import FBMainAction from "~/components/buttons/FBMainAction";
import { CenterSpinnerButton } from "~/components/Spinner";
import { CloudDownload, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import moment from "moment";

const DivImgStyle = styled.div`
    padding: 10px;
    border-radius: 10px;
    background: #FAFAFA;
    box-Shadow: 0px 4px 4px 0px #00000040;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin: 10px 5px 0px 0px;
    height: 230px;
    cursor: pointer;
    @media (max-width: 999px) {
        height: 180px;
    }

`
const LabelImg = styled.label`
    font-size: 12px;
    word-break: break-word;
    color: #474747;   
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 40px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
`
const LabelImgShow = styled.label`
    margin: 3px;
    font-size: 13px;
    word-break: break-word;
    color: #fff;   
    overflow: hidden;
    font-weight: bold;
    max-width: 100%;
`

const ImgStyled = styled.img`
    width: 100%;
    min-height: 100px;
`

const VideoStyled = styled.video`
    width: 100%;
    min-height: 100px;
`
const DivVideo = styled.div`
    @media (max-width: 999px) {
        position: relative;
        display: flex;
        flex-Direction: column;
        width: 100%;
    }
`

const DivCard = styled.div` 
    overflow: hidden;
    display: flex;
    align-Items: center;
    justify-Content: center;
    height: 150px;
    border-radius: 6px;
    @media (max-width: 999px) {
        height: 100px;
    }
`

export default function RenderFilesImgVideo({ attachments }) {

    const [guid_image, setGuidImage] = useState("");
    const [open_modal_files, setModalFiles] = useState(false);
    const [loading_download, setLoadingDownload] = useState(false);
    const startClick = useRef(0);

    useEffect(() => {

        function swipStart(event) {
            startClick.current = event.changedTouches[0].screenX
        }

        function swipEnd(event) {

            const indexFile = attachments.findIndex((file) => file.guid === guid_image);
            const endTouch = event.changedTouches[0].screenX
            const totalSwip = Math.abs(endTouch - startClick.current)

            if (indexFile === -1 || endTouch === startClick.current || totalSwip < 130) {
                return
            }

            arrayFiles(Boolean(endTouch > startClick.current))
        }

        document.addEventListener("touchstart", swipStart)
        document.addEventListener("touchend", swipEnd)

        return () => {
            document.removeEventListener("touchstart", swipStart)
            document.removeEventListener("touchend", swipEnd)
        }
        
    }, [attachments, guid_image])

    function arrayFiles(next) {

        const indexFile = attachments.findIndex((file) => file.guid === guid_image);

        if (next) {
            if (indexFile > 0) {
                handleModalFiles(attachments[indexFile - 1].guid)
            }
        } else {
            if ((attachments.length - 1) > indexFile) {
                handleModalFiles(attachments[indexFile + 1].guid)
            }
        }
    }

    async function downloadFile(file) {
        setLoadingDownload(true);
        try {
            let blob = await fetch(file.attachment_url + "?t=" + new Date().getTime()).then((r) => r.blob());
            saveAs(blob, `${file.name}`);
            toast.success("Arquivo selecionado foi baixado");
        } catch (e) {
            console.error(e)
        }

        setLoadingDownload(false);
    }

    function handleModalFiles(i) {
        setGuidImage(i);
        setModalFiles(Boolean(i));
    }

    return attachments?.map((attachment, i) => {
        return (
            <Grid xs={6} sm={4} xl={2} md={3} item key={i} >

                <DivImgStyle onClick={() => handleModalFiles(attachment.guid)}>

                    <DivCard>
                        {(attachment.attachment_url.includes(".mp4") ||
                            attachment.attachment_url.includes(".mov") ||
                            attachment.attachment_url.includes(".avi") ||
                            attachment.attachment_url.includes(".quicktime")) ? (
                                <VideoStyled preload="metadata" src={attachment.attachment_url + "#t=0.001"} type="video/mp4" />
                            ) : (
                                <ImgStyled src={attachment.attachment_url} alt="URL ARQUIVO" />
                            )}
                    </DivCard>

                    <div style={{ minHeight: "40px" }}>
                        <Tooltip title={attachment.name}>
                            <span style={{ display: "block" }}>
                                <LabelImg>{attachment.name}</LabelImg>
                            </span>
                        </Tooltip>
                    </div>

                    <div style={{ position: "absolute" }}>
                        <LabelImg>{moment(attachment.date.split("T")[0]).format("DD/MM/YYYY")} </LabelImg>
                    </div>

                </DivImgStyle>

                {open_modal_files && attachment.guid === guid_image && (
                    <Modal
                        centered
                        size="xl"
                        className="files-modal"
                        dialogClassName="modal-90w"
                        aria-labelledby="contained-modal-title-vcenter"
                        show={open_modal_files}
                        handleClose={handleModalFiles}
                    >
                        <div
                            style={{
                                display: "flex",
                                background: "#00000040",
                                borderRadius: "6px",
                                flexDirection: "column",
                                margin: "0px 0px 10px 0px",
                            }}
                        >
                            <LabelImgShow>Descrição: {attachment.name}</LabelImgShow>
                            <LabelImgShow>Data: {moment(attachment.date.split("T")[0]).format("DD/MM/YYYY")} </LabelImgShow>
                        </div>

                        {(attachment.attachment_url.includes(".mp4") ||
                            attachment.attachment_url.includes(".mov") ||
                            attachment.attachment_url.includes(".avi") ||
                            attachment.attachment_url.includes(".quicktime")) ? (
                                <DivVideo>
                                    <video style={{ borderRadius: "6px" }} autoPlay controls src={attachment.attachment_url} type="video/mp4" />
                                </DivVideo>
                            ) : (
                                <img style={{ borderRadius: "6px" }}alt="imagem" src={attachment.attachment_url} />
                            )}

                        <div
                            style={{ display: "flex", flex: 1, justifyContent: "center", marginTop: "10px" }}
                        >
                            <FBMainAction
                                onClick={(event) => { downloadFile(attachment); }}
                                style={{
                                    margin: "0px 5px",
                                    paddingRight: "0px",
                                    paddingLeft: "0px",
                                }}
                            >
                                {loading_download ? (<CenterSpinnerButton />) : (<CloudDownload />)}
                            </FBMainAction>
                            <FBMainAction
                                style={{
                                    margin: "0px 5px",
                                    paddingRight: "0px",
                                    paddingLeft: "0px",
                                }}
                                onClick={() => arrayFiles(true)}
                            >
                                <KeyboardArrowLeft />
                            </FBMainAction>
                            <FBMainAction
                                style={{
                                    margin: "0px 5px",
                                    paddingRight: "0px",
                                    paddingLeft: "0px",
                                }}
                                onClick={() => arrayFiles(false)}
                            >
                                <KeyboardArrowRight />
                            </FBMainAction>
                            <FBMainAction
                                style={{ margin: "0px 5px" }}
                                onClick={() => handleModalFiles()}
                            >
                                Voltar
                            </FBMainAction>
                        </div>
                    </Modal>
                )
                }

            </Grid >
        )
    });
}
