import React from 'react';
import styled from 'styled-components';
import './loading.scss'

/**
 *
 * @param {string,('tiny', 'small', 'medium')} size
 * @param {string,('light','gray','purple','dark')} color
 * @returns {*}
 * @constructor
 */
export default function Loading({size = "small", color = "normal"}) {
    return <div className={`loading-donut ${size} ${color}`}/>;
}

export const LoadingContainer  = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

