import React, { useCallback, useRef, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Input from "~/components/Input/Input";
import { formatPhone } from "~/services/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightRegular,
    },
    content:{
        fontSize: theme.typography.pxToRem(13.6)
    }
}));

export default function ListSuppliers({ suppliers }) {

    const classes = useStyles();
    const [search, setSearch] = useState()
    const [inputSearch, setInputSearch] = useState()
    const searchRef = useRef(null)

    const update = useCallback((ev) => {
        const value = ev.target.value
        clearTimeout(searchRef.current)
        setSearch(value)
        searchRef.current = setTimeout(() => {
            setInputSearch(value)
        }, 300)
    }, [])
    const visible = inputSearch ? suppliers.filter(g => g.name.toLowerCase().includes(inputSearch.toLowerCase())) : suppliers
    return (
        <>
            {suppliers.length ?
                <>
                    <form onSubmit={ev => { ev.preventDefault(); }}>
                        <div>
                            <Input
                                maxLength={50}
                                onChange={update}
                                value={search}
                                autoFocus
                                placeholder="Pesquise seu fornecedor pelo nome"
                            />
                        </div>
                        <button value="Pesquisar" type="submit" style={{ display: "none" }}> </button>
                    </form>
                    {visible.map((g, i) => (
                        <>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>{g.name} - {g.type}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>
                                        <Typography display="block" className={classes.content} >Telefone: {formatPhone(g.phone)}</Typography>
                                        {g.site ?
                                            (
                                                <Typography display="block" className={classes.content}>
                                                    Site:
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={g.site.startsWith('http://') || g.site.startsWith('https://') ? g.site : `http://${g.site}`}
                                                        style={{ color: 'blue', textDecoration: 'underline' }} 
                                                    >
                                                        {g.site}
                                                    </a>
                                                </Typography>
                                            ) : ""}
                                    </div>


                                </AccordionDetails>
                            </Accordion>

                        </>
                    ))}
                </>
                :
                <>
                </>
            }
        </>
    )
}