import React from 'react'
import styled from 'styled-components'
import { saveAs } from "file-saver";
import pdfImage from '~/assets/imgs/common/pdfImage.png'
import wordImage from '~/assets/imgs/common/word.png'
import file from '~/assets/imgs/common/file.png'
import excel from '~/assets/imgs/common/excel.png'
import { Col } from 'react-bootstrap'
import Modal from "~/components/Modal";
import ButtonPrimary from '../Button/ButtonPrimary';
import { track } from '~/services/track';
import { toast } from 'react-toastify';
import { Viewer, Worker } from '@react-pdf-viewer/core';

const types = {
    image: ['png', 'jpeg', 'jpg', 'tiff', 'svg', 'gif'],
    video: ['mp4', 'mov', 'quicktime', 'avi', 'webm'],
    doc: ['docx', 'document', 'doc'],
    excel: ["xlsx", 'xls'],
    pdf: ['pdf'],
}

const getType = (attachment) => {
    function selectType(value) {
        const k = Object.entries(types)
            .find(([_, v]) => v.some(ext => value.toLowerCase().endsWith(ext.toLowerCase())))
        return k ? k[0] : ""
    }
    return selectType(attachment.attachment_url) || selectType(attachment.name)
}

const Card = styled(Col)`
  padding: 10px;
  cursor: pointer;
  border-radius: 3px;
  flex: 0;
  background: #FAFAFA;
  box-shadow: 0px 1px 6px rgba(8, 22, 48, 0.15);
  margin: 10px 7.5px;
  img, video {
    max-height: 100px;
    max-width: 100%;
  }
`

const Buttons = styled.div`
  > * {
      margin: 0 5px;
  }
`

const OpenLink = styled.a`
    cursor: pointer;
`

function closeModal(attachment, setModalContent) {
    return ev => {
        ev.stopPropagation()
        track("FECHOU_ARQUIVO", { fileName: attachment.name })
        setModalContent(null)
    }

}

export const Attachment = ({ attachment, setModalContent, hideFooter, className }) => {
    const [loading, setLoading] = React.useState(false)

    const download = async (event) => {
        event.stopPropagation()
        setLoading(true)
        track("BAIXOU_ARQUIVO", { fileName: attachment.name })
        try {
            const blob = await fetch(attachment.attachment_url + "?t=" + new Date().getTime()).then((r) => r.blob());
            saveAs(blob, attachment.name)
            toast.success("Arquivo baixado")
        } catch (err) {
            toast.error("Ocorrreu um erro ao baixar o arquivo")
            console.error(err)
        } finally {
            setLoading(false)
        }
    }
    switch (getType(attachment)) {
    case "image":
        return (
            <Card onClick={() => {
                track("ABRIU_ARQUIVO", { fileName: attachment.name })
                setModalContent((
                    <>
                        <img src={attachment.attachment_url} alt={attachment.name} />
                        <Buttons>
                            <ButtonPrimary
                                onClick={download}
                                disabled={loading}
                            >
                                    Baixar anexo
                            </ButtonPrimary>
                            <ButtonPrimary onClick={closeModal(attachment, setModalContent)}>
                                    Voltar
                            </ButtonPrimary>
                        </Buttons>
                    </>
                ))
            }}
            className={["justify-content-between", className].filter(Boolean).join(" ")}>
                <div>
                    <img src={attachment.attachment_url} alt={attachment.name} />
                </div>
                {!hideFooter && <div className="footer-card" >
                    <OpenLink>{attachment.name}</OpenLink>
                </div>}
            </Card>
        )
    case "video":
        return (
            <Card
                className={className}
                onClick={() => {
                    track("ABRIU_ARQUIVO", { fileName: attachment.name })
                    setModalContent((
                        <>
                            <video controls src={attachment.attachment_url} />
                            <Buttons>
                                <ButtonPrimary
                                    onClick={download}
                                    disabled={loading}
                                >
                                        Baixar anexo
                                </ButtonPrimary>
                                <ButtonPrimary onClick={closeModal(attachment, setModalContent)}>
                                        Voltar
                                </ButtonPrimary>
                            </Buttons>
                        </>
                    ))
                }}
            >
                <div>
                    <video controls src={attachment.attachment_url} />
                </div>
                <div className="footer-card">
                    <OpenLink>{attachment.name}</OpenLink>
                </div>
            </Card>
        )
    case "pdf":
        return (
            <Card
                onClick={() => {
                    track("ABRIU_ARQUIVO", { fileName: attachment.name })
                    setModalContent((
                        <>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                                <div style={{ height: '750px', maxHeight: '80vh' }}>
                                    <Viewer fileUrl={attachment.attachment_url} />
                                </div>
                            </Worker>
                            <Buttons>
                                <ButtonPrimary
                                    disabled={loading}
                                    onClick={download}
                                >
                                        Baixar anexo
                                </ButtonPrimary>
                                <ButtonPrimary onClick={closeModal(attachment, setModalContent)}>
                                        Voltar
                                </ButtonPrimary>
                            </Buttons>
                        </>
                    ))
                }}
                className={className}
            >
                <div>
                    <img src={pdfImage} alt="Pdf icon preview" />
                </div>
                <div className="footer-card">
                    <OpenLink>{attachment.name}</OpenLink>
                </div>
            </Card>
        )
    case "doc":
        return (
            <Card onClick={download} className={className} >
                <div>
                    <img src={wordImage} alt="Generic file icon" />
                </div>
                <div className="footer-card">
                    <OpenLink>{attachment.name}</OpenLink>
                </div>
            </Card>
        )
    case "excel":
        return (
            <Card onClick={download} className={className}>
                <div>
                    <img src={excel} alt="Generic file icon" />
                </div>
                <div className="footer-card">
                    <OpenLink>{attachment.name}</OpenLink>
                </div>
            </Card>
        )
    default:
        return (
            <Card onClick={download} className={className} >
                <div>
                    <img src={file} alt="Generic file icon" />
                </div>
                <div className="footer-card">
                    <OpenLink>{attachment.name}</OpenLink>
                </div>
            </Card>
        )
    }

}

export const ClickableAttachment = ({ attachment, hideFooter, className }) => {
    const [modalContent, setModalContent] = React.useState(null)
    return (
        <>
            <Attachment hideFooter={hideFooter} attachment={attachment} className={className} setModalContent={setModalContent} />
            <Modal
                centered
                size="xl"
                className="files-modal z-99999"
                backdropClassName="z-99999"
                dialogClassName="modal-90w z-99999"
                aria-labelledby="contained-modal-title-vcenter"
                show={Boolean(modalContent)}
                handleClose={() => {
                    track("FECHOU_ARQUIVO", { fileName: attachment.name })
                    setModalContent(null)
                }}
            >
                {modalContent}
            </Modal>
        </>

    )
}
