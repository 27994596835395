import React, { useEffect, useMemo, useState } from 'react';
import { Container, Row } from "react-bootstrap";
import "./styles.scss";
import { Link, useParams } from 'react-router-dom';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import api from '~/services/api';
import { track } from '~/services/track';
import { Typography } from '@material-ui/core';
import { ClickableAttachment } from '~/components/Sac/Attachment';

export function Answer({ text = "" }) {
    const answer = useMemo(() => {
        var urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g    
        let http = "http://"                               // /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function(url) {
            const a = document.createElement("a") 
            a.style = "color: #007bff !important"
            a.target ="_blank"
            a.rel="noopener noreferrer"

            if (!text.includes("http"))
                a.href =  http + url
            else 
                a.href =  url

            a.innerText = url

            return a.outerHTML
        })
    }, [text])
    return (
        <p dangerouslySetInnerHTML={{ __html: answer }}/>
    )

}

function Question() {
    const { token, categoryGuid, questionGuid } = useParams();

    const [question, setQuestion] = useState();
    const [questionFiles, setQuestionFiles] = useState();

    const getQuestion = async () => {
        const response = await api.get(`/questions/${categoryGuid}`);
        const filteredQuestion = response.data.find(quest => quest.guid === questionGuid);
        setQuestion(filteredQuestion);
        const responseFiles = await api.get(`/questions_file/${questionGuid}`);
        setQuestionFiles(responseFiles.data)
    };

    useEffect(() => {
        getQuestion();
    }, []);

    return (
        <Container className='background-faq' >

            <Link to={`/${token}/faq/${categoryGuid}`} onClick={() => track("VOLTAR_PARA_PERGUNTAS")}>
                <div className="back-link">
                    <ArrowBackIcon />
                    <p>Voltar para perguntas</p>
                </div>
            </Link>
      
            <Container className="container-question">

                <Row style={{ width: '100%' }}>
                    <strong>{question?.question}</strong>
                    <Typography style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>
                        <Answer text={question?.answer} />
                    </Typography>
                
                    {questionFiles && questionFiles.map((questionFile, i) => (
                        <Row style={{margin: "10px 10px 6px 10px"}} key={i}>
                            <ClickableAttachment key={questionFile.guid} attachment={questionFile} />
                        </Row>
                    ))} 
                </Row>
            </Container>
        </Container>
    );
}

export default Question;
