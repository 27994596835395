import { Box, Typography } from '@material-ui/core'
import { NavigateBefore } from '@material-ui/icons'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Header = styled(Box)`
    position: relative;
    padding-top: 15px;
    > :first-child {
        position: absolute;
        left: 0;
        cursor: pointer;
    }

    > :last-child {
        padding-bottom: 10px;
        border-bottom: 2px solid #414141;
    }
`
export const PageHeader = ({ backTo, children }) => {
    return (
        <Header display="flex">
            <Link to={backTo}>
                <NavigateBefore fontSize="large" />
            </Link>
            <Typography style={{ margin: "auto" }} align="center">
                {children}
            </Typography>
        </Header>
    )
}

export const ModalHeader = ({ previous, title }) => {

    return (
        <div className="modal-v2-title">
            <span onClick={previous} className="icon-back absolute-back-button" />
            <br />
            <br />
           <div className="modal-title"> <span > {title} </span> </div>
        </div>
    )

}
