
import { Container } from 'react-bootstrap';
import React from 'react';
import MonitorList from "./MonitorList";

function Monitor() {

  return (
    <Container className="background-faq">
      <MonitorList />
    </Container>
  );
}

export default Monitor;