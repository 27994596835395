import React from "react";

import "./Modal.sass";

import { Modal } from "react-bootstrap";

export default ({
  handleClose,
  className,
  show,
  title,
  children,
  backdropClassName,
}) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
      className={className}
      backdropClassName={backdropClassName}
    >
      {title && (
        <Modal.Header closeButton>
          <h4 style={{ fontWeight: "600" }}>{title}</h4>
        </Modal.Header>
      )}
      {children}
    </Modal>
  );
};
