import React from "react";
import "./Navbar.sass";
import { Link } from "react-router-dom";
import { Nav, Navbar, Container } from "react-bootstrap";
import { track } from "~/services/track";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useSelector } from "react-redux";

export default ({ folder, link }) => {
    const unit_detail = useSelector((store) => store.unit.detail);

    return (
        <Navbar className="navbar-hallo" expand="md">
            <Container style={{ flexWrap: 'nowrap', textAlign: "right"}}>
                <Navbar.Brand
                    style={folder && { height: "initial" }}
                    className="navbar-logo-img link"
                >
                    {folder ? (
                        <Link
                            onClick={() => track("VOLTAR_PARA_O_INICIO")}
                            to={link}>
                            <div>
                                <ArrowBackIcon />
                                <p>Voltar para o Início</p>
                            </div>
                        </Link>
                    ) : (
                        <Link to="/">
                            {unit_detail && unit_detail.company_image_url ?
                                (
                                    <img
                                        src={unit_detail.building.logo ? unit_detail.building.logo : unit_detail.company_image_url}
                                        style={{ borderRadius: 6 }}
                                        className="d-inline-block align-top"
                                        alt="Logo"
                                    />
                                ) : null
                            }

                        </Link>
                    )}
                </Navbar.Brand>
                <Nav className="mg-right">
                    <p style={folder ? { color: "#081630", fontSize: 18 } : { fontSize: 18 }}>
                        {unit_detail &&
              `${unit_detail.building.name} - ${unit_detail.name}`}
                    </p>
                </Nav>
            </Container>
        </Navbar>
    );
};
