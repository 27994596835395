import React from 'react';
import { Container } from "react-bootstrap";
import "./styles.scss";
import { Link, useParams } from 'react-router-dom';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CategoryList from '~/components/FaqList/CategoryList';
import { track } from '~/services/track';

function Categories() {
  const { token } = useParams();

  return (
    <Container className="background-faq">
      <Link to={`/${token}`} onClick={() => track("VOLTAR_PARA_O_INICIO")}>
        <div className="back-link">
          <ArrowBackIcon />
          <p>Voltar para o Início</p>
        </div>
      </Link>
      <CategoryList />
    </Container>
  );
}

export default Categories;
