import React from "react";
import "./Sac.sass";
import { Container } from "react-bootstrap";
import { track } from "~/services/track";
import ListGuarantees from "../../pages/Guarantees/ListGuarantees";
import FBButton from "../Button/FBButton";
import {ModalHeader} from "../Layout";

export default ({ push, previous, guarantees = []}) => {

    return (
        <Container>
            <ModalHeader title="Garantias" previous={previous} />
            <h6>Antes de abrir um chamado, confira as garantias vencidas deste empreendimento.</h6>
            <br />
            <ListGuarantees guarantees={guarantees} expiredGuarantees />
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
                {previous &&
          <FBButton
              style={{ width: "100px", marginRight: "10px" }}
              variant="outlined"
              onClick={() => previous()}
          >
            Voltar
          </FBButton>
                }
                <FBButton
                    primary
                    style={{ width: "200px" }}
                    onClick={() => {
                        track("ABRIR_CHAMADO_IGNORANDO_GARANTIAS")
                        push("form-screen")
                    }}
                >
          Quero abrir um chamado
                </FBButton>
            </div>
        </Container>
    );
};
