import React from "react";
import Button from "@material-ui/core/Button";
import styled,{ css }  from "styled-components";
import { useTheme } from "@material-ui/core/styles";

const StyledButton = styled(Button)`
    ${props => !props.size && css`
        font-size: ${props => props.theme.typography.body1.fontSize};
        width: 100%;
        font-weight:normal;
        text-transform: none;
        height: 50px !important;
        outline: none;
    `}
`;

function FBButton({ primary, ...props }, ref) {
    const theme = useTheme();

    return <StyledButton
        ref={ref}
        variant="contained"
        color={primary && primary ? 'primary' : props.color}
        className="FBButton"
        theme={theme}
        {...props}
    >{props.children}</StyledButton>
}
export default React.forwardRef(FBButton)