import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import config from './config';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

document.title = config.title;

Sentry.init({
  dsn: "https://a894c3729209487d8eadd7699d813300@o1288534.ingest.sentry.io/6505499",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
  environment: process.env.REACT_APP_ENV || "development"
});

ReactDOM.render(<App />, document.getElementById("root"));

