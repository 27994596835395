import React, { useEffect, useState } from 'react';

import "./styles.scss"
import api from '~/services/api';
import { iconForFolder } from "~/services/utils";
import { track } from "~/services/track";
import { Link, useParams } from 'react-router-dom';
import defaultBackground from "../../assets/imgs/common/bg-common.jpg"


function CategoryList() {
    const { token } = useParams();
    const [categories, setCategories] = useState([]);
    const [backgroundImage, setBackgroundImage] = useState();


    useEffect(() => {
        async function getUnitDetails() {
            try {
                const response = await api.get(`v1/units/${token}`);
                const responseC = await api.get(`/v1/categories/${response.data[0].company_id}`);
                setCategories(responseC.data)
                let unit;

                if (token.length === 5) {
                    unit = response.data.find((u) => u.token === token);
                } else {
                    unit = response.data.find((u) => u.legacy_guid === token);
                }

                setBackgroundImage(unit.building.background_photo)
            } catch (e) {
                console.error(e)
            }
        }

        getUnitDetails();
    }, [token]);

    return (
        <div className="container-icons"
            style={{
                backgroundImage: `url(${backgroundImage ? backgroundImage : defaultBackground})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundColor: "rgba(0, 0, 0, 0.85)",
                backgroundBlendMode: "multiply",
                width: "100%",
                minHeight: "100vh",
            }}
        >

            {categories.map(category => (
                <Link to={`/${token}/faq/${category.guid}`} onClick={() => track("ABRIU_CATEGORIA", { category: category.name })}
                >
                    <div className="container-icon">
                        <img src={iconForFolder(category)} alt="Luz" />
                        <p>{category.name}</p>
                    </div>
                </Link>
            ))
            }
        </div >
    );
}

export default CategoryList;
