import React, { useState, Fragment } from "react";
import "./Sac.sass";
import { CenterSpinnerButton } from "~/components/Spinner";
import { toast } from "react-toastify";
import { Container } from "react-bootstrap";
import Input from "../Input/Input";
import Label from "../Label"
import { formatPhone } from "~/services/utils";
import { clearDigitsSpecialChars } from "~/services/utils";
import Checkbox from "../Checkbox";
import FBButton from "../Button/FBButton";
import { ModalHeader } from "../Layout";
import { formatDocument, validateDocument } from "~/utils/formatters";

export default ({ unit, push, setName, setPhone, previous, documentRequired, setDocumentNumber }) => {
    const { client } = unit

    const [loading, setLoading] = useState(false);
    const [nameChange, setNameChange] = useState(null);
    const [phoneChange, setPhoneChange] = useState(null);
    const [documentNumberChange, setDocumentNumberChange] = useState(null);
    const [selectCustumer, setSelectCustumer] = useState(false);

    function setOwner() {
        setNameChange(client.name)
        setPhoneChange(client.phone)
        setDocumentNumberChange(client.document_number)
    }

    const onChangeClient = (name, value) => {
        let valueResult = value.toString().replace(/\*/g, '')

        if (selectCustumer) {
            setSelectCustumer(false)
            setPhoneChange("")
            setNameChange("")
            setDocumentNumberChange("")
            valueResult = valueResult.charAt(valueResult.length - 1);
        }

        switch (name) {
        case "name":
            setNameChange(valueResult)
            break
        case "phone":
            setPhoneChange(formatPhone(valueResult))
            break
        case "document_number":
            setDocumentNumberChange(formatDocument(valueResult))
            break
        }
    }

    const handleSubmit = () => {
        setLoading(true)
        const formattedPhone = clearDigitsSpecialChars(phoneChange)

        if (!selectCustumer && documentRequired ){
            if ((documentNumberChange === null || clearDigitsSpecialChars(documentNumberChange) === "")) {
                setLoading(false)
                toast.warn("O documento é obrigatório");
                return
            }
    
            if (!validateDocument(documentNumberChange)) {
                setLoading(false)
                toast.warn('Insira um documento válido');
                return
            }
        }

        if (!selectCustumer && formattedPhone.length !== 11) {
            setLoading(false)
            toast.warn("O telefone precisa ter DDD e os 9 dígitos, ex: (11) 12345-6789.");
            return
        }

        if (nameChange === null || nameChange.replace(/\s/g, '') === "") {
            setLoading(false)
            toast.warn("O nome é obrigatório");
            return
        }

        setName(nameChange)
        setPhone(formattedPhone)
        setDocumentNumber(documentNumberChange)
        push("options-screen");
    }

    function closeModal() {
        previous()
    }

    function onChangeSelectCustumer(ev) {
        if (ev.target.checked) {
            setOwner()
            setSelectCustumer(true)
        } else {
            setSelectCustumer(false)
            setPhoneChange("")
            setNameChange("")
            setDocumentNumberChange("")
        }
    }

    return (
        <Container>
            <ModalHeader title="Seus dados" previous={closeModal} />
            {loading ? (
                <CenterSpinnerButton color={"#000"} />
            ) :
                <Fragment>
                    <div className="form-client">
                        {Boolean(client && client.guid) &&
                            <div style={{ display: 'inline-flex', alignItems: 'center', margin: "15px 0px" }}>
                                <Checkbox
                                    checked={selectCustumer}
                                    onChange={(ev) => onChangeSelectCustumer(ev)}
                                    label="Sou proprietário deste imóvel"
                                />
                            </div>
                        }
                        <Label style={{ margin: 0 }} text="Insira seu nome" />
                        <Input
                            maxLength={50}
                            autoFocus
                            value={nameChange}
                            placeholder=""
                            onChange={(event) => onChangeClient("name", event.target.value)}

                        />

                        {documentRequired &&
                            <>
                                <Label text="Insira seu CPF" />
                                <Input
                                    type="cpf"
                                    maxLength={50}
                                    value={documentNumberChange}
                                    placeholder=""
                                    onChange={(event) => onChangeClient("document_number", event.target.value)}
                                />
                            </>
                        }

                        <Label text="Insira seu telefone" />
                        <Input
                            type="phone"
                            maxLength={15}
                            value={phoneChange}
                            placeholder=""
                            onChange={(event) => onChangeClient("phone", event.target.value)}
                        />
                    </div>
                </Fragment>
            }

            <div className="v2-modal-footer">
                <FBButton
                    style={{ width: "100px", marginRight: "10px" }}
                    variant="outlined"
                    onClick={closeModal}
                >
                    Voltar
                </FBButton>

                <FBButton
                    style={{ width: "100px" }}
                    disabled={loading || !phoneChange || !nameChange || (documentRequired && !documentNumberChange)}
                    color='primary'
                    onClick={handleSubmit}
                >
                    Próximo
                </FBButton>
            </div>

        </Container>
    )
}
